import React, { useEffect, useState } from 'react';
import '../Designe/Blogs/BlogPostTop3.css';
import hegesztobacsi from '../img/form2.jpg'
import iv from '../img/me_m.jpg'
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../redux/actions';
import translations from '../strings/Strings'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import Formel1 from '../img/Formel1.png'
import Formel2 from '../img/Formel2.png'
import Formel3 from '../img/Formel3.png'

import situA1_HU from '../img/diagrams/SituationA_1_HU.png'
import situA2_HU from '../img/diagrams/SituationA_2_HU.png'
import situB1_HU from '../img/diagrams/SituationB_1_HU.png'
import situB2_HU from '../img/diagrams/SituationB_2_HU.png'
import situC1_HU from '../img/diagrams/SituationC_1_HU.png'
import situC2_HU from '../img/diagrams/SituationC_2_HU.png'
import calculatingWelder from '../img/CalculatingWelder.png'
import CostCalculator from './calculator'; // Tökéletesen illeszkedni kell a fájl nevéhez


function ROICalculator() {
  
  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);


  var url = 'https://orbitalchamp.com/Top3OkaHogyMiertHasznaljOrbitalisHegesztot';
  var title = 'Megtérülési kalkuláció';
  var description = 'Hogy miért is éri meg OrbitalChampet vásárolni? Ezen az oldalon a részletes kifejtés és magyarázat található, hogy miért!';
  var imageUrl = hegesztobacsi;
  
  useEffect(() => {
    // Set the document title
    document.title = title;

    // Update meta tags dynamically
    const metaTags = [
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: imageUrl },
      { property: 'og:url', content: url },
      { property: 'og:type', content: 'article' },
      { property: 'og:site_name', content: 'OrbitalChamp.com' }
    ];

    metaTags.forEach(tag => {
      let element = document.querySelector(`meta[property='${tag.property}']`);
      
      if (!element) {
        // If the meta tag doesn't exist, create it
        element = document.createElement('meta');
        element.setAttribute('property', tag.property);
        document.head.appendChild(element);
      }

      element.setAttribute('content', tag.content);
    });
  }, [title, description, imageUrl, url]); 
  
  
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 762);
    };

    
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    switch (language) {
      case 'en':       
        setStrings(translations.en)
        break;  
      case 'hun':        
        setStrings(translations.hun)
        break;       
      case 'de':        
          setStrings(translations.de)
          break;         
      default:
        break;
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [language]);

// Save language to local storage when it changes
  const dispatch = useDispatch();
  useEffect(() => {
      const savedLanguage = localStorage.getItem('language') || 'hun'; // Default to Hungarian
      dispatch(setLanguage(savedLanguage)); // Set language in Redux
      setStrings(translations[savedLanguage])
  }, [dispatch]);

  



  if(isMobile==false)
  {
  return (
    <div className="blog-container">
   
      <div className="menu">
      <ul>
          <li><a href="/shop" >{strings.Webshop}</a></li>
          <li><a  href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">{strings.Blog_Top3_Title}       </a></li>
          <li><a href="/OrbitalisHegesztesTortelnelme"                                  >{strings.Blog_History_Title}    </a></li>
          <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto"                         >{strings.Blog_Valuable_Title}   </a></li>
          <li><a href="/AMagyarOrbitalisHegesztoAzOrbitalChamp"                         >{strings.Blog_Hungarian_Title}  </a></li>
          <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes"          >{strings.Blog_NoJobsTaken_Title}</a></li>
          <li><a  href="/OrbitalChampROI"          >{strings.Blog_ROI_Title}</a></li>

      </ul>
    </div>
      <div className="blog-content">
        <h1 className="bela">{strings.ROI_Title}</h1>
        <img
          className="welding-imagetop"
          src={calculatingWelder}
          alt="Számitásokat végző hegesztőmérnök"
        />

        <h2>{strings.Blog_ROI_Calculation_Title}</h2>

        <p>
          {strings.ROI_Intro}
        </p>

        <h2>{strings.ROI_Title_Base_Data}</h2>
            
        <p> 
          {strings.ROI_Base_Data_Par1}
         </p>
            
        <p>{strings.ROI_Base_Data_Par2}</p>
            
            <ul>
                <li>{strings.Blog_ROI_WelderBruttText} ( γ<sub>w</sub> ) : <b> {strings.Blog_ROI_WelderBruttVal}</b> {strings.Blog_ROI_WelderNetVal} </li>
                <li>{strings.Blog_ROI_WorkerBruttText} ( γ<sub>m</sub> ) : <b> {strings.Blog_ROI_WorkerBruttVal}</b> {strings.Blog_ROI_WorkerNetVal}</li>
                <li>{strings.Blog_ROI_OCBruttText} ( Ω ) : <b> {strings.Blog_ROI_OcBruttVal}</b> </li>
            </ul>
            
            <p> 
                {strings.ROI_Variable_Names}
            </p>
            <ul>
                <li>{strings.ROI_WelderCntText} ( n<sub>w</sub> )  </li>
               <li>{strings.ROI_WelderCntTextVesszo} ( n'<sub>w</sub> )  </li>
                <li>{strings.ROI_WorkerCntText} ( n<sub>m</sub> ) </li>
                <li>{strings.ROI_OrbitalCntText} ( n<sub>Ω</sub> ) </li>
                <li>{strings.ROI_MonthsCnt} ( n<sub>h</sub> ) </li>
                <li>{strings.ROI_CostVariableWeld} ( α<sub>w</sub> ) </li>
                <li>{strings.ROI_CostVariableOrbi} ( α<sub>Ω</sub> ) </li>
               <li>{strings.ROI_DiffVarName} ( Δ<sub>α</sub> ) </li>
            </ul>
            <p> 
                {strings.ROI_Intro2}
            </p>

            
            <table style={{
                border: '2px solid #9e1000',
                borderCollapse: 'collapse',
                width: '100%'
                }}>
                <tr>
                    <th style={{
                    border: '1px solid #9e1000',
                    padding: '10px'
                    }}>{strings.Blog_ROI_SmallCompany}</th>
                    <th style={{
                    border: '1px solid #9e1000',
                    padding: '10px'
                    }}>{strings.Blog_ROI_MediumCompany}</th>
                    <th style={{
                    border: '1px solid #9e1000',
                    padding: '10px'
                    }}>{strings.Blog_ROI_MediumBigCompany}</th>
                </tr>
                <tr>
                
                    <td style={{
                    border: '1px solid #9e1000',
                    padding: '10px'
                    }}>
                    <p>{strings.Blog_ROI_Scenario1_Title} </p>
                    <ul>
                        <li>  n<sub>w</sub> = 1</li>
                        <li>n<sub>m</sub> = 1</li>
                        <li>n<sub>Ω</sub> = 1</li>
                    </ul>
                    </td>
                    <td style={{
                    border: '1px solid #9e1000',
                    padding: '10px'
                    }}>
                    <p>{strings.Blog_ROI_Scenario2_Title} </p>

                    <ul>
                        <li>n<sub>w</sub> = 2, n'<sub>w</sub> = 1</li>
                        <li>n<sub>m</sub> = 1</li>
                        <li>n<sub>Ω</sub> = 2</li>
                    </ul>
                    </td>
                    <td style={{
                    border: '1px solid #9e1000',
                    padding: '10px'
                    }}>
                    <p>{strings.Blog_ROI_Scenario3_Title} </p>

                    <ul>
                        <li>n<sub>w</sub> = 4, n’_h = 1</li>
                        <li>n<sub>m</sub> = 3</li>
                        <li>n<sub>Ω</sub> = 3</li>
                    </ul>
                    </td>
                </tr>
            </table>

        <h2>{strings.ROI_CostTitle}</h2>

        <p>{strings.ROI_Cost_Par1} </p>


<table style={{
  border: '2px solid #9e1000',
  borderCollapse: 'collapse',
  width: '100%'
}}>
  <tr>
    <th style={{
      border: '1px solid #9e1000',
      padding: '10px'
    }}>
      {strings.ROI_Cost_Formula1}
    </th>
    <th style={{
      border: '1px solid #9e1000',
      padding: '10px'
    }}>
      {strings.ROI_Cost_Formula2}
    </th>
    <th style={{
      border: '1px solid #9e1000',
      padding: '10px'
    }}>
      {strings.ROI_Cost_Formula3}
    </th>
  </tr>
<tr>
  <td style={{
    border: '1px solid #9e1000',
    padding: '10px',
    textAlign: 'center',
    fontSize: '30px !important'  // Force override inherited font size
  }}>
    <img 
    style = {{width: '100%', height: '100%'}}
      src={Formel1}>
    </img>
  </td>
  <td style={{
    border: '1px solid #9e1000',
    padding: '10px',
    textAlign: 'center',
    fontSize: '30px !important'
  }}>
    <img 
    style = {{width: '100%', height: '100%'}}
    src={Formel2}></img>
  </td>
  <td style={{
    border: '1px solid #9e1000',
    padding: '10px',
    textAlign: 'center',
    fontSize: '30px !important'
  }}>
    <img 
      style = {{width: '100%', height: '100%'}}
      src={Formel3}>
    </img>
  </td>
</tr>

</table>

<h2 style= {{textAlign: 'center'}}>{strings.ROI_Searched_Point} Δ<sub>α</sub> = 0</h2>
   
<p>{strings.ROI_Cost_Par2}</p>

<p>{strings.ROI_Cost_Par3}</p>
        
        <h2>{strings.ROI_Results}</h2>

<p><b>{strings.ROI_Result1_Title}</b>{strings.ROI_Result1_Text}</p>

 <table>
        <tr>
            <td><img src={situA1_HU} alt="Első kép" style={{width: '100%', margin: '20px'}} /></td>
            <div style={{padding: '20px'}}></div>
            <td><img src={situA2_HU} alt="Második kép"  style={{width: '100%', margin: '20px'}} /></td>
            <div style={{padding: '20px'}}></div>
        </tr>
    </table>


<p><b>{strings.ROI_Result2_Title}</b>{strings.ROI_Result2_Text}</p>

 <table>
        <tr>
            <td><img src={situB1_HU} alt="Első kép" style={{width: '100%', margin: '20px'}} /></td>
            <div style={{padding: '20px'}}></div>
            <td><img src={situB2_HU} alt="Második kép"  style={{width: '100%', margin: '20px'}} /></td>
            <div style={{padding: '20px'}}></div>
        </tr>
    </table>
<p><b>{strings.ROI_Result3_Title}</b>{strings.ROI_Result3_Text}</p>

 <table>
        <tr>
            <td><img src={situC1_HU} alt="Első kép" style={{width: '100%', margin: '20px'}} /></td>
            <div style={{padding: '20px'}}></div>
            <td><img src={situC2_HU} alt="Második kép"  style={{width: '100%', margin: '20px'}} /></td>
            <div style={{padding: '20px'}}></div>
        </tr>
    </table>

        <h2>
          {strings.ROI_Conclusion}
        </h2>
        <p>
          {strings.ROI_Outro1}
        </p>
        <p>
          {strings.ROI_Outro2}
        </p>
        <table>
          <tr>
            <td style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <p>{strings.ROI_Outro3}</p>
              <a className='button_a' href='/ContactUsPage' style={{ width: '150px', height: 'auto' }}>
                {strings.Blog_ROI_Bemutato}
              </a>
            </td>
          </tr>
        </table>

        <CostCalculator strings={strings}></CostCalculator>

        {strings.Blog_Author} <br />
        <div className='author_div'>
            <img
              className="author-image"
              src={iv}
              alt="Okleveles Mechatronikai Mérnök"> 
            </img>
            <div className='author-text'>
                <p>               
                <strong>Hopp Márton Péter</strong> <br />
                <hr className="rounded"></hr>
                <span className="author-title"></span>{strings.Blog_TitulusMecha}
                </p>

            </div>

        </div>

      </div>
      <div className='empty-space'>

      </div>
    </div>
  );
 }else{
  return (
    <div className="blog-container">
      <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:url" content={url} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="OrbitalChamp.com" />
        </Helmet>

        <div className="blog-content">
          <h1 className="bela-mobile">{strings.ROI_Title}</h1>
          <img
            className="welding-imagetop"
            src={calculatingWelder}
            alt="Számitásokat végző hegesztőmérnök"
          />
      
        <div style={{ padding: "20px" }}>
          <h2>{strings.Blog_ROI_Calculation_Title}</h2>
        </div>

        <p>
          {strings.ROI_Intro}
        </p>
        <div style={{ padding: "20px" }}>
          <h2>{strings.ROI_Title_Base_Data}</h2>
        </div>
            
        <p> 
          {strings.ROI_Base_Data_Par1}
         </p>
            
        <p>{strings.ROI_Base_Data_Par2}</p>
        <div style={{ padding: "20px" }}>
           <ul>
             <li>{strings.Blog_ROI_WelderBruttText} ( γ<sub>w</sub> ) : <b> {strings.Blog_ROI_WelderBruttVal}</b> {strings.Blog_ROI_WelderNetVal} </li>
             <li>{strings.Blog_ROI_WorkerBruttText} ( γ<sub>m</sub> ) : <b> {strings.Blog_ROI_WorkerBruttVal}</b> {strings.Blog_ROI_WorkerNetVal}</li>
             <li>{strings.Blog_ROI_OCBruttText} ( Ω ) : <b> {strings.Blog_ROI_OcBruttVal}</b> </li>
           </ul>
        </div> 
        <p>{strings.ROI_Variable_Names} </p>

        <div style={{ padding: "20px" }}>
          <ul>
               <li>{strings.ROI_WelderCntText} ( n<sub>w</sub> )  </li>
               <li>{strings.ROI_WelderCntTextVesszo} ( n'<sub>w</sub> )  </li>
               <li>{strings.ROI_WorkerCntText} ( n<sub>m</sub> ) </li>
               <li>{strings.ROI_OrbitalCntText} ( n<sub>Ω</sub> ) </li>
               <li>{strings.ROI_CostVariableWeld} ( α<sub>w</sub> ) </li>
               <li>{strings.ROI_CostVariableOrbi} ( α<sub>Ω</sub> ) </li>
               <li>{strings.ROI_DiffVarName} ( Δ<sub>α</sub> ) </li>
               <li>{strings.ROI_MonthsCnt} ( n<sub>h</sub> ) </li>
           </ul>
        </div>

         <p> 
             {strings.ROI_Intro2}
         </p>

      <div>
            <h2>{strings.Blog_ROI_SmallCompany}</h2>
            <p>{strings.Blog_ROI_Scenario1_Title}</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <ul style={{ padding: 0, margin: "5px 0", listStylePosition: "inside" }}>
                    <li>n<sub>w</sub> = 1</li>
                    <li>n<sub>m</sub> = 1</li>
                    <li>n<sub>Ω</sub> = 1</li>
                </ul>
            </div>

            <h2>{strings.Blog_ROI_MediumCompany}</h2>
            <p>{strings.Blog_ROI_Scenario2_Title}</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <ul style={{ padding: 0, margin: "5px 0", listStylePosition: "inside"}}>
                    <li>n<sub>w</sub> = 2, n’<sub>w</sub> = 1</li>
                    <li>n<sub>m</sub> = 1</li>
                    <li>n<sub>Ω</sub> = 2</li>
                </ul>
            </div>

            <h2>{strings.Blog_ROI_MediumBigCompany}</h2>
            <p>{strings.Blog_ROI_Scenario3_Title}</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <ul style={{ padding: 0, margin: "5px 0", listStylePosition: "inside" }}>
                    <li>n<sub>w</sub> = 4, n’<sub>w</sub> = 1</li>
                    <li>n<sub>m</sub> = 3</li>
                    <li>n<sub>Ω</sub> = 3</li>
                </ul>
            </div>
        </div>
        <h2>{strings.ROI_CostTitle}</h2>

        <p>{strings.ROI_Cost_Par1} </p>

        <div style={{ textAlign: "center" }}>
            <p style={{ margin: "0px 0" }}>{strings.ROI_Cost_Formula1}</p>
            <div style={{ display: "flex", justifyContent: "center", padding: "5px" }}>
                <img style={{ maxWidth: "100%", height: "auto" }} src={Formel1} alt="Formula 1" />
            </div>

            <p style={{ margin: "0px 0" }}>{strings.ROI_Cost_Formula2}</p>
            <div style={{ display: "flex", justifyContent: "center", padding: "5px" }}>
                <img style={{ maxWidth: "100%", height: "auto" }} src={Formel2} alt="Formula 2" />
            </div>

            <p style={{ margin: "0px 0" }}>{strings.ROI_Cost_Formula3}</p>
            <div style={{ display: "flex", justifyContent: "center", padding: "5px" }}>
                <img style={{ maxWidth: "100%", height: "auto" }} src={Formel3} alt="Formula 3" />
            </div>
        </div>

        <h2 style= {{textAlign: 'center'}}>{strings.ROI_Searched_Point} Δ<sub>α</sub> = 0</h2>
          
        <p>{strings.ROI_Cost_Par2}</p>

        <p>{strings.ROI_Cost_Par3}</p>
                
        <h2>{strings.ROI_Results}</h2>

        <div style={{ textAlign: "center" }}>
          <p><b>{strings.ROI_Result1_Title}</b> {strings.ROI_Result1_Text}</p>
          <div style={{ padding: "20px" }}>
              <img src={situA1_HU} alt="Első kép" style={{ width: "100%" }} />
          </div>
          <div style={{ padding: "20px" }}>
              <img src={situA2_HU} alt="Második kép" style={{ width: "100%" }} />
          </div>

          <p><b>{strings.ROI_Result2_Title}</b> {strings.ROI_Result2_Text}</p>
          <div style={{ padding: "20px" }}>
              <img src={situB1_HU} alt="Első kép" style={{ width: "100%" }} />
          </div>
          <div style={{ padding: "20px" }}>
              <img src={situB2_HU} alt="Második kép" style={{ width: "100%" }} />
          </div>

          <p><b>{strings.ROI_Result3_Title}</b> {strings.ROI_Result3_Text}</p>
          <div style={{ padding: "20px" }}>
              <img src={situC1_HU} alt="Első kép" style={{ width: "100%" }} />
          </div>
          <div style={{ padding: "20px" }}>
              <img src={situC2_HU} alt="Második kép" style={{ width: "100%" }} />
          </div>
        </div>

        <h2>
          {strings.ROI_Conclusion}
        </h2>
        <p>
          {strings.ROI_Outro1}
        </p>
        <p>
          {strings.ROI_Outro2}
        </p>
        <p>{strings.ROI_Outro3}</p>
        <a className='button_a' href='/ContactUsPage' style={{ width: '150px', height: 'auto' }}>
          {strings.Blog_ROI_Bemutato}
        </a>
        <div style={{ padding: "20px" }}></div>

        <CostCalculator strings={strings}/>

          {strings.Blog_Author} <br />
          <div className='author_div'>
              <img
                className="author-image"
                src={iv}
                alt="Okleveles Mechatronikai Mérnök"> 
              </img>
              <div className='author-text'>
                  <p>               
                  <strong>Hopp Márton Péter</strong> <br />
                  <hr className="rounded"></hr>
                  <span className="author-title"></span>{strings.Blog_TitulusMecha}
                  </p>

              </div>
          </div>
      </div>
      <div className="menu">
        <ul>
            <li><a href="/shop" >{strings.Webshop}</a></li>
            <li><a  href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">{strings.Blog_Top3_Title}       </a></li>
            <li><a href="/OrbitalisHegesztesTortelnelme"                                  >{strings.Blog_History_Title}    </a></li>
            <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto"                         >{strings.Blog_Valuable_Title}   </a></li>
            <li><a href="/AMagyarOrbitalisHegesztoAzOrbitalChamp"                         >{strings.Blog_Hungarian_Title}  </a></li>
            <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes"          >{strings.Blog_NoJobsTaken_Title}</a></li>
            <li><a  href="/OrbitalChampROI"          >{strings.Blog_ROI_Title}</a></li>
        </ul>

      </div>
    </div>
  );
 }



 
}

export default ROICalculator;



