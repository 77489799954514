
const translations = {
  hun: {
      Navbar_Product_title: 'Termékünk',
      Navbar_Application_title: 'Hegesztőgépek',
      Navbar_Weldings_title: 'Hegesztéseink',
      Navbar_OrbitalWelding_title: 'Orbitális hegesztés',
      Navbar_Contact_title: 'Kapcsolat',
      
      ContactApplyTitle: "Jelentkezem a demóra!",
      Contact_TestDrive: 'Válasszon egy időpontot!',
      Contact_AtYou: 'Önnél:',
      Contact_AtUs: 'Nálunk',
      Contact_AtYouPlace: 'Az Ön cime',
      Contact_ChooseDate: 'Válasszon egy dátumot',
      Contact_Info: 'A küldés gomb megnyomása után felvesszük a kapcsolatot Önnel, hogy mikor legyen a demó. A cimet amiatt kérjük, hogy a logisztikai részleteket ki tudjuk dolgozni',
      Contact_Info2: 'Amennyiben tudja mikor tudna jönni hozzánk, akkor kérjük válasszon időpontot!',
      Change: "Váltás",
      
      Welcome_Button: 'Lépj velünk kapcsolatba!',

      Footer_Location: 'Helyszín',
      Toast_Error_Email: 'Hiba az email küldésekor',

      About_Head_title: 'OrbitalChamp',
      About_Head_desc: 'Az OrbitalChamp egy olyan hegesztő okosító berendezés, mellyel röntgen-biztos, tökéletes pontosságú és minőségű hegesztést tud végezni egy olyan munkavállaló, aki nem rendelkezik hegesztői végzettséggel',
      About_High_Quality_Weldings_title: 'Minősített hegesztés',
      About_High_Quality_Weldings_desc: 'Az OrbitalChamp-pel magas minőségű hegesztéseket lehet végezni. Erre bizonyíték, hogy rendelkezünk TÜV minősítéssel.',
      About_Track_Setting_title: 'Pályaállítás',
      About_Track_Setting_desc: 'Van lehetőségünk a hegesztési folyamat közben, a hegesztés pályát megváltoztatni',
      About_Electronic_title: 'Vezérlő elektronika',
      About_Electronic_desc: 'Magát a hegesztési folyamatot egy vezérlő elektronika vezérli le. Ezt az elektronikát egy mobil applikációval tudjuk programozni.',
      About_UI_title: 'Távirányító',
      About_UI_desc: 'Az OrbitalChamp egy távirányítóval vezérelhető eszköz, amelyen három darab gomb található, valamint egy kapcsoló és három LED. A nyomógombok segítségével pozícionálni lehet a fejet, illetve el lehet indítani a hegesztési folyamatot. A kapcsolóval lehet ki- és bekapcsolni a távirányítót. A LED-ek jelzik a hegesztési folyamat kezdetét, befejezését, valamint a Bluetooth kapcsolatot.   ',
      About_Your_Welder_title: 'Csatlakoztasd gépedhez!',
      About_Your_Welder_desc: 'Az OrbitalChamp egyik legjobb tulajdonsága, hogy bármelyik TIG hegesztőgéppel kompatibilis, így a hegesztő szakember a saját, már jól ismert hegesztőgépét tudja használni',
      About_All_Pipe_title: 'Minden fejméretre',
      About_All_Pipe_desc: 'Termékünk két hegesztőfejjel kapható. Az egyikkel DIN20-tól DIN50-ig lehet hegeszteni, a másikkal pedig DIN50-től DIN100-ig. A mérettartományon belül különböző méterű betéteket használhatunk a különböző csövekhez.',
      About_Next: 'Tovább',
      About_Back: 'Vissza',

      WeldingMachines_Title: 'Melyik hegesztőgéphez csatlakoztatnád?',
      WeldingMachines_Button: 'További AWI hegesztőgépek',

      WeldingMachines_mobile_Title: 'Hegesztőgépek',
      WeldingMachines_mobile_Button: 'Nézd meg a videót!',

      WeldingsAndForm_Title: 'Orbitális Hegesztéseink',
      WeldingsAndForm_Form_Title: 'Próbálja ki Ön is az Orbitális hegesztőnket ingyen!',
      WeldingsAndForm_Form_Address: 'Helyszín: 1224 XVI. utca 9/a',
      WeldingsAndForm_Form_Button: 'Jelentkezzen kapcsolatfelvételre!',
      WeldingsAndForm_Form_Button_Demo: 'Jelentkezzen próbára!',
      WeldingsAndForm_Form_Button_Offer: 'Ajánlatkérés!',

      OrbitalWeldings_Title: 'Orbitális hegesztés',
      OrbitalWeldings_P1: 'Az orbitális hegesztés egy fejlett hegesztési technológia, amelyet elsősorban csövek és hengeres szerkezetek precíziós hegesztésére használnak. Az eljárás precíz, kiváló minőségű varratokat biztosít, és különösen fontos olyan iparágakban, mint a gyógyszeripar, az élelmiszeripar, az olaj- és gázipar, ahol a hegesztési minőség és higiénia kulcsfontosságú.',
      OrbitalWeldings_P2: 'Az orbitális hegesztés legnagyobb előnyei közé tartozik a kiváló hegesztési minőség és a folyamat ismételhetősége. Az automatizált rendszer lehetővé teszi, hogy minden hegesztés ugyanolyan magas színvonalú legyen, ami minimalizálja a hibákat és csökkenti az utólagos ellenőrzés szükségességét. Ezen kívül az orbitális hegesztés gyorsabb, mint a hagyományos módszerek, ami csökkenti a munkavégzés idejét és ezáltal a költségeket is.',
      OrbitalWeldings_P3: 'A beruházás egy orbitális hegesztési rendszerbe hosszú távon megtérülhet, mivel csökkenti a munkaerőköltségeket, javítja a termelés hatékonyságát és növeli a termék minőségét. Az ilyen rendszerek különösen alkalmasak olyan projektekre, ahol nagy mennyiségű csővezeték hegesztése szükséges, és ahol a minőség nem kompromisszum kérdése. Az orbitális hegesztés tehát nemcsak technológiai előnyt nyújt, hanem versenyelőnyt is biztosít azok számára, akik elkötelezettek a kiváló minőség mellett.', 

      Application_title: 'Applikáció',
      Application_Settings_title: 'Beállítások',
      Application_Settings_Start_Welder_title: 'Start hegesztési impulzus',
      Application_Settings_Start_Welder_desc: '0.5 másodperccel nagyobb, mint a gáz előadagolás',
      Application_Settings_Delay_title: 'Indítás késleltetés',
      Application_Settings_Delay_desc: 'Az a várakozási idő, ami alatt az anyag átmelegszik',
      Application_Settings_Rotation_Speed_title: 'Forgatási sebesség',
      Application_Settings_Rotation_Speed_desc: 'A cső kerületét 4 szektorra osztottuk, ezekben a szektorokban különböző sebességekkel tudunk haladni',
      Application_Settings_OverRun_title: 'Túlfutás',
      Application_Settings_OverRun_desc: 'Azt hatérozza meg, hogy a 360 fokon túl hol érjen véget a hegesztés',
      Application_Settings_Stop_Impulse_title: 'Stop impulzus',
      Application_Settings_Stop_Impulse_desc: 'Az Ív elvétel helyét határozza meg',
      Application_Settings_Stop_Welder_Impulse_title: 'Stop hegesztési impulzus',
      Application_Settings_Stop_Welder_Impulse_desc: 'Az ív elvétel idejét határozza meg',
      Application_Settings_Rotation_Direction_title: 'Forgatási irány',
      Application_Settings_Rotation_Direction_desc: 'A hegesztőfej forgásának irányát határozza meg',
      Application_Settings_Meta_title: 'Áttétel',
      Application_Settings_Meta_desc: 'A különböző mérető fejekhez, különböző áttételek taroznak, ezt itt tudjuk beállítani',
      Application_Get_More_Information: 'Tudj meg többet!',
      Application_Inquery_title: 'Lekérdezés',
      Application_Inquery_desc: 'Módunkban áll egy teljes olyan képernyő, ahol le tudjuk kérdezni az eszközre feltöltött adatokat!',
      Application_Save_BT_title: 'Mentés',
      Application_Save_title: 'Mentsd el a hegesztési beállításokat!',
      Application_Save_desc: 'Az applikáció lehetőséget biztosít arra, hogy a hegesztési adatokat lementsük és bármikor újra elő tudjuk hívni, majd fel tudjuk tölteni egy, vagy több eszközre. Ebből következik egy nagyon hasznos tulajdonsága eszközünknek. Nézzünk rá egy példát: A hegesztő mérnök műhely körülmények között felprogramozza az eszközt úgy, hogy a beállított hegesztés végeredménye egy magas minőségű hegesztés legyen. Ezt feltölti az eszközre, majd elmenti a táblázatba, esetleg több eszközre is feltölti. Átadja a hegesztő okosítót egy betanított munkásnak, aki ráhelyezi a meghegesztendő csőre az eszközt, majd a hegesztés elindítása után ugyanazt a minőségi hegesztést végzi el, amit a hegesztővégzett a műhelyben. Ez a folyamat gyakorlatilag korlátlan számban elvégezhető.',
      Application_Or: 'vagy',

      Shop_Cancel: 'Mégse',

      Weldings_title: 'Hegesztéseink',
      Weldings_seams: 'Varratok',
      Weldings_root: 'Gyök',
      Weldings_factory: 'Gyártás',

      Contact_title: 'Kapcsolat',
      Contact_name: 'Név',
      Contact_email: 'Email',
      Contact_Company_name: 'Cégnév',
      Contact_Phone: 'Telefonszám',
      Contact_message: 'Üzenet',
      Conatct_message_desc: 'Írja ide az üzenetet!',
      Conatct_Send: 'Küldés',
      Webshop: 'Webshop',
      Shop_SmartWeldingSystemTitle:'OrbitalChamp hegesztőokositó rendszer',
      Shop_WelderheadTitleDIN50: 'OrbitalChamp Hegesztőfej DIN20-DIN50',
      Shop_WelderheadTitleDIN100: 'OrbitalChamp Hegesztőfej DIN50-DIN100',
      Shop_SmartBoxTitle: 'OrbitalChamp Smartbox',
      Shop_BackToMainPage: 'Vissza a főoldalra',
      Shop_OurProducts: 'Termékeink',
      Shop_OurProduct: 'Termékünk',
      Shop_Price: 'Ár',
      VAT: 'ÁFA',
      Shop_Item_SmartWelding_Info : " Az OrbitalChamp hegesztőokosító teljes rendszere is megvásárolható. Ez a csomag tartalmazza a Smartboxot és egy választható méretű orbitális jetet.",
      Shop_Item_Contains : "Tartalma:",
      Shop_Item_OrbitalWelder : "Orbitális hegesztőfej",
      Shop_Item_Inlay : "4 db betét",
      Shop_Item_Welding_Connector : "Hegesztő csatlakozó",
      Shop_Item_WorkCable : "Munka kábel",
      Shop_Item_WelderHead : "Hegesztő fej",
      Shop_Item_Back : "Vissza",
      
      Shop_Item_Offer : "Ajánlatkérés",
      Shop_Item_Compatible_Devices : "Kompatibilis eszközök",
      Shop_Item_Orbital_Products : "Csomag tartalma",
      Shop_Item_Compatible_Devices_Info : "Válasszon ki egyet, hogy mi is tudjuk melyik géphez használná",
      Shop_Item_Download_UM : "Töltse le user manualunkat",
      Shop_Item_Download_Text : "Letöltés",
      Shop_Item_Download_Android : "Letöltés Androidra",
      Shop_Item_Download_IOS : "Letöltés iOS-re",

      Shop_Item_Your_Email : "Email cime",
      Shop_Item_Know_More : "Tudj meg többet a termékünkről!",
      Shop_Item_Sizes : "Mérettartományok",
      Shop_Item_Pro : "Előnyök",
      Shop_Item_Successful_Sending: "Az üzenet sikeresen elküldve!", 
      Shop_Item_Unsuccesful_Sending : "Hiba történt az üzenet küldése közben!",
      Shop_Item_Bad_Email : "Az email cim nem megfelelő",
      Shop_Item_Bad_Number : "Nem megfelelő a telefonszám",

      Shop_Item_SWS_Vorteil1 : "Kompatibilis bármely TIG hegesztővel",
      Shop_Item_SWS_Vorteil2 : "Tökéletes pontosságú és minőségű hegesztés a fix ív tartásnak köszönhetően",
      Shop_Item_SWS_Vorteil3 : "A menthető paramétereknek hála bármikor megismételhető a hegesztés.",
      Shop_Item_SWS_Vorteil4 : "Állítsa be a tökéletes hegesztést a telefonjával!",
      Shop_Item_SWS_Vorteil5 : "Bármely csőméretre alkalmazható a betétek által",
      Shop_Item_SWS_Vorteil6 : "Kitermelhető ár, magas minőség, megbízhatóság",

      Shop_Item_IndexRemote:         "Távirányitó",
      Shop_Item_IndexOrbitalWelderHead:         "Orbitális Hegesztőfej",
      Shop_Item_IndexSmartBox :         "SmartBox (Elektronika)",
      Shop_Item_IndexKonzol :         "Konzol",
      Shop_Item_IndexWeldConnector :         "Hegesztő Csatlakozó",
      Shop_Item_IndexBetet :         "Betét",
      Shop_Item_IndexMotorCable :         "Motor kábel 3 [m] vagy 3+ [m]",
      Shop_Item_IndexWelderHeadEnd :         "Hegesztő fej",
      Shop_Item_IndexPowerSupply :         "12V Tápegység",
      Shop_Item_IndexArbeitKabel :         "Munkakábel 3 [m] vagy 3+ [m]",

      Shop_Item_SizeOfWelderHead : 'A hegesztőfej méretei:', 
      Shop_Item_Size_WidthDIN50 : 'Szélesség: 185 [mm]',
      Shop_Item_Size_LengthDIN50 : 'Hosszúság: 480 [mm]',
      Shop_Item_Size_HeightDIN50 : 'Magasság: 155 [mm]',
      Shop_Item_Size_WidthDIN100 : 'Szélesség: 245 [mm]',
      Shop_Item_Size_LengthDIN100 : 'Hosszúság: 480 [mm]',
      Shop_Item_Size_HeightDIN100 : 'Magasság: 155 [mm]',
      Shop_Item_Size_WidthSmart : 'Szélesség: 120 [mm]',
      Shop_Item_Size_LengthSmart : 'Hosszúság: 240 [mm]',
      Shop_Item_Size_HeightSmart : 'Magasság: 40 [mm]',
      Shop_Item_Size_Smartbox : 'A Smartbox méretei',
      Shop_Item_Size_Cable : 'A kábelek méretei',
      Shop_Item_CurrectCable : 'Munkakábel: 3 [m] (bővithető)',
      Shop_Item_MotorCable : 'Motorkábel: 3 [m] (bővithető)',
      Shop_Item_WeldingRangeDIN50 : '20 [mm] - 60.3 [mm] befogó tartománnyal rendelkezik',
      Shop_Item_WeldingRangeDIN100 : '60.3 [mm] - 114.3 [mm] befogó tartománnyal rendelkezik',

      Shop_Item_SmartBoxInfo : "Az OrbitalChamp SmartBox egy automatizált hegesztés vezérlő rendszer, amely biztosítja a precíz, megbízható hegesztési folyamatokat. Egyszerű integráció a meglévő rendszerekbe, okostelefonos vezérléssel.",
      Shop_Item_DIN50Info : "20 mm - 60,3 mm átmérőjű csövekhez alkalmas hegesztőfej, amely precíz és megbízható hegesztést biztosít ipari környezetben. Könnyen illeszthető az OrbitalChamp SmartBoxhoz.",
      Shop_Item_DIN100Info : "60,3 mm - 114,3 mm átmérőjű csövekhez alkalmas hegesztőfej, amely precíz és megbízható hegesztést biztosít ipari környezetben. Könnyen illeszthető az OrbitalChamp SmartBoxhoz.",


      Blog_Author: "Szerző",
      Blog_TitulusMecha: "Okleveles Mechatronikai Mérnök",
      Blog_Illustracio: "A kép illusztráció",

      Blog_Top3_Title_Meta : "Az orbitális hegesztés történelme!",
      Blog_Top3_Description_Meta : "Az orbitális hegesztés egy speciális, automatizált hegesztési technológia, amely elsősorban csövek és csővezetékek összeillesztésére szolgál. A technológia kialakulása az 1960-as évekre tehető, amikor az űrkutatás és az atomenergia-ipar előretörése új, megbízható hegesztési módszereket követelt meg.",
      Blog_Top3_Title : "Top 3 oka, hogy miért használj ORBITÁLIS HEGESZTŐT!",
      Blog_Top3_Paragraph1 : "Az orbitális hegesztés technológiája az utóbbi években egyre nagyobb teret hódított a hegesztési iparban. De miért válasszunk orbitális hegesztőt a hagyományos módszerek helyett? Íme három meggyőző ok, amelyek miatt érdemes a modern technológiát választanod:",
      Blog_Top3_T1_Strong : "Kiemelkedő Pontosság és Ismételhetőség:",
      Blog_Top3_T1_Text : "Az  orbitális hegesztés legnagyobb előnye a hihetetlen pontosság és ismételhetőség. A hegesztőgép automatikusan beállítható és"+
            + "biztosítja, hogy a hegesztési varrat egyenletes és szabályos legyen"
            +"minden egyes hegesztési szakaszon. Ez különösen fontos azokon az"
            +"iparágakon, ahol a hegesztési minőség közvetlenül befolyásolja a"
            +"termék biztonságát és az élettartamát, például a gyógyszeriparban"
            +"vagy az élelmiszeriparban.",
      Blog_Top3_T2_Strong : "Csökkentett Munkaidő és Növelt Hatékonyság:",
      Blog_Top3_T2_Text : "Az"
      +"orbitális hegesztők gyorsasága és hatékonysága drasztikusan"
      +"csökkentheti a munkahoz szükséges időt. Mivel a gépek részben"
      +"automatizáltak, kevesebb időt és erőforrást kell fordítani a"
      +"munkafolyamatok képzésére. Emellett a csökkentett hegesztési idő azt"
      +"is lehetővé teszi, hogy a projektek gyorsabban készüljenek el, ami"
      +"növeli a termelékenységet és optimalizálja az ipari műveleteket.",
      Blog_Top3_T3_Strong : "Megnövelt Biztonság a Munkahelyen:",
      Blog_Top3_T3_Text : " A hegesztési folyamat automatizálása nemcsak hogy hatékonyabbá, de biztonságosabbá"
           +" is teszi. Az orbitális hegesztők minimalizálják a kézi hegesztéssel"
           +" járó hibákat és baleseteket, mivel a kezelőnek sokkal kevesebb"
           +" fizikai beavatkozásra van szüksége a folyamat alatt. Ez felhasználók"
           +" számára jobb egészségügyi feltételeket teremt, ami tovább csökkenti"
           +" a lehetséges egészségügyi kockázatokat.",
      Blog_Top3_P2 : "          Ha olyan innovatív technológiára van szükséged, amely forradalmasíthatja"
         +" a hegesztési ipart, pontosság, hatékonyság és biztonság szem előtt"
         +" tartásával, az orbitális hegesztés a legjobb választás. Az alacsonyabb"
         +" hibaarány és gyorsabb elvégzés nemcsak időt spórol meg, de növeli a"
         +" végtermék minőségét is.",
        


      Blog_History_Title: "Az Orbitális Hegesztés Történelme",
      Blog_History_Paragraph1:
        "Az orbitális hegesztés egy speciális, automatizált hegesztési technológia, amely elsősorban csövek és csővezetékek összeillesztésére szolgál. A technológia kialakulása az 1960-as évekre tehető, amikor az űrkutatás és az atomenergia-ipar előretörése új, megbízható hegesztési módszereket követelt meg.",
      Blog_History_TheBeginnings_Title: "A Kezdetek",
      Blog_History_TheBeginnings_Text:
        "Az orbitális hegesztés megszületése szorosan kapcsolódik az Egyesült Államok űrprogramjához. A NASA számára rendkívül fontos volt, hogy a rakéták és űrhajók rendszereiben alkalmazott csővezetékek hegesztése tökéletes legyen, hiszen a legkisebb hiba is katasztrofális következményekkel járhatott. Az első orbitális hegesztőberendezéseket a North American Aviation fejlesztette ki, kifejezetten a vékony falú rozsdamentes acélcsövek precíz hegesztésére.",
      Blog_History_TwoTypes: "A technológia fejlődése során két fő hegesztőfej-típus alakult ki:",
      Blog_History_Technology_Title: "Technológiai Fejlődés",
      Blog_History_Technology_Text:
        "Az 1970-es és 1980-as években a technológia továbbfejlődött és elterjedt más iparágakban is, mint például a vegyipar, a gyógyszeripar és az élelmiszeripar. Ezekben az ágazatokban a tisztaság és a hegesztési varratok minősége kiemelt jelentőségű, ezért az orbitális hegesztés ideális megoldást nyújtott.",
      
      Blog_History_Heads_Title: "Nyitott és Zárt Hegesztőfejek",
      Blog_History_OpenHeads: "Nyitott hegesztőfejek:",
      Blog_History_OpenHeads_Text: "Ezek lehetővé teszik a hegesztési folyamat közvetlen megfigyelését, és rugalmasabban alkalmazhatók különböző csőméretekhez.",
      Blog_History_ClosedHeads: "Zárt hegesztőfejek:",
      Blog_History_ClosedHeads_Text: " A hegesztési területet egy zárt kamrában tartják, ahol inert gáz védi a varratot az oxidációtól, így kiváló minőségű hegesztések érhetők el.",
      
      Blog_History_Modern_Title: "Modern Fejlesztések és Az OrbitalChamp",
      Blog_History_Modern_Text:
        "Napjainkra az orbitális hegesztés széles körben elterjedt és folyamatosan fejlődik. Magyarországon is jelentős előrelépések történtek ezen a területen. Az OrbitalChamp egy magyar fejlesztésű orbitális hegesztőberendezés, amely különlegessége abban rejlik, hogy kompatibilis a legtöbb meglévő hegesztőgéppel. Ez lehetővé teszi a vállalkozások számára, hogy anélkül alkalmazzák az orbitális hegesztés előnyeit, hogy teljesen új rendszert kellene vásárolniuk.",
      
      Blog_History_Importance_Title: "Az Orbitális Hegesztés Jelentősége",
      Blog_History_Importance_Text:
        "Az orbitális hegesztés története a technológiai innováció és az ipari igények találkozásának eredménye. A módszer előnyei közé tartozik a kiváló minőségű, ismételhető hegesztési varratok készítése, a munkaidő csökkentése és a termelékenység növelése. Ezek a tényezők teszik nélkülözhetetlenné a technológiát számos iparágban.",
      
      Blog_History_Summary_Title: "Összegzés",
      Blog_History_Summary_Text:
        "Az elmúlt évtizedekben az orbitális hegesztés jelentős fejlődésen ment keresztül, és továbbra is kulcsszerepet játszik a modern ipari folyamatokban. A folyamatos innováció és a hazai fejlesztések, mint az OrbitalChamp, biztosítják, hogy ez a technológia a jövőben is meghatározó maradjon.",
    
      Blog_Author_Name: "Horváth Tibor",
      Blog_Author_Title: "OrbitChamp CEO",




      Blog_Valuable_Title: "Ezért leszel egyre értékesebb, mint hegesztő",
      Blog_Valuable_Paragraph1: 
        "Az utóbbi években a hegesztők iránti kereslet jelentősen megnövekedett, és ez a tendencia várhatóan folytatódni fog a jövőben is. De miért is leszel egyre értékesebb mint hegesztő, és hogyan használhatod ki a szakmában rejlő lehetőségeket? Ebben a bejegyzésben megvizsgáljuk azokat a tényezőket, amelyek hozzájárulnak ahhoz, hogy hegesztőként egyre nagyobb megbecsülésben részesülj.",
      
      Blog_Valuable_WorkerShortage_Title: "Növekvő munkaerőhiány",
      Blog_Valuable_WorkerShortage_Text:
        "A hegesztőipar egyik legnagyobb kihívása jelenleg a szakemberhiány. Sok tapasztalt hegesztő közeledik a nyugdíjhoz, miközben az utánpótlás nem tud lépést tartani a kieső munkaerővel. Az Európai Unióban a hegesztők iránti kereslet folyamatosan növekszik, és a European Employment Service jelentése szerint a hegesztő szakemberek hiánya az egyik legmagasabb az EU-ban. Ez a hiány növeli a meglévő hegesztők értékét a munkaerőpiacon.",
      
      Blog_Valuable_Infrastructure_Title: "Infrastruktúra fejlesztések és ipari növekedés",
      Blog_Valuable_Infrastructure_Text:
        "A globális infrastruktúra-fejlesztési projektek száma növekszik, legyen szó építőiparról, energetikai beruházásokról vagy közlekedési hálózatokról. Ezek a projektek mind hegesztőket igényelnek, hiszen nélkülük nem valósíthatók meg a szükséges szerkezetek és rendszerek. Az ipari növekedés további munkalehetőségeket teremt a hegesztők számára, és növeli a szakma értékét.",
      
      Blog_Valuable_Technology_Title: "Technológiai fejlődés és specializáció",
      Blog_Valuable_Technology_Text:
        "Az új technológiák megjelenése, mint például az orbitális hegesztés, lézerhegesztés és más fejlett hegesztési technikák, olyan szakembereket igényelnek, akik képesek alkalmazkodni és elsajátítani ezeket az innovációkat. Azok a hegesztők, akik lépést tartanak a technológiai fejlődéssel, különösen értékesek lesznek a munkaerőpiacon.",
      
      Blog_Valuable_Automation_Title: "Automatizáció és emberi szakértelem",
      Blog_Valuable_Automation_Text:
        "Bár az automatizáció egyre nagyobb szerepet kap az iparban, a hegesztők szakértelme továbbra is nélkülözhetetlen. Az automatizált rendszerek felügyelete, beállítása és karbantartása olyan feladatok, amelyekhez tapasztalt hegesztőkre van szükség. Az emberi kreativitás és problémamegoldó képesség pótolhatatlan, különösen összetett projektek esetén.",
      
      Blog_Valuable_Salary_Title: "Versenyképes bérezés és karrierlehetőségek",
      Blog_Valuable_Salary_Text:
        "A növekvő kereslet és a szakemberhiány miatt a hegesztők bérezése is emelkedik. Emellett számos lehetőség nyílik a szakmai előrelépésre, legyen szó specializációról vagy vezetői pozíciók betöltéséről. A folyamatos tanulás és fejlődés lehetővé teszi, hogy még értékesebbé válj a munkaadók szemében.",
      
      Blog_Valuable_Summary_Title: "Összegzés",
      Blog_Valuable_Summary_Text:
        "A hegesztő szakma előtt fényes jövő áll. A munkaerőhiány, az ipari növekedés és a technológiai fejlődés mind hozzájárulnak ahhoz, hogy hegesztőként egyre értékesebb legyél. Érdemes kihasználni ezeket a lehetőségeket, folyamatosan fejleszteni tudásodat és alkalmazkodni az új technológiákhoz. Így nemcsak biztosíthatod a helyed a munkaerőpiacon, hanem jelentős mértékben hozzájárulhatsz az ipar fejlődéséhez is.",
      
      Blog_Valuable_Author_Name: "Horváth Benedegúz",
      Blog_Valuable_Author_Title: "OrbitChamp CEO",




      Blog_Hungarian_Title: "A magyar orbitális hegesztő: az OrbitalChamp",
      Blog_Hungarian_Paragraph1:
        "Az ipari technológia folyamatos fejlődése számos innovációt hozott a hegesztési eljárások terén. Az egyik ilyen jelentős fejlesztés az orbitális hegesztés, amely különösen fontos szerepet tölt be a csővezetékek és csövek hegesztésében. Magyarország is büszkélkedhet egy ilyen innovációval: az OrbitalChamp nevű orbitális hegesztőberendezéssel.",
      
      Blog_Hungarian_WhatIs_Title: "Mi az orbitális hegesztés?",
      Blog_Hungarian_WhatIs_Text:
        "Az orbitális hegesztés egy automatizált hegesztési technológia, amelyben a hegesztőfej körbeforog a rögzített cső körül. Ez a módszer biztosítja az egyenletes és kiváló minőségű hegesztési varratokat, ami elengedhetetlen az olyan iparágakban, mint a gyógyszeripar, az élelmiszeripar vagy az olaj- és gázipar. Az orbitális hegesztés minimalizálja az emberi hibákat és növeli a termelékenységet.",
      
      Blog_Hungarian_Advantages_Title: "Az OrbitalChamp előnyei",
      Blog_Hungarian_Advantages_Text:
        "Az OrbitalChamp egy magyar fejlesztésű orbitális hegesztő, amely több szempontból is kiemelkedik a piacon:",
        Blog_Hungarian_Advantage_Compatibility : "Kompatibilitás:",
      Blog_Hungarian_Advantage_Compatibility_Text: " Az OrbitalChamp különlegessége, hogy kompatibilis a legtöbb meglévő hegesztőgéppel. Ez azt jelenti, hogy nem szükséges teljesen új berendezéseket vásárolni; az OrbitalChamp könnyedén integrálható a már meglévő eszközökkel.",
      Blog_Hungarian_Advantage_Cost: "Költséghatékonyság:",
      Blog_Hungarian_Advantage_Cost_Text: " Mivel nincs szükség új hegesztőgépek beszerzésére, jelentős költségmegtakarítás érhető el. Ez különösen előnyös a kisebb vállalkozások számára.",
        Blog_Hungarian_Advantage_Innovation: "Innováció:",
      Blog_Hungarian_Advantage_Innovation_Text: " Az OrbitalChamp fejlesztése során nagy hangsúlyt fektettek az olyan technológiákra, mint az alternáló száladagolás, amely tovább javítja a hegesztési varratok minőségét és csökkenti a hőbevitel mértékét.",
      
      Blog_Hungarian_Impact_Title: "Az OrbitalChamp hatása a hegesztőiparra",
      Blog_Hungarian_Impact_Text:
        "Az OrbitalChamp nemcsak egy új berendezés, hanem egy olyan megoldás, amely hozzájárul a hegesztőipar fejlődéséhez. Lehetővé teszi a hegesztők számára, hogy kihasználják az orbitális hegesztés előnyeit anélkül, hogy teljesen új rendszereket kellene megtanulniuk és használniuk. Ez elősegíti a gyorsabb és hatékonyabb munkavégzést, miközben fenntartja a magas minőségi követelményeket.",
      
      Blog_Hungarian_International_Title: "Magyar innováció nemzetközi szinten",
      Blog_Hungarian_International_Text:
        "Az OrbitalChamp már bizonyított itthon és külföldön egyaránt. A berendezést sikeresen alkalmazzák különböző iparágakban, és pozitív visszajelzéseket kapott a felhasználóktól. Ez a magyar innováció hozzájárul ahhoz, hogy hazánk neve ismertté váljon a hegesztési technológiák terén is.",
      
      Blog_Hungarian_Summary_Title: "Összegzés",
      Blog_Hungarian_Summary_Text:
        "Az OrbitalChamp egy kiváló példa arra, hogyan lehet a meglévő technológiákat továbbfejleszteni és hozzáférhetőbbé tenni a szélesebb felhasználói kör számára. Az orbitális hegesztés előnyeinek kihasználása mostantól nem csak a nagyvállalatok kiváltsága, hanem a kisebb cégek és műhelyek számára is elérhető.",
      
      Blog_Hungarian_Author_Name: "Horváth Benedegúz",
      Blog_Hungarian_Author_Title: "OrbitChamp CEO",

      Blog_NoJobsTaken_Title: "Miért Nem Veszi El a Hegesztők Munkáját az Orbitális Hegesztés",
      Blog_NoJobsTaken_Paragraph1: 
        "Az ipari technológiák fejlődése gyakran aggodalmat kelt a munkavállalók körében, különösen akkor, ha automatizációról és gépesítésről van szó. Az orbitális hegesztés egy ilyen innováció, amely első pillantásra fenyegetésnek tűnhet a hegesztők számára. Azonban a valóságban ez a technológia nem veszi el a hegesztők munkáját, sőt, új lehetőségeket és előnyöket kínál számukra. Lássuk, miért!",
      
      Blog_NoJobsTaken_Section1_Title: "1. Az Orbitális Hegesztés Kiegészíti a Hegesztők Munkáját",
      Blog_NoJobsTaken_Section1_Text1: 
        "Az orbitális hegesztés olyan speciális feladatokra lett kifejlesztve, ahol a precizitás és az ismételhetőség kritikus fontosságú, például vékony falú csövek hegesztésénél a gyógyszeriparban vagy az élelmiszeriparban. Ezek a feladatok gyakran monotónak és fizikailag megterhelőek, ezért az automatizálás nemcsak hatékonyabbá teszi a folyamatot, hanem a hegesztők számára is előnyös.",
      Blog_NoJobsTaken_Section1_Text2: 
        "A hegesztők továbbra is kulcsszerepet játszanak a folyamatban:",
      Blog_NoJobsTaken_Supervision: "Felügyelet és Ellenőrzés",
      Blog_NoJobsTaken_Supervision_Text: 
        "A gépek működését szakértő hegesztők felügyelik, akik biztosítják a minőséget és a biztonságot.",
      Blog_NoJobsTaken_Programming: "Programozás és Beállítás",
      Blog_NoJobsTaken_Programming_Text: 
        "Az orbitális hegesztőgépek programozását és beállítását szakképzett hegesztők végzik.",
      Blog_NoJobsTaken_Maintenance: "Karbantartás",
      Blog_NoJobsTaken_Maintenance_Text: 
        "A berendezések karbantartása és hibaelhárítása is szakmai tudást igényel.",
    
      Blog_NoJobsTaken_Section2_Title: "2. Új Készségek és Szakmai Fejlődés",
      Blog_NoJobsTaken_Section2_Text: 
        "Az orbitális hegesztés megjelenése lehetőséget teremt a hegesztők számára, hogy új készségeket sajátítsanak el és szakmailag fejlődjenek. A modern hegesztőtechnológiák ismerete növeli a munkavállalók értékét a munkaerőpiacon.",
      Blog_NoJobsTaken_TechnologySkills: "Technológiai Ismeretek",
      Blog_NoJobsTaken_TechnologySkills_Text: 
        "Az automatizált rendszerek kezelése és programozása új kompetenciákat igényel.",
      Blog_NoJobsTaken_CareerAdvancement: "Szakmai Előrelépés",
      Blog_NoJobsTaken_CareerAdvancement_Text: 
        "Az új technológiák elsajátítása lehetőséget teremt a karrier előrehaladására és magasabb pozíciók betöltésére.",
      Blog_NoJobsTaken_Education: "Oktatás és Képzés",
      Blog_NoJobsTaken_Education_Text: 
        "A hegesztők továbbképzése növeli a vállalatok versenyképességét és javítja a munkavállalók munkahelyi elégedettségét.",
    
      Blog_NoJobsTaken_Section3_Title: "3. Fókusz a Kreatív és Összetett Feladatokra",
      Blog_NoJobsTaken_Section3_Text: 
        "Az automatizáció felszabadítja a hegesztőket a repetitív és monotón feladatok alól, lehetővé téve számukra, hogy kreatívabb és összetettebb munkákra összpontosítsanak.",
      Blog_NoJobsTaken_UniqueJobs: "Egyedi Munkák",
      Blog_NoJobsTaken_UniqueJobs_Text: 
        "A speciális hegesztési feladatok, amelyek egyedi megoldásokat igényelnek, továbbra is emberi szakértelmet követelnek.",
      Blog_NoJobsTaken_ProblemSolving: "Problémamegoldás",
      Blog_NoJobsTaken_ProblemSolving_Text: 
        "A nem szabványos helyzetek kezelése és a váratlan problémák megoldása a hegesztők tapasztalatára támaszkodik.",
      Blog_NoJobsTaken_QualityAssurance: "Minőségbiztosítás",
      Blog_NoJobsTaken_QualityAssurance_Text: 
        "Az emberi szem és ítélőképesség pótolhatatlan a végső minőségellenőrzés során.",
    
      Blog_NoJobsTaken_Section4_Title: "4. Az Orbitális Hegesztés Nem Alkalmas Minden Feladatra",
      Blog_NoJobsTaken_Section4_Text: 
        "Bár az orbitális hegesztés számos előnnyel rendelkezik, nem alkalmazható minden hegesztési feladatnál. A komplex szerkezetek, nehezen hozzáférhető helyek és speciális anyagok hegesztése továbbra is tapasztalt hegesztőket igényel.",
    
      Blog_NoJobsTaken_Section5_Title: "5. A Munkaerőhiány Enyhítése",
      Blog_NoJobsTaken_Section5_Text: 
        "A hegesztőiparban világszerte munkaerőhiány tapasztalható. Az orbitális hegesztés segít enyhíteni ezt a hiányt azáltal, hogy növeli a termelékenységet, de nem helyettesíti a képzett szakembereket.",
    
      Blog_NoJobsTaken_Summary_Title: "Összegzés",
      Blog_NoJobsTaken_Summary_Text: 
        "Az orbitális hegesztés nem fenyegetés a hegesztők számára, hanem egy eszköz, amely megkönnyíti munkájukat és lehetőséget teremt a szakmai fejlődésre. Az emberi szakértelem és tapasztalat továbbra is nélkülözhetetlen a hegesztési folyamatokban. A technológia és az ember együttműködése vezet a legjobb eredményekhez, ezért érdemes nyitottan állni az innovációkhoz és kihasználni az általuk nyújtott előnyöket.",
    
      Blog_NoJobsTaken_Author_Name: "Horváth Benedegúz",
      Blog_NoJobsTaken_Author_Title: "OrbitChamp CEO",

// ========================================== ROI BLOG POST =============================================== 
      Blog_ROI_Disclainer: "",
      
      Blog_ROI_Title: "2 év alatt 2x OrbitalChamp ára megtakaritásban? Hogyan?",
      Blog_ROI_Title3 : "Hogyan térül meg?",
      Blog_ROI_Title2 : "Miért fontos a megfelelő eszköz kiválasztása?",

      Blog_ROI_Intro1: "A gyártási szektorban minden döntés pénzbe kerül, és a hegesztés sem kivétel. A hagyományos hegesztési módszerekkel gyakran előfordul, hogy nem optimálisak az erőforrások, és a hibák elkerülhetetlenek. De mi van, ha egy olyan megoldást találunk, ami nemcsak hogy csökkenti a hibák számát, hanem gyorsabb és hatékonyabb munkavégzést is biztosít?"
 +" Az OrbitalChamp termékei pontosan ezt kínálják: csökkentik a költségeket, miközben a hegesztési minőséget a legmagasabb szinten tartják. Ez a cikk segít megérteni, miért érdemes befektetni egy OrbitalChamp rendszerbe és hogyan térül meg a befektetés a hosszú távú használat során.", 
      Blog_ROI_Intro2: 
        "A pontosság, a minőség és a költséghatékonyság kulcsfontosságú a hegesztés világában. Egy elrontott csővezeték bizonyos iparágakban milliárdos veszteséget okozhat, más iparágakban csak idegesítő időkiesést. Az OrbitalChamp automata hegesztőrendszerrel nem csupán gyorsabb és precízebb munkavégzés érhető el, hanem rövid idő alatt megtérülő befektetés is!",

      Blog_ROI_Intro3 : "Kevés olyan cég létezik amely kizárólag csőhegesztésekkel foglalkozna. Ugyanakkor Orbitalchamp használatával a hegesztési munkák diverzifikálhatóak. Tehát a hegesztők pihenten és koncentráltan el tudják végezni az egyéb hegesztéseket, míg a csőhegesztést, ami egyébként is egy rengeteg koncentrációt igénylő munka, rá lehet bízni az Orbitalchamp-re. Hatékonyabb munkavégzés, kevesebb hiba, több sikerélmény a cégnek. Ha azonban arra lennénk kíváncsiak, hogy mennyi idő alatt térül meg az eszköz, amennyiben kizárólag csőhegesztéseket szeretnénk optimalizálni, akkor érdemes elolvasni a cikkünket.",

      Blog_ROI_WelderBruttText : "Hegesztő bruttó bére : ",
      Blog_ROI_WelderBruttVal : "1 020 000 Ft",
      Blog_ROI_WelderNetVal : "(nettó 600 000 Ft)",

      Blog_ROI_WorkerBruttText : "Betanított munkás bruttó bére : ",
      Blog_ROI_WorkerBruttVal : "530 000 Ft",
      Blog_ROI_WorkerNetVal : "(nettó 300 000 Ft)",

      Blog_ROI_OCBruttText : "OrbitalChamp ára : ",
      Blog_ROI_OcBruttVal : "2 750 000 Ft",

      Blog_ROI_SmallCompany : "A) Kisvállalkozás",
      Blog_ROI_MediumCompany : "B) Középvállalkozás",
      Blog_ROI_MediumBigCompany : "C) Közép-Nagyvállalkozás",





    Blog_ROI_Calculation_Title: "Mennyi idő alatt térül meg az OrbitalChamp? – Költségek és Megtérülés részletes elemzése",

    Blog_ROI_Calculation_Paragraph1: "5 hónap. Az lehetetlen! Az alábbiakban egy részletes elemzést nyújtunk, amelyben bemutatjuk, hogyan alakítja a költségeket az OrbitalChamp eszköz alkalmazása a hagyományos hegesztési folyamatokhoz képest. Három különböző vállalkozási szintet vizsgálunk: egy kisvállalkozás, egy középvállalkozás és egy közép-nagyvállalkozás. A célunk az, hogy megmutassuk, hogyan csökkenthetők a költségek és hogyan javítható a termelékenység, ha az OrbitalChamp-ot alkalmazzuk a hegesztési munkák során.",

    Blog_ROI_Calculation_Paragraph2: "Feltételezzük, hogy a következő 24 hónapban folyamatos hegesztési munkákra van szükség, amelyeket 1-4 hegesztővel állandó szerződésben végzünk. Célunk a termelés gyorsítása, a minőség javítása és a költségek csökkentése.",

    Blog_ROI_Calculation_Paragraph3: "Ezen kivül nem vesszük figyelembe a megnövekedett termelékenység hatására elvállalható többletmunkát és az abból származó plusz profitot. Csak azt vizsgáljuk meg, hogy hogyan alakulnak a költségek OrbitalChamp nélkül, illetve annak használatával.",

    Blog_ROI_Case_Title: "Három különböző esetet vizsgálunk:",
    Blog_ROI_Case1_Title: "Első eset: ",
    Blog_ROI_Case1_Text: "Csőhegesztést végzünk, de nem alkalmazunk külön hegesztőmérnököt",
    Blog_ROI_Case2_Title: "Második eset: ",
    Blog_ROI_Case2_Text: "Növelni szeretnénk a hegesztések mennyiségét, de a vállalkozás anyagi helyzete nem enged meg több mérnök alkalmazását. (Itt az OrbitalChamp segíthet!)",
    Blog_ROI_Case3_Title: "Harmadik eset: ",
    Blog_ROI_Case3_Text: "Jelentős bővülést szeretnénk elérni, de a lehető legköltséghatékonyabb módon.",

    Blog_ROI_Calculation_Paragraph4: "Tanulmányunk során azt nézzük meg hogy az összköltség mértéke hogyan változik az adott időszakon belül.",

    Blog_ROI_Calculation_Title2 : "Költségkalkulációk:",
    Blog_ROI_Calculation_Paragraph5 : "A következő táblázat bemutatja a költségeket a hagyományos hegesztéshez képest az OrbitalChamp használatával:",
   
    Blog_ROI_NumberOfWelders : "Hegesztők száma",
    Blog_ROI_Cost_WO_OC : "Költség OrbitalChamp nélkül 24 hónap alatt",
    Blog_ROI_Cost_W_OC : "Költség OrbitalChamp-el 24 hónap alatt",
    Blog_ROI_Diff_In_24_Months : "Különbség 24 hónap múlva",




    
    Blog_ROI_Scenario1_Title: "1 hegesztő vs. 1 betanított munkás + 1 OrbitalChamp eszköz",
    Blog_ROI_Scenario2_Title: "2 hegesztő vs. 1 hegesztő + 1 betanított munkás + 2 OrbitalChamp eszköz",
    Blog_ROI_Scenario3_Title: "4 hegesztő vs. 1 hegesztő + 1 betanított munkás + 3 OrbitalChamp eszköz",

    Blog_ROI_Cost_In_Ft_Scen1_WO_OC : "~ 25 Millió Ft",
    Blog_ROI_Cost_In_Ft_Scen1_W_OC : "~ 15 Millió Ft",
    Blog_ROI_Cost_In_Ft_Scen1_Diff : "~ 10 Millió Ft megtakaritás",

    Blog_ROI_Cost_In_Ft_Scen2_WO_OC : "~ 50 Millió Ft",
    Blog_ROI_Cost_In_Ft_Scen2_W_OC : "~ 30 Millió Ft",
    Blog_ROI_Cost_In_Ft_Scen2_Diff : "~ 20 Millió Ft megtakaritás",

    Blog_ROI_Cost_In_Ft_Scen3_WO_OC : "~ 100 Millió Ft",
    Blog_ROI_Cost_In_Ft_Scen3_W_OC : "~ 70 Millió Ft",
    Blog_ROI_Cost_In_Ft_Scen3_Diff : "~ 30 Millió Ft megtakaritás",

    Blog_ROI_Summary : "A megtakaritások mértéke is szép szám, viszont ami még vonzóbb lehet, az az, hogy a számítások alapján az 5-6. hónapban térül meg a befektetés. Ezen a ponton az OrbitalChamp használata mellett a költségek folyamatosan csökkennek, így a hosszú távú megtakarítások növekednek.",
    
    Blog_ROI_Outro_Title : "Miért Érdemes Belevágni?",
    Blog_ROI_Outro1 : "Az OrbitalChamp segítségével nemcsak a költségeket csökkenthetjük, hanem javíthatjuk a hegesztési folyamatok minőségét és gyorsaságát, diverzifikálhatjuk a hegesztéseinket és elkerülhetjük a hibás hegesztéseket.. Az eszközök hosszú távú alkalmazása még inkább jelentős megtakarítást eredményez a jövőbeni hegesztési projektek során.",
    Blog_ROI_Outro2 : "Ha további részletes számításokat szeretnél látni, vagy akár te is szeretnél játszadozni a megtérülés számlálóval, kattints a bal oldali gombra. Ha pedig kíváncsi vagy arra, hogyan működik maga az OrbitalChamp termék, kattings a jobboldali gombra!",

    Blog_ROI_Megterules_calc : "Megtérülés számitás",
    Blog_ROI_Bemutato : "Jelentkezem bemutatóra",


    ROI_Title : "Megtérülési kalkuláció",
    ROI_Intro : "Ebben a tanulmányban három eltérő vállalati méretben vizsgáljuk meg az OrbitalChamp rendszer gazdasági hatásait, figyelembe véve a beruházási és működési költségeket, valamint a munkavállalói bérstruktúrát. Az elemzés célja a költségcsökkentés és a megtérülési idő számszerűsítése.",
    ROI_Title_Base_Data : "Alapadatok és Feltételezések",
    ROI_Base_Data_Par1 : "Minden matematikai számitás alapja az hogy feltételeket, kereteket adunk meg. Jelen esetben az az alapfeltevés, hogy a következő 24 hónapban folyamatos hegesztési munkára van szükség, és a vállalat állandó szerződéssel alkalmaz 1-4 szakembert.  Azt a munkatöbbletet amelyet a gép használatának köszönhetően pluszban el tudunk vállalni, jelen számítás mellőzi.",
    ROI_Base_Data_Par2 : "A számításokhoz szükséges adatok: Egy hegesztő és egy betanított munkás átlagos bére, valamint azok szuperbruttó költségei. Ezek:",
    
    ROI_Variable_Names : "Egyéb fontos változók nevei: ",
    
    ROI_WelderWageText: "Hegesztők bruttó bére",
    ROI_WelderCntText: "Hegesztők száma",
    ROI_WelderCntTextVesszo : "Hegesztők megváltozott száma",
    ROI_WorkerWageText: "Betanitott munkások bruttó bére",
    ROI_WorkerCntText: "Betanitott munkások száma",
    ROI_OrbitalCntText: "Orbitalchamp gépek száma",
    ROI_CostVariableWeld : "Kölség hegesztők alkalmazásával",    
    ROI_CostVariableOrbi : "Kölség hegesztők és Orbitalchamp alkalmazásával",    
    ROI_DiffVarName : "Kötlségek különbsége",
    ROI_MonthsCnt : "Hónapok száma",
    ROI_NullLine : "Megtérülési vonal",
    ROI_Machine_Price : "Gépek ára",
    


    ROI_Intro2 : "Elemzésünk során három különböző vállalati struktúrát vizsgálunk:",

    ROI_CostTitle : "A Költségelemzési Modell",
    ROI_Cost_Par1 : "A számításainkhoz használt képletek mindig azonosak, melyek a következők:",
    ROI_Cost_Formula1 : "A teljes munkabérköltség az automatizáció nélküli esetben:",
    ROI_Cost_Formula2 : "Az OrbitalChamp rendszerrel történő működés költsége: ",
    ROI_Cost_Formula3 : "A két költség közötti eltérés: ",
    
    ROI_Searched_Point : "A keresett pont: ",
    ROI_Cost_Par2 : "Amint ezen érték a nullához közelít, ott található a megtérülési pont. Ez azt az időt jelzi, amikor a költségek összege mindkét variációban azonos. Tehát ebben a pontban lényegtelen hogy van e OrbitalChamp hegesztőgépunk, vagy pedig a hegesztőket alkalmazzuk. Viszont ezen pont felett már jelentős a különbség.",
    ROI_Cost_Par3 : "Ezen képleteket amiatt osztjuk meg a kedves olvasóval, hogy a számításokat le tudják ellenőrizni tetszőleges bemenettel, vagy éppen az itt megadott adatokkal. Viszont az oldalon egy interaktív diagram készítő is jelen lesz, ahol bárki beírhat bármit, és tesztelheti, hogy melyik megoldás a legeffektívebb neki.",
  
    ROI_Results : "Eredmények",
    ROI_Result1_Title : "1. Kisvállalkozás esetén ",
    ROI_Result1_Text : "a 24 hónapos időszak végére a kizárólag hegesztőmérnök alkalmazásával járó költségek elérik a 25 millió forintot, míg az OrbitalChamp rendszerrel történő működés költsége 15 millió forint lesz. A megtérülési idő 5-6 hónap, míg a rendszer teljes beruházási költsége 11 hónap alatt megtérül.",
    ROI_Result2_Title : "2. Középvállalkozás esetén",
    ROI_Result2_Text : " hasonló trend figyelhető meg, a megtérülés 5-6 hónapnál következik be, míg a 24. hónap végére a költségkülönbség 20 millió forint körüli értéket ér el. A beruházás 10-11 hónap alatt térül meg.",
    ROI_Result3_Title : "Közép-Nagyvállalkozás",
    ROI_Result3_Text : "3. Közép-Nagyvállalkozás esetén a kezdeti beruházás ugyan magasabb, azonban a nagyobb volumenű termelési kapacitás révén a 24. hónap végére a költségkülönbség már 30 millió forintra növekszik. A megtérülési pont itt is 5-6 hónapnál található, míg a teljes beruházás 11 hónapon belül fedezhető.",
    ROI_Conclusion : "Konklúzió",
    ROI_Outro1 : "Az elemzésből egyértelműen látható, hogy az OrbitalChamp automatizált hegesztőrendszer alkalmazása jelentős költségmegtakarítást eredményez a hagyományos manuális hegesztéssel szemben. A megtérülési idő rövid, általában 5-6 hónap között mozog, míg a teljes beruházási költség 10-11 hónapon belül megtérül. Ezt 3 jellegében hasonló, mégis különböző esettel bizonyitottuk.",
    ROI_Outro2 : "Az automatizált hegesztési technológia bevezetésével nem csupán a költségek csökkenthetők, hanem a termelékenység és a hegesztési minőség is növekszik, ami hosszú távon versenyelőnyt biztosít a vállalatok számára. Az eredmények alapján elmondható, hogy a rendszer alkalmazása nem csupán gazdasági szempontból javallott, hanem a hatékonyság és minőség terén is jelentős előnyökkel jár. Erről egy későbbi cikkben fogunk majd írni. ",
    ROI_Outro3 : "Amennyiben érdeklődne a termék irány, vegye fel velünk a kapcsolatot az alábbi oldalon:",
   
    ROI_SporoltOszzeg : "Megsporolt összeg a 24.Hónap végén:",
    ROI_Osszkoltseg_Hegeszto : "Orbitalchamp nélküli összköltség a 24.Hónap végén:",
    ROI_Osszkoltseg_Orbitalchamp : "Összköltség OrbitalChampet használva a 24.Hónap végén:",

    ROI_Money : "Millió Ft",

    CostCalculator: "Költségszámítás" 

  },
  en: {
    Navbar_Product_title: 'Product',
    Navbar_Application_title: 'Welding Machines',
    Navbar_Weldings_title: 'Our Weldings',
    Navbar_Contact_title: 'Contact',
    Navbar_OrbitalWelding_title: 'Orbital Welding',
    Welcome_Button: 'Get in touch with us!',
    Change: "Change",

  Footer_Location: 'Location',

      ContactApplyTitle: "Sign up for the demo!",
      Contact_TestDrive: 'Choose a date!',
      Contact_AtYou: 'At your place',
      Contact_AtUs: 'At us',
      Contact_AtYouPlace: 'Your address',
      Contact_ChooseDate: 'Choose a date',
     Contact_Info: 'After pressing the send button, we will contact you to let you know when the demo will be. The reason we ask for the address is so that we can work out the logistical details',
      Contact_Info2: 'If you know when you can come to us, please choose a time',
 

    About_Head_title: 'OrbitalChamp',
    About_Head_desc: 'The OrbitalChamp is a device, which can make X-ray proof, high quality and perfect precision welding, even by employees who do not have a welding qualification. ',
    About_High_Quality_Weldings_title: 'Certified welding',

    About_High_Quality_Weldings_desc: 'With the OrbitalChamp high quality welds can be performed. As evidence of this, we have a TÜV certification.',
    About_Track_Setting_title: 'Welding path positioning',
    About_Track_Setting_desc: 'You have the chance to control the welding path during the welding process.',
    About_Electronic_title: 'Electronic controller',
    About_Electronic_desc: 'An electronic controller guides the welding process entirely automatically. This device is programmable with a mobile application.',
    About_UI_title: 'Remote Controller',
    About_UI_desc: 'The OrbitalChamp is a remote-controlled device equipped with three buttons, a switch, and three LEDs. The buttons are used to position the head and initiate the welding process. The switch controls the on/off function of the remote control. The LEDs indicate the start and end of the welding process, as well as the Bluetooth connection.',
    About_Your_Welder_title: 'Connect your own machine!',
    About_Your_Welder_desc: 'One of the key strengths of the OrbitalChamp is it is compatible with every type of TIG welder, the welder can use the welding machine they are familiar with.',
    About_All_Pipe_title: 'Fits all pipe diameters',
    About_All_Pipe_desc: 'Our product is available with two welding heads. One allows welding from DIN20 to DIN50, while the other enables welding from DIN50 to DIN100. Within the size range, various size of inserts can be used for different pipes.',
    About_Next: 'Next',
    About_Back: 'Back',

    WeldingMachines_Title: 'Which welding machine would you like to connect to?',
    WeldingMachines_Button: 'More TIG welding machines',

    WeldingMachines_mobile_Title: 'Welding Machines',
    WeldingMachines_mobile_Button: 'Watch the video!',

    WeldingsAndForm_Title: 'Our Orbital Weldings',
    WeldingsAndForm_Form_Title: 'Try our Orbital Welder for free!',
    WeldingsAndForm_Form_Address: 'Location: 1224 XVI. street 9/a',
    WeldingsAndForm_Form_Button: 'Register for contact!',
    WeldingsAndForm_Form_Button_Offer: 'Request for an offer!!',
    WeldingsAndForm_Form_Button_Demo: 'Register for demo!',
    Webshop: 'Webshop',

    
    OrbitalWeldings_Title: 'Orbital Welding',
    OrbitalWeldings_P1: 'Orbital welding is an advanced welding technology primarily used for the precision welding of pipes and cylindrical structures. The process provides precise, high-quality seams and is especially important in industries such as pharmaceuticals, food processing, and oil and gas, where welding quality and hygiene are crucial.', 
    OrbitalWeldings_P2: 'The greatest advantages of orbital welding include excellent welding quality and process repeatability. The automated system ensures that each weld is of the same high standard, minimizing errors and reducing the need for subsequent inspections. Moreover, orbital welding is faster than traditional methods, which reduces the time of operations and thereby the costs.',
    OrbitalWeldings_P3: 'Investing in an orbital welding system can pay off in the long run as it reduces labor costs, improves production efficiency, and enhances product quality. Such systems are particularly suitable for projects requiring extensive pipeline welding where quality is non-negotiable. Thus, orbital welding not only provides a technological advantage but also offers a competitive edge to those committed to excellent quality.',

    Application_title: 'Welding Machines',
    Application_Settings_title: 'Settings',
    Application_Settings_Start_Welder_title: 'Start Welding Impulse',
    Application_Settings_Start_Welder_desc: 'Always set the Start Welding Impulse 0.5 s more then gas pre-dosing',
    Application_Settings_Delay_title: 'Start delay',
    Application_Settings_Delay_desc: 'Set the delay until the material reaches the appropriate temperature',
    Application_Settings_Rotation_Speed_title: 'Rotation speed',
    Application_Settings_Rotation_Speed_desc: 'We can divide the diameter of the pipe into 4 parts. In each part we can set a different speed',
    Application_Settings_OverRun_title: 'Overrun',
    Application_Settings_OverRun_desc: 'Here we can set where the welding will end after 360 degrees.',
    Application_Settings_Stop_Impulse_title: 'Stop Impulse',
    Application_Settings_Stop_Impulse_desc: 'The stop impulse defines the point of the arc removal.',
    Application_Settings_Stop_Welder_Impulse_title: 'Stop Welding Impulse',
    Application_Settings_Stop_Welder_Impulse_desc: 'The stop welding impulse defines the time of the arc removal',
    Application_Settings_Rotation_Direction_title: 'Rotation Direction',
    Application_Settings_Rotation_Direction_desc: 'This defines the rotation direction',
    Application_Settings_Meta_title: 'Transmission',
    Application_Settings_Meta_desc: 'Different head diameters belong to different transmission, and this can be set here',
    Application_Get_More_Information: 'Find out more!',
    Application_Inquery_title: 'Inquiry',
    Application_Inquery_desc: 'There is a full screen display where we can inquire which parameters were uploaded to the device',
    Application_Save_BT_title: 'Save',
    Application_Save_title: 'Saveable welding parameters!',
    Application_Save_desc: "The application provides the chance to save the welding parameters and call them back any time, then upload them  to one or more devices. A very useful strength of our device follows from this. Let's take a look at a useful example: The welding engineer programs the device in the workshop to achieve a high-quality and X-ray proof welding. He/She uploads this to one or more devices, and saves it to the application before handing the smart welding box to the semi-skilled worker, who attaches the orbital welding head to the pipe. This will produce as high-quality welding result as the welder could achieve himself in the workshop. This process is repeatable anytime, anywhere.",
    Application_Or: 'or',

    Shop_Cancel: 'Cancel',


    Weldings_title: 'Our Weldings',
    Weldings_seams: 'Beads',
    Weldings_root: 'Root',
    Weldings_factory: 'Production',

    Contact_title: 'Contact',
    Contact_name: 'Name',
    Contact_email: 'E-mail',
    Contact_Company_name: 'Company name',
    Contact_Phone: 'Phone',
    Contact_message: 'Message',
    Conatct_message_desc: 'Write the message here!',
    Conatct_Send: 'Send',

    Shop_SmartWeldingSystemTitle: 'OrbitalChamp welding system',
    Shop_WelderheadTitleDIN50: 'OrbitalChamp welding head DIN20-DIN50',
    Shop_WelderheadTitleDIN100: 'OrbitalChamp welding head DIN50-DIN100',
    Shop_SmartBoxTitle: 'OrbitalChamp Smartbox',
    Shop_BackToMainPage: 'Back to the main page',
    Shop_OurProducts: 'Our products',
    Shop_OurProduct: 'Our product',
    Shop_Price: 'Price',
    VAT: 'VAT',
    Shop_Item_SmartWelding_Info: "The OrbitalChamp welding system can also be purchased as a complete set. This package includes the Smartbox and a choice of orbital jet size.",
    Shop_Item_Contains: "Contents:",
    Shop_Item_OrbitalWelder: "Orbital welding head",
    Shop_Item_Inlay: "4 pcs of inlays",
    Shop_Item_Welding_Connector: "Welding connector",
    Shop_Item_WorkCable: "Work cable",
    Shop_Item_WelderHead: "Welding head",
    Shop_Item_Back : "Back",
    
    Shop_Item_Offer : "Request an offer!",
    Shop_Item_Compatible_Devices : "Compatible Devices",
    Shop_Item_Orbital_Products : "Contents of the package",
    Shop_Item_Compatible_Devices_Info : "Select one so we know which machine you would use it for",
    Shop_Item_Download_UM : "Download our user manual",
    Shop_Item_Download_Text : "Download",
    Shop_Item_Download_Android : "Download for Android",
    Shop_Item_Download_IOS : "Download for iOS",

    Shop_Item_Your_Email : "Your E-mail",
    Shop_Item_Know_More : "Learn more about our product",
    Shop_Item_Sizes : "Size ranges",
    Shop_Item_Pro : "Advantages",
    Shop_Item_Successful_Sending : "The message was sent successfully!", 
    Shop_Item_Unsuccessful_Sending : "An error occurred while sending the message!",
    Shop_Item_Bad_Email : "The e-mail address is not correct",
    Shop_Item_Bad_Number : "The telephone number is not correct",

    Shop_Item_SWS_Vorteil1 : "Compatible with any TIG welder",
    Shop_Item_SWS_Vorteil2 : "Welding with perfect precision and quality thanks to the fixed arc support",
    Shop_Item_SWS_Vorteil3 : "Welding can be repeated at any time thanks to the parameters that can be saved",
    Shop_Item_SWS_Vorteil4 : "Set the perfect weld with your phone!",
    Shop_Item_SWS_Vorteil5 : "Can be applied to any pipe size by the inserts",
    Shop_Item_SWS_Vorteil6 : "Affordable price, high quality, reliability",

          Shop_Item_IndexRemote: "Remote Controller",
      Shop_Item_IndexOrbitalWelderHead: "Orbital Welding head",
      Shop_Item_IndexSmartBox : "SmartBox (Electronics)",
      Shop_Item_IndexKonzol : "Console",
      Shop_Item_IndexWeldConnector : "Welding Connector",
      Shop_Item_IndexBetet : "Insert",
      Shop_Item_IndexMotorCable : "Motor cable 3 [m] or 3+ [m]",
      Shop_Item_IndexWelderHeadEnd : "Welding head",
      Shop_Item_IndexPowerSupply : "12V power supply",
      Shop_Item_IndexArbeitKabel : "Work cable 3 [m] or 3+ [m]",

      Shop_Item_SizeOfWelderHead : 'Dimensions of the welding head:', 
      Shop_Item_Size_WidthDIN50 : 'Width: 185 [mm]',
      Shop_Item_Size_LengthDIN50 : 'Length: 480 [mm]',
      Shop_Item_Size_HeightDIN50 : 'Height: 155 [mm]',
      Shop_Item_Size_WidthDIN100 : 'Width: 245 [mm]',
      Shop_Item_Size_LengthDIN100 : 'Length: 480 [mm]',
      Shop_Item_Size_HeightDIN100 : 'Height: 155 [mm]',
      Shop_Item_Size_WidthSmart : 'Width: 120 [mm]',
      Shop_Item_Size_LengthSmart : 'Length: 240 [mm]',
      Shop_Item_Size_HeightSmart : 'Height: 40 [mm]',
            Shop_Item_Size_Smartbox : 'Size of the Smartbox',
      Shop_Item_Size_Cable : 'Dimensions of the cables',
      Shop_Item_CurrectCable : 'Work cable: 3 [m] (expandable)',
      Shop_Item_MotorCable : 'Motor cable: 3 [m] (expandable)',
      Shop_Item_WeldingRangeDIN50 : 'Has a 20 [mm] - 60.3 [mm] gripping range',
      Shop_Item_WeldingRangeDIN100 : 'Has a 60.3 [mm] - 114.3 [mm] gripping range',

      Shop_Item_SmartBoxInfo : "The OrbitalChamp SmartBox is an automated welding control system that ensures precise, reliable welding processes. Easy integration into existing systems with smartphone control",
      Shop_Item_DIN50Info : "Welding head suitable for 20 mm - 60.3 mm diameter pipes, providing precise and reliable welding in industrial environments. Easy to integrate with the OrbitalChamp SmartBox.",
      Shop_Item_DIN100Info : "Welding head suitable for pipes with diameters from 60.3 mm to 114.3 mm, providing precise and reliable welding in an industrial environment. Easy to fit to the OrbitalChamp SmartBox.",




      Toast_Error_Email: 'Error in sending email',


    Blog_Author: "Author",
    Blog_TitulusMecha: "Certified Mechatronics Engineer",
    Blog_Illustracio: "Image for illustration",
  
    Blog_Top3_Title_Meta: "The History of Orbital Welding!",
    Blog_Top3_Description_Meta: "Orbital welding is a specialized, automated welding technology primarily used for joining pipes and pipelines. The development of the technology dates back to the 1960s, when the rise of space exploration and the nuclear energy industry demanded new, reliable welding methods.",
    Blog_Top3_Title: "Top 3 Reasons to Use ORBITAL WELDING!",
    Blog_Top3_Paragraph1: "In recent years, orbital welding technology has gained significant ground in the welding industry. But why choose orbital welding over traditional methods? Here are three compelling reasons why modern technology is worth choosing:",
    Blog_Top3_T1_Strong: "Outstanding Precision and Repeatability:",
    Blog_Top3_T1_Text: "The greatest advantage of orbital welding is its incredible precision and repeatability. The welding machine can be automatically adjusted to ensure that the weld seam is uniform and consistent at every stage of the process. This is particularly important in industries where weld quality directly impacts product safety and lifespan, such as the pharmaceutical or food industry.",
    Blog_Top3_T2_Strong: "Reduced Work Time and Increased Efficiency:",
    Blog_Top3_T2_Text: "The speed and efficiency of orbital welders can drastically reduce the time required for work. Since the machines are partially automated, less time and resources are needed for process training. Additionally, the reduced welding time allows projects to be completed faster, boosting productivity and optimizing industrial operations.",
    Blog_Top3_T3_Strong: "Enhanced Workplace Safety:",
    Blog_Top3_T3_Text: "The automation of the welding process not only makes it more efficient but also safer. Orbital welders minimize the errors and accidents associated with manual welding since the operator requires much less physical intervention during the process. This creates better health conditions for users, further reducing potential health risks.",
    Blog_Top3_P2: "If you're looking for an innovative technology that can revolutionize the welding industry with a focus on precision, efficiency, and safety, orbital welding is the best choice. The lower error rate and faster completion not only save time but also improve the quality of the final product.",
   
    Blog_History_Title: "The History of Orbital Welding",
   Blog_History_Paragraph1: 
     "Orbital welding is a specialized, automated welding technology primarily used for joining pipes and pipelines. The technology emerged in the 1960s, during the rise of space exploration and the nuclear energy industry, which demanded new, reliable welding methods.",
   Blog_History_TheBeginnings_Title: "The Beginnings",
   Blog_History_TheBeginnings_Text:
     "The birth of orbital welding is closely tied to the U.S. space program. For NASA, it was crucial that the welding of pipelines used in rockets and spacecraft be flawless, as even the smallest error could have catastrophic consequences. The first orbital welding machines were developed by North American Aviation, specifically designed for the precise welding of thin-walled stainless steel pipes.",
   
   Blog_History_TwoTypes: "During its development, two main types of welding heads emerged:",
   
   Blog_History_Technology_Title: "Technological Advancement",
   Blog_History_Technology_Text:
     "During the 1970s and 1980s, the technology further advanced and spread to other industries such as chemical, pharmaceutical, and food processing. In these sectors, cleanliness and weld quality are of utmost importance, making orbital welding the ideal solution.",
   
   Blog_History_Heads_Title: "Open and Closed Welding Heads",
   Blog_History_OpenHeads: "Open Welding Heads:",
   Blog_History_OpenHeads_Text: 
     "These allow direct observation of the welding process and are more flexible for different pipe sizes.",
   Blog_History_ClosedHeads: "Closed Welding Heads:",
   Blog_History_ClosedHeads_Text: 
     "The welding area is enclosed in a sealed chamber where inert gas protects the weld from oxidation, allowing for high-quality welds.",
   
   Blog_History_Modern_Title: "Modern Developments and OrbitalChamp",
   Blog_History_Modern_Text: 
     "Today, orbital welding is widely used and continues to evolve. In Hungary, significant advancements have been made in this field. OrbitalChamp is a Hungarian-developed orbital welding machine that is unique in its compatibility with most existing welding machines. This allows businesses to leverage the benefits of orbital welding without the need to invest in an entirely new system.",
   
   Blog_History_Importance_Title: "The Significance of Orbital Welding",
   Blog_History_Importance_Text: 
     "The history of orbital welding is the result of the intersection of technological innovation and industrial demand. The method's advantages include the production of high-quality, repeatable welds, reducing work time, and increasing productivity. These factors make the technology indispensable in many industries.",
   
   Blog_History_Summary_Title: "Summary",
   Blog_History_Summary_Text: 
     "Over the past decades, orbital welding has undergone significant development and continues to play a key role in modern industrial processes. Continuous innovation and domestic developments like OrbitalChamp ensure that this technology will remain crucial in the future.",
   
   Blog_Author_Name: "Tibor Horváth",
   Blog_Author_Title: "OrbitChamp CEO",
   
   Blog_Valuable_Title: "Why You'll Become More Valuable as a Welder",
   Blog_Valuable_Paragraph1: 
     "In recent years, the demand for welders has increased significantly, and this trend is expected to continue in the future. But why will you become more valuable as a welder, and how can you take advantage of the opportunities in the profession? In this post, we examine the factors that contribute to the increasing appreciation of welders.",
   
   Blog_Valuable_WorkerShortage_Title: "Growing Workforce Shortage",
   Blog_Valuable_WorkerShortage_Text: 
     "One of the biggest challenges in the welding industry today is the shortage of skilled workers. Many experienced welders are nearing retirement, while the younger generation is not keeping pace with the loss of labor. Across the European Union, the demand for welders continues to grow, and according to the European Employment Service, the shortage of welding professionals is one of the highest in the EU. This shortage increases the value of existing welders in the labor market.",
   
   Blog_Valuable_Infrastructure_Title: "Infrastructure Development and Industrial Growth",
   Blog_Valuable_Infrastructure_Text: 
     "The number of global infrastructure development projects is on the rise, whether in construction, energy investments, or transportation networks. All these projects require welders, as the necessary structures and systems cannot be realized without them. Industrial growth creates more job opportunities for welders and increases the value of the profession.",
   
   Blog_Valuable_Technology_Title: "Technological Advancement and Specialization",
   Blog_Valuable_Technology_Text: 
     "The emergence of new technologies such as orbital welding, laser welding, and other advanced welding techniques requires professionals who can adapt and master these innovations. Welders who keep up with technological advancement will become particularly valuable in the job market.",
   
   Blog_Valuable_Automation_Title: "Automation and Human Expertise",
   Blog_Valuable_Automation_Text: 
     "While automation is playing an increasing role in the industry, welders' expertise remains indispensable. The oversight, setup, and maintenance of automated systems are tasks that require experienced welders. Human creativity and problem-solving skills are irreplaceable, especially in complex projects.",
   
   Blog_Valuable_Salary_Title: "Competitive Salaries and Career Opportunities",
   Blog_Valuable_Salary_Text: 
     "Due to the growing demand and shortage of skilled workers, welders' wages are rising. In addition, there are many opportunities for career advancement, whether through specialization or taking on leadership positions. Continuous learning and development enable you to become even more valuable to employers.",
   
   Blog_Valuable_Summary_Title: "Summary",
   Blog_Valuable_Summary_Text: 
     "The future is bright for the welding profession. The workforce shortage, industrial growth, and technological advancement all contribute to welders becoming increasingly valuable. It's worth taking advantage of these opportunities, continuously improving your skills, and adapting to new technologies. In doing so, you will not only secure your place in the labor market but also make a significant contribution to the development of the industry.",
   
   Blog_Valuable_Author_Name: "Benedegúz Horváth",
   Blog_Valuable_Author_Title: "OrbitChamp CEO",

   Blog_Hungarian_Title: "The Hungarian Orbital Welder: OrbitalChamp",
   Blog_Hungarian_Paragraph1:
     "The continuous evolution of industrial technology has brought numerous innovations in welding procedures. One significant development is orbital welding, which plays a particularly important role in the welding of pipelines and tubes. Hungary is proud to present such an innovation: the OrbitalChamp orbital welding device.",
   
   Blog_Hungarian_WhatIs_Title: "What is Orbital Welding?",
   Blog_Hungarian_WhatIs_Text:
     "Orbital welding is an automated welding technology in which the welding head rotates around a fixed pipe. This method ensures uniform and high-quality welds, which are essential in industries like pharmaceuticals, food, and oil and gas. Orbital welding minimizes human errors and increases productivity.",
   
   Blog_Hungarian_Advantages_Title: "Advantages of OrbitalChamp",
   Blog_Hungarian_Advantages_Text:
     "The OrbitalChamp is a Hungarian-developed orbital welder that stands out in the market for several reasons:",
     Blog_Hungarian_Advantage_Compatibility : "Compatibility:",
   Blog_Hungarian_Advantage_Compatibility_Text: " The uniqueness of OrbitalChamp is that it is compatible with most existing welding machines. This means there is no need to purchase entirely new equipment; the OrbitalChamp can be easily integrated with existing tools.",
   Blog_Hungarian_Advantage_Cost: "Cost-Effectiveness:",
   Blog_Hungarian_Advantage_Cost_Text: " As there is no need to buy new welding machines, significant cost savings can be achieved. This is particularly beneficial for smaller businesses.",
     Blog_Hungarian_Advantage_Innovation: "Innovation:",
   Blog_Hungarian_Advantage_Innovation_Text: " The development of OrbitalChamp focused heavily on technologies such as alternating wire feeding, which further enhances weld quality and reduces heat input.",
   
   Blog_Hungarian_Impact_Title: "Impact of OrbitalChamp on the Welding Industry",
   Blog_Hungarian_Impact_Text:
     "OrbitalChamp is not just a new machine; it is a solution that contributes to the development of the welding industry. It allows welders to take advantage of the benefits of orbital welding without having to learn and use entirely new systems. This promotes faster and more efficient work while maintaining high quality standards.",
   
   Blog_Hungarian_International_Title: "Hungarian Innovation on the International Stage",
   Blog_Hungarian_International_Text:
     "OrbitalChamp has already proven itself both domestically and internationally. The machine is successfully used in various industries and has received positive feedback from users. This Hungarian innovation contributes to making our country known in the field of welding technologies.",
   
   Blog_Hungarian_Summary_Title: "Summary",
   Blog_Hungarian_Summary_Text:
     "OrbitalChamp is an excellent example of how existing technologies can be further developed and made accessible to a broader user base. Taking advantage of the benefits of orbital welding is now not just a privilege of large companies but is also available to smaller firms and workshops.",
   
   Blog_Hungarian_Author_Name: "Benedek Horváth",
   Blog_Hungarian_Author_Title: "OrbitalChamp CEO",
   
   Blog_NoJobsTaken_Title: "Why Orbital Welding Does Not Take Welders' Jobs",
   Blog_NoJobsTaken_Paragraph1: 
     "The advancement of industrial technologies often raises concerns among workers, especially regarding automation and mechanization. Orbital welding is one such innovation that may initially seem threatening to welders. However, in reality, this technology does not take welders' jobs but instead offers new opportunities and benefits. Let's see why!",
   
   Blog_NoJobsTaken_Section1_Title: "1. Orbital Welding Complements Welders' Work",
   Blog_NoJobsTaken_Section1_Text1: 
     "Orbital welding has been specifically developed for tasks where precision and repeatability are critical, such as welding thin-walled pipes in the pharmaceutical or food industry. These tasks are often monotonous and physically demanding, so automation not only makes the process more efficient but also benefits welders.",
   Blog_NoJobsTaken_Section1_Text2: 
     "Welders still play a key role in the process:",
   Blog_NoJobsTaken_Supervision: "Supervision and Control",
   Blog_NoJobsTaken_Supervision_Text: 
     "The operation of the machines is supervised by skilled welders who ensure quality and safety.",
   Blog_NoJobsTaken_Programming: "Programming and Setup",
   Blog_NoJobsTaken_Programming_Text: 
     "The programming and setup of orbital welding machines are performed by trained welders.",
   Blog_NoJobsTaken_Maintenance: "Maintenance",
   Blog_NoJobsTaken_Maintenance_Text: 
     "The maintenance and troubleshooting of the equipment also require professional knowledge.",
 
   Blog_NoJobsTaken_Section2_Title: "2. New Skills and Professional Development",
   Blog_NoJobsTaken_Section2_Text: 
     "The emergence of orbital welding provides welders with the opportunity to acquire new skills and develop professionally. Knowledge of modern welding technologies increases workers' value in the job market.",
   Blog_NoJobsTaken_TechnologySkills: "Technological Knowledge",
   Blog_NoJobsTaken_TechnologySkills_Text: 
     "The operation and programming of automated systems require new competencies.",
   Blog_NoJobsTaken_CareerAdvancement: "Career Advancement",
   Blog_NoJobsTaken_CareerAdvancement_Text: 
     "Learning new technologies provides opportunities for career advancement and the filling of higher positions.",
   Blog_NoJobsTaken_Education: "Education and Training",
   Blog_NoJobsTaken_Education_Text: 
     "The further education of welders increases the competitiveness of companies and improves employee job satisfaction.",
 
   Blog_NoJobsTaken_Section3_Title: "3. Focus on Creative and Complex Tasks",
   Blog_NoJobsTaken_Section3_Text: 
     "Automation frees welders from repetitive and monotonous tasks, allowing them to focus on more creative and complex jobs.",
   Blog_NoJobsTaken_UniqueJobs: "Unique Tasks",
   Blog_NoJobsTaken_UniqueJobs_Text: 
     "Special welding tasks, which require unique solutions, continue to demand human expertise.",
   Blog_NoJobsTaken_ProblemSolving: "Problem Solving",
   Blog_NoJobsTaken_ProblemSolving_Text: 
     "Dealing with non-standard situations and solving unexpected problems relies on the experience of welders.",
   Blog_NoJobsTaken_QualityAssurance: "Quality Assurance",
   Blog_NoJobsTaken_QualityAssurance_Text: 
     "The human eye and judgment are irreplaceable during final quality inspection.",
 
   Blog_NoJobsTaken_Section4_Title: "4. Orbital Welding is Not Suitable for Every Task",
   Blog_NoJobsTaken_Section4_Text: 
     "Although orbital welding has many advantages, it cannot be applied to every welding task. Welding complex structures, hard-to-reach places, and special materials still require experienced welders.",
 
   Blog_NoJobsTaken_Section5_Title: "5. Alleviating Labor Shortages",
   Blog_NoJobsTaken_Section5_Text: 
     "The welding industry is experiencing labor shortages worldwide. Orbital welding helps alleviate this shortage by increasing productivity, but it does not replace skilled professionals.",
 
   Blog_NoJobsTaken_Summary_Title: "Summary",
   Blog_NoJobsTaken_Summary_Text: 
     "Orbital welding is not a threat to welders; rather, it is a tool that makes their work easier and creates opportunities for professional development. Human expertise and experience remain essential in welding processes. The collaboration between technology and humans leads to the best results, so it is worthwhile to remain open to innovations and take advantage of the benefits they offer.",
 
   Blog_NoJobsTaken_Author_Name: "Benedek Horváth",
   Blog_NoJobsTaken_Author_Title: "OrbitalChamp CEO",


// ========================================== ROI BLOG POST =============================================== ű

      Blog_ROI_Disclainer: "Disclaimer! This study was done for the Hungarian market and for the tax system of Hungary. The values are in forints!",


      Blog_ROI_Title: "2 years 2x OrbitalChamp price in savings? How?",
      Blog_ROI_Title3 : "How to recoup?",
      Blog_ROI_Title2 : "Why it's important to choose the right tool",

      Blog_ROI_Intro1 : "Every decision in manufacturing costs money, and welding is no exception. With traditional welding methods, resources are often sub-optimal and mistakes are inevitable. But what if we could find a solution that not only reduces the number of errors, but also ensures faster and more efficient work?"
 +"OrbitalChamp products offer exactly that: they reduce costs while maintaining weld quality at the highest level. This article will help you understand why you should invest in an OrbitalChamp system and how your investment will pay off over the long term", 
      Blog_ROI_Intro2: 
        "Precision, quality and cost-effectiveness are key in the world of welding. A damaged pipeline can cost billions in some industries and only annoying time in others. With the OrbitalChamp automated welding system, you not only get faster and more precise work, but also a return on your investment in a short time!",

      Blog_ROI_Intro3 : "There are few companies that deal exclusively with pipe welding. However, with Orbitalchamp, welding jobs can be diversified. So welders can relax and concentrate on other welding jobs, while pipe welding, which is a job that requires a lot of concentration, can be left to Orbitalchamp. More efficient work, fewer errors, more success for the company. However, if you are wondering how long it takes to get a return on your investment if you only want to optimise pipe welding, you should read our article",

      Blog_ROI_WelderBruttText : "Welders' gross wage : ",
      Blog_ROI_WelderBruttVal : "1 020 000 Ft",
      Blog_ROI_WelderNetVal : "(net 600 000 Ft)",

      Blog_ROI_WorkerBruttText : "Gross wage of a labourer : ",
      Blog_ROI_WorkerBruttVal : "530 000 Ft",
      Blog_ROI_WorkerNetVal : "(net 300 000 Ft)",

      Blog_ROI_OCBruttText : "OrbitalChamp price : ",
      Blog_ROI_OcBruttVal : "2 750 000 Ft",

      Blog_ROI_SmallCompany : "A) Small company",
      Blog_ROI_MediumCompany : "B) Medium",
      Blog_ROI_MediumBigCompany : "C) Medium-Small",





    Blog_ROI_Calculation_Title : "How long does it take to recoup OrbitalChamp? - Detailed Analysis of Costs and Returns",

    Blog_ROI_Calculation_Paragraph1: "5 months. That's impossible! Below we provide a detailed analysis showing how the cost of using the OrbitalChamp tool compares to traditional welding processes. Three different business levels are considered: a small business, a medium business and a medium-large business. Our aim is to show how costs can be reduced and productivity improved when using OrbitalChamp in welding operations",

    Blog_ROI_Calculation_Paragraph2: "We assume that continuous welding work is needed for the next 24 months, carried out by 1-4 welders on a permanent contract. Our aim is to speed up production, improve quality and reduce costs",

    Blog_ROI_Calculation_Paragraph3: "In addition, we do not take into account the extra work and the extra profit that could be generated by increased productivity. We only consider how costs evolve without OrbitalChamp and with OrbitalChamp",

    Blog_ROI_Case_Title: "We look at three different cases",
    Blog_ROI_Case1_Title: "First case: ",
    Blog_ROI_Case1_Text: "We do pipe welding, but we do not use a separate welding engineer",
    Blog_ROI_Case2_Title: "Second case: ",
    Blog_ROI_Case2_Text: "We would like to increase the number of welds, but the financial situation of the company does not allow us to hire more engineers (OrbitalChamp can help!)",
    Blog_ROI_Case3_Title: "Third case: ",
    Blog_ROI_Case3_Text: "We want to achieve significant expansion, but in the most cost-effective way possible",

    Blog_ROI_Calculation_Paragraph4: "In our study, we look at how the overall cost rate changes over time.",

    Blog_ROI_Calculation_Title2 : "Cost calculations:",
    Blog_ROI_Calculation_Paragraph5 : "The following table shows the costs compared to conventional welding using OrbitalChamp:",
   
    Blog_ROI_NumberOfWelders : "Number of welders",
    Blog_ROI_Cost_WO_OC : "Cost without OrbitalChamp over 24 months",
    Blog_ROI_Cost_W_OC : "Cost with OrbitalChamp over 24 months",
    Blog_ROI_Diff_In_24_Months : "Difference after 24 months",

Blog_ROI_Scenario1_Title: "1 welder vs. 1 trained worker + 1 OrbitalChamp device",
Blog_ROI_Scenario2_Title: "2 welders vs. 1 welder + 1 trained worker + 2 OrbitalChamp devices",
Blog_ROI_Scenario3_Title: "4 welders vs. 1 welder + 1 trained worker + 3 OrbitalChamp devices",

Blog_ROI_Cost_In_Ft_Scen1_WO_OC : "~ 25 million Ft",
Blog_ROI_Cost_In_Ft_Scen1_W_OC : "~ 15 million Ft",
Blog_ROI_Cost_In_Ft_Scen1_Diff : "~ 10 million Ft savings",

Blog_ROI_Cost_In_Ft_Scen2_WO_OC : "~ 50 million Ft",
Blog_ROI_Cost_In_Ft_Scen2_W_OC : "~ 30 million Ft",
Blog_ROI_Cost_In_Ft_Scen2_Diff : "~ 20 million Ft savings",

Blog_ROI_Cost_In_Ft_Scen3_WO_OC : "~ 100 million Ft",
Blog_ROI_Cost_In_Ft_Scen3_W_OC : "~ 70 million Ft",
Blog_ROI_Cost_In_Ft_Scen3_Diff : "~ 30 million Ft savings",

Blog_ROI_Summary : "The amount of savings is impressive, but even more attractive is the fact that, according to calculations, the investment pays off in the 5th to 6th month. From this point on, costs continuously decrease with the use of OrbitalChamp, leading to long-term savings.",

Blog_ROI_Outro_Title : "Why is it worth investing?",
Blog_ROI_Outro1 : "With OrbitalChamp, not only can costs be reduced, but the quality and speed of welding processes can also be improved. It enables greater flexibility in welding and helps avoid faulty welds. The long-term use of these devices results in significant savings for future welding projects.",  
Blog_ROI_Outro2 : "If you want to see more detailed calculations, click the button on the left. If you are curious about how OrbitalChamp works, check out the button on the right!",  

Blog_ROI_Megterules_calc : "Return on Investment Calculation",
Blog_ROI_Bemutato : "Sign up for a demo",

ROI_Title: "Return on Investment Calculation",  
ROI_Intro: "In this study, we examine the economic impacts of the OrbitalChamp system in three different company sizes, considering investment and operational costs, as well as employee wage structures. The aim of the analysis is to quantify cost reduction and payback period.",  
ROI_Title_Base_Data: "Basic Data and Assumptions",  
ROI_Base_Data_Par1: "All mathematical calculations are based on providing assumptions and frameworks. In this case, the basic assumption is that continuous welding work is required over the next 24 months, and the company employs 1-4 professionals on a permanent contract. The additional workload that can be undertaken thanks to the use of the machine is omitted from this calculation.",  
ROI_Base_Data_Par2: "Data required for calculations: The average wages of a welder and an unskilled worker, as well as their gross costs. These are:",  
ROI_Variable_Names: "Names of other important variables:",  
ROI_WelderWageText: "Gross wage of welders",  
ROI_WelderCntText: "Number of welders",  
ROI_WelderCntTextVesszo: "Changed number of welders",  
ROI_WorkerWageText: "Gross wage of unskilled workers",  
ROI_WorkerCntText: "Number of unskilled workers",  
ROI_OrbitalCntText: "Number of OrbitalChamp machines",  
ROI_CostVariableWeld: "Cost with employing welders",  
ROI_CostVariableOrbi: "Cost with employing welders and OrbitalChamp",  
ROI_DiffVarName: "Difference in costs",  
ROI_MonthsCnt: "Number of months",  
ROI_NullLine: "Break-even line",  
ROI_Machine_Price: "Price of machines",  
ROI_Intro2: "In our analysis, we examine three different corporate structures:",  
ROI_CostTitle: "The Cost Analysis Model",  
ROI_Cost_Par1: "The formulas used for our calculations are always the same, which are as follows:",  
ROI_Cost_Formula1: "Total labor cost in the case without automation:",  
ROI_Cost_Formula2: "Cost of operation with the OrbitalChamp system:",  
ROI_Cost_Formula3: "Difference between the two costs:",  
ROI_Searched_Point: "The sought point:",  
ROI_Cost_Par2: "As this value approaches zero, the break-even point is found. This indicates the time when the total costs are the same in both variations. So at this point, it doesn't matter whether we have an OrbitalChamp welding machine or employ welders. However, above this point, the difference becomes significant.",  
ROI_Cost_Par3: "We share these formulas with the dear reader so that they can verify the calculations with any input or with the data provided here. However, there will also be an interactive chart creator on the site, where anyone can enter anything and test which solution is most effective for them.",  
ROI_Results: "Results",  
ROI_Result1_Title: "1. In the case of a small business",  
ROI_Result1_Text: "By the end of the 24-month period, the costs associated with employing only welding engineers reach 25 million forints, while the cost of operating with the OrbitalChamp system will be 15 million forints. The payback period is 5-6 months, while the total investment cost of the system is recovered in 11 months.",  
ROI_Result2_Title: "2. In the case of a medium-sized business",  
ROI_Result2_Text: "A similar trend can be observed, with the payback occurring at 5-6 months, while by the end of the 24th month, the cost difference reaches around 20 million forints. The investment pays off in 10-11 months.",  
ROI_Result3_Title: "3. In the case of a medium-large business",  
ROI_Result3_Text: "Although the initial investment is higher, due to the larger production capacity, by the end of the 24th month, the cost difference increases to 30 million forints. The break-even point is also found at 5-6 months, while the total investment is covered within 11 months.",  
ROI_Conclusion: "Conclusion",  
ROI_Outro1: "The analysis clearly shows that the application of the OrbitalChamp automated welding system results in significant cost savings compared to traditional manual welding. The payback period is short, typically between 5-6 months, while the total investment cost is recovered within 10-11 months. This has been proven with 3 similar but different cases.",  
ROI_Outro2: "With the introduction of automated welding technology, not only can costs be reduced, but productivity and welding quality also increase, providing a competitive advantage for companies in the long run. Based on the results, it can be said that the application of the system is not only recommended from an economic point of view but also brings significant benefits in terms of efficiency and quality. We will write about this in a later article.",  
ROI_Outro3: "If you are interested in the product, please contact us on the following page:",  
ROI_SporoltOszzeg: "Amount saved at the end of the 24th month:",  
ROI_Osszkoltseg_Hegeszto: "Total cost without OrbitalChamp at the end of the 24th month:",  
ROI_Osszkoltseg_Orbitalchamp: "Total cost using OrbitalChamp at the end of the 24th month:",  
ROI_Money: "Million HUF",  

CostCalculator: "Cost Calculation" 

  },
  de: {
    Navbar_Product_title: 'Produkt',
    Navbar_Application_title: 'Schweißmaschinen',
    Navbar_Weldings_title: 'Unsere Schweißnähte',
    Navbar_Contact_title: 'Kontakt',
    Navbar_OrbitalWelding_title: 'Orbitales Schweißen',
    Welcome_Button: 'Kontaktieren Sie uns!',

      ContactApplyTitle: "Melden Sie sich an!",
      Contact_TestDrive: "Wählen Sie ein Datum!",
      Contact_AtYou: 'Bei Ihnen',
      Contact_AtUs: 'Bei uns',
      Contact_AtYouPlace: 'Ihre Adresse',
      Contact_ChooseDate: 'Wählen Sie ein Datum',
     Contact_Info: 'Nachdem Sie auf die Schaltfläche "Senden" geklickt haben, werden wir Sie kontaktieren, um Ihnen mitzuteilen, wann die Demo stattfinden wird. Wir fragen Sie nach Ihrer Adresse, damit wir die logistischen Details ausarbeiten können',
      Contact_Info2: 'Wenn Sie wissen, wann Sie zu uns kommen können, wählen Sie bitte eine Zeit aus',
 
    Change: "Wechseln",

    Footer_Location: 'Ort',

    About_Head_title: 'OrbitalChamp',
    About_Head_desc: 'Der OrbitalChamp ist ein Gerät, das Röntgensichere, qualitativ hochwertige und präzise Schweißnähte auch von Mitarbeitern ohne Schweißqualifikation erzeugen kann',
    About_High_Quality_Weldings_title: 'Zertifiziertes Schweißen',
    About_High_Quality_Weldings_desc: 'Mit dem OrbitalChamp können Schweißnähte in hoher Qualität durchgeführt werden. Dies wird durch unsere TÜV-Zertifizierung bestätigt.',
    About_Track_Setting_title: 'Schweißpfad-Positionierung',
    About_Track_Setting_desc: 'Sie haben die Möglichkeit, den Schweißpfad während des Schweißprozesses zu steuern.',
    About_Electronic_title: 'Elektronische Steuerung',
    About_Electronic_desc: 'Ein elektronischer Controller steuert den Schweißprozess vollständig automatisch. Dieses Gerät ist mit einer mobilen App programmierbar.',
    About_UI_title: 'Fernsteuerung',
    About_UI_desc: 'Der OrbitalChamp ist ein ferngesteuertes Gerät mit drei Tasten, einem Schalter und drei LEDs. Die Tasten werden verwendet, um den Kopf zu positionieren und den Schweißprozess zu starten. Der Schalter steuert die Ein-/Ausschaltfunktion der Fernbedienung. Die LEDs zeigen den Start und das Ende des Schweißprozesses sowie die Bluetooth-Verbindung an.',
    About_Your_Welder_title: 'Verbinden Sie Ihre eigene Maschine!',
    About_Your_Welder_desc: 'Eine der größten Stärken des OrbitalChamp ist seine Kompatibilität mit allen Arten von WIG-Schweißgeräten. Der Schweißer kann die ihm vertraute Schweißmaschine verwenden.',
    About_All_Pipe_title: 'Passend für alle Rohrdurchmesser',
    About_All_Pipe_desc: 'Unser Produkt ist mit zwei Schweißköpfen erhältlich. Einer ermöglicht das Schweißen von DIN20 bis DIN50, während der andere das Schweißen von DIN50 bis DIN100 ermöglicht. Innerhalb des Größenbereichs können verschiedene Einsätze für verschiedene Rohre verwendet werden.',
    About_Next: 'Weiter',
    About_Back: 'Zurück',

    Webshop: 'Webshop',

    WeldingMachines_Title: 'Mit welcher Schweißmaschine möchten Sie verbinden?',
    WeldingMachines_Button: 'Weitere WIG-Schweißmaschinen',

    WeldingMachines_mobile_Title: 'Schweißmaschinen',
    WeldingMachines_mobile_Button: 'Sehen Sie das Video!',

    WeldingsAndForm_Title: 'Unsere orbitalen Schweißnähte',
    WeldingsAndForm_Form_Title: 'Testen Sie unseren orbitalen Schweißer kostenlos!',
    WeldingsAndForm_Form_Address: 'Adresse: 1224 XVI. Straße 9/a',
    WeldingsAndForm_Form_Button: 'Kontakt registrieren!',
    WeldingsAndForm_Form_Button_Offer: 'Angebot anfordern!',
    WeldingsAndForm_Form_Button_Demo: 'Für Demo registrieren!',

    OrbitalWeldings_Title: 'Orbitales Schweißen',
    OrbitalWeldings_P1: 'Das Orbitalschweißen ist eine fortschrittliche Schweißtechnologie, die hauptsächlich für das präzise Schweißen von Rohren und zylindrischen Strukturen verwendet wird. Der Prozess ermöglicht die Realisierung präziser und hochwertiger Nähte, was insbesondere in Branchen wie der Pharmaindustrie, der Lebensmittelverarbeitung sowie der Öl- und Gasindustrie von entscheidender Bedeutung ist. Dort sind eine einwandfreie Schweißqualität sowie ein hohes Maß an Hygiene unabdingbar.', 
    OrbitalWeldings_P2: 'Die wesentlichen Vorteile des Orbitalschweißens sind in der exzellenten Schweißqualität sowie der hohen Prozesswiederholbarkeit zu sehen. Das automatisierte System gewährleistet, dass jede Schweißnaht den gleichen hohen Qualitätsstandard aufweist, wodurch Fehlerquellen minimiert und nachträgliche Inspektionen reduziert werden. Des Weiteren zeichnet sich das Orbitalschweißen durch eine höhere Prozessgeschwindigkeit aus, wodurch sich die Betriebszeit verkürzt und somit Kosten eingespart werden können.',
    OrbitalWeldings_P3: 'Die Investition in ein Orbitalschweißsystem kann sich langfristig auszahlen, da es die Arbeitskosten senkt, die Produktionseffizienz verbessert und die Produktqualität steigert. Derartige Systeme erweisen sich insbesondere für Vorhaben als vorteilhaft, die eine Vielzahl an Rohrschweißungen umfassen und bei denen die Qualität der Ergebnisse von höchster Relevanz ist. Daher bietet das Orbitalschweißen nicht nur einen technologischen Vorteil, sondern auch einen Wettbewerbsvorteil für diejenigen, die sich der exzellenten Qualität verschrieben haben.',

    Application_title: 'Schweißmaschinen',
    Application_Settings_title: 'Einstellungen',
    Application_Settings_Start_Welder_title: 'Schweißimpuls starten',
    Application_Settings_Start_Welder_desc: 'Setzen Sie den Schweißstartimpuls immer 0,5 s länger als die Gasvordosierung.',
    Application_Settings_Delay_title: 'Startverzögerung',
    Application_Settings_Delay_desc: 'Stellen Sie die Verzögerung ein, bis das Material die richtige Temperatur erreicht hat.',
    Application_Settings_Rotation_Speed_title: 'Rotationsgeschwindigkeit',
    Application_Settings_Rotation_Speed_desc: 'Wir können den Durchmesser des Rohres in 4 Teile unterteilen. In jedem Teil kann eine andere Geschwindigkeit eingestellt werden.',
    Application_Settings_OverRun_title: 'Überlauf',
    Application_Settings_OverRun_desc: 'Hier können wir einstellen, wo das Schweißen nach 360 Grad endet.',
    Application_Settings_Stop_Impulse_title: 'Stoppimpuls',
    Application_Settings_Stop_Impulse_desc: 'Der Stoppimpuls definiert den Punkt des Lichtbogenausfalls.',
    Application_Settings_Stop_Welder_Impulse_title: 'Schweißimpuls stoppen',
    Application_Settings_Stop_Welder_Impulse_desc: 'Der Schweißstoppimpuls definiert den Zeitpunkt des Lichtbogenausfalls.',
    Application_Settings_Rotation_Direction_title: 'Drehrichtung',
    Application_Settings_Rotation_Direction_desc: 'Dies definiert die Drehrichtung.',
    Application_Settings_Meta_title: 'Getriebeübersetzung',
    Application_Settings_Meta_desc: 'Unterschiedliche Kopfdurchmesser gehören zu unterschiedlichen Getriebeübersetzungen, und dies kann hier eingestellt werden.',
    Application_Get_More_Information: 'Mehr erfahren!',
    Application_Inquery_title: 'Anfrage',
    Application_Inquery_desc: 'Es gibt eine Vollbildanzeige, auf der wir anfragen können, welche Parameter auf das Gerät hochgeladen wurden.',
    Application_Save_BT_title: 'Speichern',
    Application_Save_title: 'Speicherbare Schweißparameter!',
    Application_Save_desc: 'Die Anwendung bietet die Möglichkeit, die Schweißparameter zu speichern und jederzeit wieder aufzurufen und auf ein oder mehrere Geräte hochzuladen. Eine sehr nützliche Stärke unseres Geräts ergibt sich daraus. Ein nützliches Beispiel: Der Schweißingenieur programmiert das Gerät in der Werkstatt, um eine qualitativ hochwertige und röntgensichere Schweißnaht zu erzielen. Er lädt dies auf ein oder mehrere Geräte hoch und speichert es in der Anwendung, bevor er die intelligente Schweißbox dem halbqualifizierten Arbeiter übergibt, der den Orbitalschweißkopf an das Rohr anbringt. Dies wird ein ebenso qualitativ hochwertiges Schweißergebnis liefern, wie der Schweißer es selbst in der Werkstatt erzielen könnte. Dieser Prozess ist jederzeit und überall wiederholbar.',

    Application_Or: 'oder',

    Shop_Cancel: 'Abbrechen',


    Weldings_title: 'Unsere Schweißnähte',
    Weldings_seams: 'Nähte',
    Weldings_root: 'Wurzel',
    Weldings_factory: 'Produktion',

    Contact_title: 'Kontakt',
    Contact_name: 'Name',
    Contact_email: 'E-Mail',
    Contact_Company_name: 'Firmenname',
    Contact_Phone: 'Telefon',
    Contact_message: 'Nachricht',
    Conatct_message_desc: 'Schreiben Sie hier die Nachricht!',
    Conatct_Send: 'Senden',

    Shop_SmartWeldingSystemTitle: 'OrbitalChamp Schweißsystem',
    Shop_WelderheadTitleDIN50: 'OrbitalChamp Schweißkopf DIN20-DIN50',
    Shop_WelderheadTitleDIN100: 'OrbitalChamp Schweißkopf DIN50-DIN100',
    Shop_SmartBoxTitle: 'OrbitalChamp Smartbox',
    Shop_BackToMainPage: 'Zurück zur Hauptseite',
    Shop_OurProducts: 'Unsere Produkte',
    Shop_OurProduct: 'Unser Produkt',
    Shop_Price: 'Preis',
    VAT: 'MwSt.',
    Shop_Item_SmartWelding_Info: 'Das OrbitalChamp Schweißsystem kann auch als Komplettset erworben werden. Dieses Paket umfasst die Smartbox und eine Wahl des orbitalen Jets.',
    Shop_Item_Contains: 'Inhalt:',
    Shop_Item_OrbitalWelder: 'Orbitalschweißkopf',
    Shop_Item_Inlay: '4 Stk. Einsätze',
    Shop_Item_Welding_Connector: 'Schweißanschluss',
    Shop_Item_WorkCable: 'Arbeitskabel',
    Shop_Item_WelderHead: 'Schweißkopf',
    Shop_Item_Back : 'Zurück',

    Shop_Item_Offer : "Angebotsanfrage",
    Shop_Item_Compatible_Devices : "Kompatible Geräte",
    Shop_Item_Orbital_Products : "Inhalt der Verpackung",
    Shop_Item_Compatible_Devices_Info : "Wählen Sie eines aus, damit wir wissen, für welche Maschine Sie es verwenden möchten",
    Shop_Item_Download_UM : "Laden Sie unser Benutzerhandbuch herunter",
    Shop_Item_Download_Text : "Herunterladen",
    Shop_Item_Download_Android : "Herunterladen für Android",
    Shop_Item_Download_IOS : "Herunterladen für iOS",

    Shop_Item_Your_Email : "Deine E-mail Addresse",
    Shop_Item_Know_More : "Erfahren Sie mehr über unser Produkt",
    Shop_Item_Sizes : "Größenbereiche",
    Shop_Item_Pro : "Vorteile",
    Shop_Item_Successful_Sending : "Die Nachricht wurde erfolgreich gesendet!", 
    Shop_Item_Unsuccessful_Sending : "Beim Senden der Nachricht ist ein Fehler aufgetreten!",
    Shop_Item_Bad_Email : "Die E-Mail Adresse ist nicht korrekt",
    Shop_Item_Bad_Number : "Die Telefonnummer ist nicht korrekt",

      Shop_Item_SWS_Vorteil1 : "Kompatibel mit jedem WIG-Schweißgerät",
      Shop_Item_SWS_Vorteil2 : "Schweißen mit perfekter Präzision und Qualität dank der festen Lichtbogenunterstützung",
      Shop_Item_SWS_Vorteil3 : "Dank der speicherbaren Parameter kann das Schweißen jederzeit wiederholt werden",
      Shop_Item_SWS_Vorteil4 : "Stellen Sie die perfekte Schweißnaht mit Ihrem Handy ein!",
      Shop_Item_SWS_Vorteil5 : "Kann durch die Einsätze auf jede Rohrgröße angewendet werden",
      Shop_Item_SWS_Vorteil6 : "Erschwinglicher Preis, hohe Qualität, Zuverlässigkeit",

      Shop_Item_IndexRemote: "Fernsteuerung",
      Shop_Item_IndexOrbitalWelderHead: "Orbitaler Schweißkopf",
      Shop_Item_IndexSmartBox : "SmartBox (Elektronik)",
      Shop_Item_IndexKonzol : "Konsole",
      Shop_Item_IndexWeldConnector : "Schweißanschluss",
      Shop_Item_IndexBetet : "Einsatz",
      Shop_Item_IndexMotorCable : "Motorkabel 3 [m] oder 3+ [m]",
      Shop_Item_IndexWelderHeadEnd : "Schweißkopf",
      Shop_Item_IndexPowerSupply : "12V-Stromversorgung",
      Shop_Item_IndexArbeitKabel : "Arbeitskabel 3 [m] oder 3+ [m]",

      Shop_Item_SizeOfWelderHead : 'Abmessungen des Schweißkopfes:', 
      Shop_Item_Size_WidthDIN50 : 'Breite: 185 [mm]',
      Shop_Item_Size_LengthDIN50 : 'Länge: 480 [mm]',
      Shop_Item_Size_HeightDIN50 : 'Höhe: 155 [mm]',
      Shop_Item_Size_WidthDIN100 : 'Breite: 245 [mm]',
      Shop_Item_Size_LengthDIN100 : 'Länge: 480 [mm]',
      Shop_Item_Size_HeightDIN100 : 'Höhe: 155 [mm]',
      Shop_Item_Size_WidthSmart : 'Breite: 120 [mm]',
      Shop_Item_Size_LengthSmart : 'Länge: 240 [mm]',
      Shop_Item_Size_HeightSmart : 'Höhe: 40 [mm]',
            Shop_Item_Size_Smartbox : 'Größe der Smartbox',
      Shop_Item_Size_Cable : 'Abmessungen der Kabel',
      Shop_Item_CurrectCable : 'Arbeitskabel: 3 [m] (erweiterbar)',
      Shop_Item_MotorCable : 'Motorkabel: 3 [m] (erweiterbar)',
      Shop_Item_WeldingRangeDIN50 : 'Hat einen Greifbereich von 20 [mm] - 60.3 [mm] ',
      Shop_Item_WeldingRangeDIN100 : 'Hat einen Greifbereich von 60.3 [mm] - 114.3 [mm] ',

      Shop_Item_SmartBoxInfo : "Die OrbitalChamp SmartBox ist ein automatisiertes Schweißsteuerungssystem, das für präzise und zuverlässige Schweißprozesse sorgt. Einfache Integration in bestehende Systeme mit Smartphone-Steuerung",
      Shop_Item_DIN50Info : "Schweißkopf für Rohre mit einem Durchmesser von 20 mm bis 60,3 mm, der präzises und zuverlässiges Schweißen in industriellen Umgebungen ermöglicht. Einfach zu integrieren mit der OrbitalChamp SmartBox.",
      Shop_Item_DIN100Info : "Schweißkopf geeignet für Rohre mit einem Durchmesser von 60,3 mm bis 114,3 mm, für präzises und zuverlässiges Schweißen in einer industriellen Umgebung. Einfach auf die OrbitalChamp SmartBox zu montieren.",

    Blog_Author: "Verfasser",







  Blog_TitulusMecha: "Zertifizierter Mechatronik-Ingenieur",
  Blog_Illustracio: "Bild für Veranschaulichung",

  Blog_Top3_Title_Meta: "Die Geschichte des Orbitalschweißens!",
  Blog_Top3_Description_Meta: "Das Orbitalschweißen ist eine spezialisierte, automatisierte Schweißtechnologie, die hauptsächlich für das Verbinden von Rohren und Rohrleitungen verwendet wird. Die Entwicklung dieser Technologie reicht bis in die 1960er Jahre zurück, als der Aufstieg der Raumfahrt und der Kernenergieindustrie neue, zuverlässige Schweißmethoden erforderte.",
  Blog_Top3_Title: "Top 3 Gründe, warum du ORBITAL-SCHWEIßEN nutzen solltest!",
  Blog_Top3_Paragraph1: "In den letzten Jahren hat die Orbitalschweißtechnik in der Schweißindustrie erheblich an Bedeutung gewonnen. Aber warum Orbitalschweißen anstelle traditioneller Methoden wählen? Hier sind drei überzeugende Gründe, warum moderne Technologie die beste Wahl ist:",
  Blog_Top3_T1_Strong: "Hervorragende Präzision und Wiederholbarkeit:",
  Blog_Top3_T1_Text: "Der größte Vorteil des Orbitalschweißens ist die unglaubliche Präzision und Wiederholbarkeit. Die Schweißmaschine kann automatisch eingestellt werden, um sicherzustellen, dass die Schweißnaht in jedem Abschnitt gleichmäßig und konsistent ist. Dies ist besonders wichtig in Branchen, in denen die Schweißqualität die Produktsicherheit und Lebensdauer direkt beeinflusst, wie beispielsweise in der Pharma- oder Lebensmittelindustrie.",
  Blog_Top3_T2_Strong: "Reduzierte Arbeitszeit und erhöhte Effizienz:",
  Blog_Top3_T2_Text: "Die Geschwindigkeit und Effizienz von Orbitalschweißern kann die für die Arbeit erforderliche Zeit drastisch verkürzen. Da die Maschinen teilweise automatisiert sind, wird weniger Zeit und Ressourcen für die Schulung der Prozesse benötigt. Zudem ermöglicht die verkürzte Schweißzeit eine schnellere Fertigstellung der Projekte, was die Produktivität steigert und die Industrieprozesse optimiert.",
  Blog_Top3_T3_Strong: "Erhöhte Arbeitssicherheit:",
  Blog_Top3_T3_Text: "Die Automatisierung des Schweißprozesses macht ihn nicht nur effizienter, sondern auch sicherer. Orbitalschweißer minimieren die Fehler und Unfälle, die mit manuellen Schweißverfahren verbunden sind, da der Bediener während des Prozesses viel weniger physisch eingreifen muss. Dies schafft bessere Gesundheitsbedingungen für die Benutzer und reduziert potenzielle Gesundheitsrisiken.",
  Blog_Top3_P2: "Wenn du nach einer innovativen Technologie suchst, die die Schweißindustrie revolutionieren kann, mit einem Fokus auf Präzision, Effizienz und Sicherheit, ist das Orbitalschweißen die beste Wahl. Die geringere Fehlerquote und die schnellere Ausführung sparen nicht nur Zeit, sondern verbessern auch die Qualität des Endprodukte."

,  Blog_History_Title: "Die Geschichte des Orbital-Schweißens",
  Blog_History_Paragraph1: 
    "Das Orbital-Schweißen ist eine spezielle, automatisierte Schweißtechnologie, die hauptsächlich zum Verbinden von Rohren und Rohrleitungen eingesetzt wird. Die Technologie entstand in den 1960er Jahren, als die Raumforschung und die Kernenergieindustrie neue, zuverlässige Schweißmethoden forderten.",
  
  Blog_History_TheBeginnings_Title: "Die Anfänge",
  Blog_History_TheBeginnings_Text: 
    "Die Geburt des Orbital-Schweißens ist eng mit dem amerikanischen Raumprogramm verbunden. Für die NASA war es von entscheidender Bedeutung, dass das Schweißen von Rohren, die in Raketen und Raumfahrzeugen verwendet werden, perfekt ist, da selbst der kleinste Fehler katastrophale Folgen haben könnte. Die ersten Orbital-Schweißgeräte wurden von North American Aviation entwickelt, speziell für das präzise Schweißen von dünnwandigen Edelstahlrohren.",
  
  Toast_Error_Email: 'Feheler beim Email Sendung',


  Blog_History_TwoTypes: "Im Verlauf der Entwicklung entstanden zwei Haupttypen von Schweißköpfen:",
  
  Blog_History_Technology_Title: "Technologischer Fortschritt",
  Blog_History_Technology_Text: 
    "In den 1970er und 1980er Jahren entwickelte sich die Technologie weiter und breitete sich auch auf andere Industrien wie die Chemie-, Pharma- und Lebensmittelindustrie aus. In diesen Sektoren sind Sauberkeit und Schweißnahtqualität von größter Bedeutung, wodurch das Orbital-Schweißen die ideale Lösung bietet.",
  
  Blog_History_Heads_Title: "Offene und Geschlossene Schweißköpfe",
  Blog_History_OpenHeads: "Offene Schweißköpfe:",
  Blog_History_OpenHeads_Text: 
    "Diese ermöglichen eine direkte Beobachtung des Schweißprozesses und sind flexibler für verschiedene Rohrgrößen einsetzbar.",
  Blog_History_ClosedHeads: "Geschlossene Schweißköpfe:",
  Blog_History_ClosedHeads_Text: 
    "Der Schweißbereich befindet sich in einer geschlossenen Kammer, in der ein inertes Gas die Naht vor Oxidation schützt, wodurch hochwertige Schweißverbindungen erzielt werden.",
  
  Blog_History_Modern_Title: "Moderne Entwicklungen und OrbitalChamp",
  Blog_History_Modern_Text: 
    "Heute ist das Orbital-Schweißen weit verbreitet und entwickelt sich ständig weiter. Auch in Ungarn gab es erhebliche Fortschritte auf diesem Gebiet. OrbitalChamp ist ein ungarisch entwickeltes Orbital-Schweißgerät, das sich dadurch auszeichnet, dass es mit den meisten vorhandenen Schweißmaschinen kompatibel ist. Dies ermöglicht es Unternehmen, die Vorteile des Orbital-Schweißens zu nutzen, ohne ein völlig neues System anschaffen zu müssen.",
  
  Blog_History_Importance_Title: "Die Bedeutung des Orbital-Schweißens",
  Blog_History_Importance_Text: 
    "Die Geschichte des Orbital-Schweißens ist das Ergebnis des Zusammentreffens von technologischer Innovation und industrieller Nachfrage. Zu den Vorteilen dieser Methode gehören die Herstellung hochwertiger, wiederholbarer Schweißnähte, die Reduzierung der Arbeitszeit und die Steigerung der Produktivität. Diese Faktoren machen die Technologie in vielen Branchen unverzichtbar.",
  
  Blog_History_Summary_Title: "Zusammenfassung",
  Blog_History_Summary_Text: 
    "In den letzten Jahrzehnten hat das Orbital-Schweißen erhebliche Fortschritte gemacht und spielt weiterhin eine Schlüsselrolle in modernen Industrieprozessen. Kontinuierliche Innovation und inländische Entwicklungen wie OrbitalChamp stellen sicher, dass diese Technologie auch in Zukunft entscheidend bleibt.",
  
  Blog_Author_Name: "Tibor Horváth",
  Blog_Author_Title: "CEO von OrbitChamp",
  
  Blog_Valuable_Title: "Warum du als Schweißer immer wertvoller wirst",
  Blog_Valuable_Paragraph1: 
    "In den letzten Jahren hat die Nachfrage nach Schweißern erheblich zugenommen, und dieser Trend wird voraussichtlich auch in Zukunft anhalten. Aber warum wirst du als Schweißer immer wertvoller, und wie kannst du die Möglichkeiten in deinem Beruf nutzen? In diesem Beitrag untersuchen wir die Faktoren, die dazu beitragen, dass Schweißer immer mehr geschätzt werden.",
  
  Blog_Valuable_WorkerShortage_Title: "Wachsende Fachkräftemangel",
  Blog_Valuable_WorkerShortage_Text: 
    "Eine der größten Herausforderungen in der Schweißindustrie ist derzeit der Mangel an Fachkräften. Viele erfahrene Schweißer nähern sich der Rente, während der Nachwuchs nicht mit dem Verlust an Arbeitskräften Schritt halten kann. In der Europäischen Union wächst die Nachfrage nach Schweißern kontinuierlich, und laut dem Bericht des Europäischen Beschäftigungsdienstes gehört der Mangel an Schweißfachkräften zu den höchsten in der EU. Dieser Mangel erhöht den Wert der bestehenden Schweißer auf dem Arbeitsmarkt.",
  
  Blog_Valuable_Infrastructure_Title: "Infrastrukturentwicklungen und industrielles Wachstum",
  Blog_Valuable_Infrastructure_Text: 
    "Die Zahl der globalen Infrastrukturentwicklungsprojekte steigt, sei es im Bauwesen, bei Energieinvestitionen oder im Verkehrsnetz. All diese Projekte benötigen Schweißer, da die erforderlichen Strukturen und Systeme ohne sie nicht realisiert werden können. Das industrielle Wachstum schafft weitere Arbeitsmöglichkeiten für Schweißer und erhöht den Wert des Berufs.",
  
  Blog_Valuable_Technology_Title: "Technologischer Fortschritt und Spezialisierung",
  Blog_Valuable_Technology_Text: 
    "Das Auftreten neuer Technologien wie Orbital-Schweißen, Laser-Schweißen und anderer fortschrittlicher Schweißtechniken erfordert Fachleute, die in der Lage sind, sich anzupassen und diese Innovationen zu beherrschen. Schweißer, die mit dem technologischen Fortschritt Schritt halten, werden auf dem Arbeitsmarkt besonders wertvoll sein.",
  
  Blog_Valuable_Automation_Title: "Automatisierung und menschliche Expertise",
  Blog_Valuable_Automation_Text: 
    "Obwohl die Automatisierung eine zunehmende Rolle in der Industrie spielt, bleibt das Fachwissen der Schweißer unverzichtbar. Die Überwachung, Einrichtung und Wartung automatisierter Systeme sind Aufgaben, die erfahrene Schweißer erfordern. Menschliche Kreativität und Problemlösungsfähigkeiten sind unersetzlich, insbesondere bei komplexen Projekten.",
  
  Blog_Valuable_Salary_Title: "Wettbewerbsfähige Gehälter und Karrieremöglichkeiten",
  Blog_Valuable_Salary_Text: 
    "Aufgrund der wachsenden Nachfrage und des Fachkräftemangels steigen auch die Gehälter der Schweißer. Darüber hinaus eröffnen sich zahlreiche Möglichkeiten für berufliches Fortkommen, sei es durch Spezialisierung oder das Übernehmen von Führungspositionen. Kontinuierliches Lernen und Entwicklung ermöglichen es dir, für Arbeitgeber noch wertvoller zu werden.",
  
  Blog_Valuable_Summary_Title: "Zusammenfassung",
  Blog_Valuable_Summary_Text: 
    "Die Zukunft des Schweißberufs ist vielversprechend. Der Fachkräftemangel, das industrielle Wachstum und der technologische Fortschritt tragen alle dazu bei, dass Schweißer immer wertvoller werden. Es ist sinnvoll, diese Chancen zu nutzen, dein Wissen ständig zu erweitern und dich an neue Technologien anzupassen. So kannst du nicht nur deinen Platz auf dem Arbeitsmarkt sichern, sondern auch einen erheblichen Beitrag zur Entwicklung der Industrie leisten.",
  
  Blog_Valuable_Author_Name: "Benedegúz Horváth",
  Blog_Valuable_Author_Title: "CEO von OrbitChamp",
  
  Blog_Hungarian_Title: "Der ungarische Orbital-Schweißer: OrbitalChamp",
  Blog_Hungarian_Paragraph1:
    "Die kontinuierliche Weiterentwicklung der industriellen Technologie hat zahlreiche Innovationen im Schweißverfahren hervorgebracht. Eine bedeutende Entwicklung ist das Orbital-Schweißen, das eine besonders wichtige Rolle beim Schweißen von Rohrleitungen und Rohren spielt. Ungarn kann mit einer solchen Innovation aufwarten: dem OrbitalChamp, einem orbitalen Schweißgerät.",
  
  Blog_Hungarian_WhatIs_Title: "Was ist Orbital-Schweißen?",
  Blog_Hungarian_WhatIs_Text:
    "Das Orbital-Schweißen ist eine automatisierte Schweißtechnologie, bei der der Schweißkopf um ein festes Rohr rotiert. Diese Methode gewährleistet gleichmäßige und qualitativ hochwertige Schweißnähte, die in Branchen wie Pharmazie, Lebensmittel und Öl und Gas unerlässlich sind. Orbital-Schweißen minimiert menschliche Fehler und erhöht die Produktivität.",
  
  Blog_Hungarian_Advantages_Title: "Vorteile von OrbitalChamp",
  Blog_Hungarian_Advantages_Text:
    "Der OrbitalChamp ist ein ungarisch entwickelter Orbital-Schweißer, der sich aus mehreren Gründen auf dem Markt hervorhebt:",
    Blog_Hungarian_Advantage_Compatibility : "Kompatibilität:",
  Blog_Hungarian_Advantage_Compatibility_Text: " Die Einzigartigkeit von OrbitalChamp besteht darin, dass er mit den meisten vorhandenen Schweißmaschinen kompatibel ist. Das bedeutet, dass es nicht notwendig ist, völlig neue Geräte zu kaufen; der OrbitalChamp kann problemlos in bestehende Werkzeuge integriert werden.",
  Blog_Hungarian_Advantage_Cost: "Kosten-Effizienz:",
  Blog_Hungarian_Advantage_Cost_Text: " Da keine neuen Schweißmaschinen gekauft werden müssen, können erhebliche Kosteneinsparungen erzielt werden. Dies ist besonders vorteilhaft für kleinere Unternehmen.",
    Blog_Hungarian_Advantage_Innovation: "Innovation:",
  Blog_Hungarian_Advantage_Innovation_Text: " Bei der Entwicklung des OrbitalChamp wurde großer Wert auf Technologien wie die alternierende Drahtzufuhr gelegt, die die Schweißnahtqualität weiter verbessert und die Wärmeaufnahme reduziert.",
  
  Blog_Hungarian_Impact_Title: "Einfluss von OrbitalChamp auf die Schweißindustrie",
  Blog_Hungarian_Impact_Text:
    "OrbitalChamp ist nicht nur ein neues Gerät, sondern eine Lösung, die zur Weiterentwicklung der Schweißindustrie beiträgt. Es ermöglicht den Schweißern, die Vorteile des Orbital-Schweißens zu nutzen, ohne vollständig neue Systeme erlernen und verwenden zu müssen. Dies fördert schnelleres und effizienteres Arbeiten und erhält gleichzeitig hohe Qualitätsstandards.",
  
  Blog_Hungarian_International_Title: "Ungarische Innovation auf internationaler Ebene",
  Blog_Hungarian_International_Text:
    "OrbitalChamp hat sich sowohl im Inland als auch im Ausland bereits bewährt. Das Gerät wird erfolgreich in verschiedenen Branchen eingesetzt und hat positives Feedback von den Benutzern erhalten. Diese ungarische Innovation trägt dazu bei, dass unser Land auch im Bereich der Schweißtechnologien bekannt wird.",
  
  Blog_Hungarian_Summary_Title: "Zusammenfassung",
  Blog_Hungarian_Summary_Text:
    "OrbitalChamp ist ein ausgezeichnetes Beispiel dafür, wie bestehende Technologien weiterentwickelt und einem breiteren Nutzerkreis zugänglich gemacht werden können. Die Vorteile des Orbital-Schweißens zu nutzen, ist jetzt nicht nur ein Privileg großer Unternehmen, sondern auch für kleinere Firmen und Werkstätten verfügbar.",
  
  Blog_Hungarian_Author_Name: "Benedek Horváth",
  Blog_Hungarian_Author_Title: "OrbitalChamp CEO",
  
  Blog_NoJobsTaken_Title: "Warum Orbital-Schweißen den Arbeitsplätzen der Schweißer nicht schadet",
  Blog_NoJobsTaken_Paragraph1: 
    "Die Entwicklung der industriellen Technologien sorgt häufig für Besorgnis unter den Arbeitnehmern, insbesondere wenn es um Automatisierung und Mechanisierung geht. Orbital-Schweißen ist eine solche Innovation, die auf den ersten Blick als Bedrohung für die Schweißer erscheinen mag. In Wirklichkeit nimmt diese Technologie jedoch nicht den Schweißern die Arbeit weg, sondern bietet neue Möglichkeiten und Vorteile. Lassen Sie uns sehen, warum!",
  
  Blog_NoJobsTaken_Section1_Title: "1. Orbital-Schweißen ergänzt die Arbeit der Schweißer",
  Blog_NoJobsTaken_Section1_Text1: 
    "Das Orbital-Schweißen wurde für spezielle Aufgaben entwickelt, bei denen Präzision und Wiederholbarkeit entscheidend sind, wie beispielsweise beim Schweißen von dünnwandigen Rohren in der Pharma- oder Lebensmittelindustrie. Diese Aufgaben sind oft monoton und körperlich belastend, daher macht die Automatisierung den Prozess nicht nur effizienter, sondern auch vorteilhaft für die Schweißer.",
  Blog_NoJobsTaken_Section1_Text2: 
    "Die Schweißer spielen weiterhin eine Schlüsselrolle im Prozess:",
  Blog_NoJobsTaken_Supervision: "Überwachung und Kontrolle",
  Blog_NoJobsTaken_Supervision_Text: 
    "Die Bedienung der Maschinen wird von erfahrenen Schweißern überwacht, die die Qualität und Sicherheit gewährleisten.",
  Blog_NoJobsTaken_Programming: "Programmierung und Einrichtung",
  Blog_NoJobsTaken_Programming_Text: 
    "Die Programmierung und Einrichtung der orbitalen Schweißmaschinen erfolgt durch ausgebildete Schweißer.",
  Blog_NoJobsTaken_Maintenance: "Wartung",
  Blog_NoJobsTaken_Maintenance_Text: 
    "Die Wartung und Fehlersuche der Geräte erfordert ebenfalls Fachwissen.",

  Blog_NoJobsTaken_Section2_Title: "2. Neue Fähigkeiten und berufliche Entwicklung",
  Blog_NoJobsTaken_Section2_Text: 
    "Das Erscheinen des Orbital-Schweißens bietet den Schweißern die Möglichkeit, neue Fähigkeiten zu erlernen und sich beruflich weiterzuentwickeln. Kenntnisse über moderne Schweißtechnologien erhöhen den Wert der Arbeitnehmer auf dem Arbeitsmarkt.",
  Blog_NoJobsTaken_TechnologySkills: "Technologische Kenntnisse",
  Blog_NoJobsTaken_TechnologySkills_Text: 
    "Die Bedienung und Programmierung automatisierter Systeme erfordert neue Kompetenzen.",
  Blog_NoJobsTaken_CareerAdvancement: "Beruflicher Aufstieg",
  Blog_NoJobsTaken_CareerAdvancement_Text: 
    "Das Erlernen neuer Technologien bietet Möglichkeiten für den beruflichen Aufstieg und das Erreichen höherer Positionen.",
  Blog_NoJobsTaken_Education: "Bildung und Ausbildung",
  Blog_NoJobsTaken_Education_Text: 
    "Die Weiterbildung der Schweißer erhöht die Wettbewerbsfähigkeit der Unternehmen und verbessert die Arbeitszufriedenheit der Mitarbeiter.",

  Blog_NoJobsTaken_Section3_Title: "3. Fokus auf kreative und komplexe Aufgaben",
  Blog_NoJobsTaken_Section3_Text: 
    "Die Automatisierung befreit die Schweißer von repetitiven und monotonen Aufgaben, sodass sie sich auf kreativere und komplexere Arbeiten konzentrieren können.",
  Blog_NoJobsTaken_UniqueJobs: "Einzigartige Aufgaben",
  Blog_NoJobsTaken_UniqueJobs_Text: 
    "Spezielle Schweißaufgaben, die einzigartige Lösungen erfordern, verlangen weiterhin menschliche Expertise.",
  Blog_NoJobsTaken_ProblemSolving: "Problemlösung",
  Blog_NoJobsTaken_ProblemSolving_Text: 
    "Der Umgang mit nicht-standardmäßigen Situationen und das Lösen unerwarteter Probleme basieren auf der Erfahrung der Schweißer.",
  Blog_NoJobsTaken_QualityAssurance: "Qualitätssicherung",
  Blog_NoJobsTaken_QualityAssurance_Text: 
    "Das menschliche Auge und Urteilsvermögen sind bei der finalen Qualitätsprüfung unersetzlich.",

  Blog_NoJobsTaken_Section4_Title: "4. Orbital-Schweißen ist nicht für jede Aufgabe geeignet",
  Blog_NoJobsTaken_Section4_Text: 
    "Obwohl das Orbital-Schweißen viele Vorteile hat, kann es nicht für jede Schweißaufgabe angewendet werden. Das Schweißen komplexer Strukturen, schwer zugänglicher Stellen und spezieller Materialien erfordert weiterhin erfahrene Schweißer.",

  Blog_NoJobsTaken_Section5_Title: "5. Minderung des Arbeitskräftemangels",
  Blog_NoJobsTaken_Section5_Text: 
    "In der Schweißindustrie gibt es weltweit einen Arbeitskräftemangel. Orbital-Schweißen trägt dazu bei, diesen Mangel zu lindern, indem es die Produktivität steigert, ersetzt jedoch nicht die qualifizierten Fachkräfte.",

  Blog_NoJobsTaken_Summary_Title: "Zusammenfassung",
  Blog_NoJobsTaken_Summary_Text: 
    "Orbital-Schweißen ist keine Bedrohung für die Schweißer, sondern ein Werkzeug, das ihre Arbeit erleichtert und Möglichkeiten zur beruflichen Entwicklung schafft. Menschliche Expertise und Erfahrung bleiben in den Schweißprozessen unerlässlich. Die Zusammenarbeit zwischen Technologie und Mensch führt zu den besten Ergebnissen, weshalb es sich lohnt, innovationsfreudig zu sein und die gebotenen Vorteile zu nutzen.",

  Blog_NoJobsTaken_Author_Name: "Benedek Horváth",
  Blog_NoJobsTaken_Author_Title: "OrbitalChamp CEO",


// ============================================== ROI BLOG POST ==========================================

      Blog_ROI_Disclainer: "Achtung! Diese Studie bezieht sich für den ungarischen Market, und das Steuersystem von Ungarn. The werte sind in Forints angegeben!",


      Blog_ROI_Title: "2 Jahre 2x OrbitalChamp Preisersparnis? Wie?",
      Blog_ROI_Title3 : "Wie amortisiert man sich?",
      Blog_ROI_Title2 : "Warum es wichtig ist, das richtige Werkzeug zu wählen",

      Blog_ROI_Intro1 : "Jede Entscheidung in der Fertigung kostet Geld, und das Schweißen ist da keine Ausnahme. Bei herkömmlichen Schweißverfahren sind die Ressourcen oft suboptimal, und Fehler sind unvermeidlich. Aber was wäre, wenn wir eine Lösung finden könnten, die nicht nur die Anzahl der Fehler reduziert, sondern auch ein schnelleres und effizienteres Arbeiten ermöglicht?"
 + "Die Produkte von OrbitalChamp bieten genau das: Sie reduzieren die Kosten und halten gleichzeitig die Schweißqualität auf höchstem Niveau. In diesem Artikel erfahren Sie, warum Sie in ein OrbitalChamp-System investieren sollten und wie sich Ihre Investition langfristig auszahlt", 
      Blog_ROI_Intro2: 
        "Präzision, Qualität und Kosteneffizienz sind in der Welt des Schweißens entscheidend. Eine beschädigte Rohrleitung kann in manchen Branchen Milliarden kosten, in anderen nur ärgerliche Zeit. Mit dem automatisierten Schweißsystem OrbitalChamp arbeiten Sie nicht nur schneller und präziser, sondern machen Ihre Investition in kurzer Zeit wieder wett.",

      Blog_ROI_Intro3 : "Es gibt nur wenige Unternehmen, die sich ausschließlich mit Rohrschweißen beschäftigen. Mit Orbitalchamp können die Schweißarbeiten jedoch diversifiziert werden. So können sich die Schweißer entspannt auf andere Schweißarbeiten konzentrieren, während das Rohrschweißen, eine Arbeit, die viel Konzentration erfordert, Orbitalchamp überlassen werden kann. Effizienteres Arbeiten, weniger Fehler, mehr Erfolg für das Unternehmen. Wenn Sie sich jedoch fragen, wie lange es dauert, bis sich Ihre Investition rentiert, wenn Sie nur das Rohrschweißen optimieren wollen, sollten Sie unseren Artikel lesen",

      Blog_ROI_SchweißerBruttText : "Bruttolohn der Schweißer : ",
      Blog_ROI_SchweißerBruttVal : "1 020 000 Ft",
      Blog_ROI_SchweißerNetVal : "(netto 600 000 Ft)",

      Blog_ROI_WorkerBruttText : "Bruttolohn eines Arbeiters : ",
      Blog_ROI_ArbeiterBruttVal : "530 000 Ft",
      Blog_ROI_ArbeiterNetVal : "(netto 300 000 Ft)",

      Blog_ROI_OCBruttText : "OrbitalChamp Preis : ",
      Blog_ROI_OcBruttVal : "2 750 000 Ft",

      Blog_ROI_SmallCompany : "A) Kleines Unternehmen",
      Blog_ROI_MediumCompany : "B) Mittleres Unternehmen",
      Blog_ROI_MediumBigCompany : "C) Mittel-Klein",





    Blog_ROI_Calculation_Title : "Wie lange dauert es, bis sich OrbitalChamp amortisiert? - Detaillierte Analyse der Kosten und Erträge",

    Blog_ROI_Calculation_Paragraph1: "5 Monate. Das ist unmöglich! Nachfolgend finden Sie eine detaillierte Analyse, die zeigt, wie sich die Kosten für den Einsatz des OrbitalChamp-Werkzeugs im Vergleich zu herkömmlichen Schweißverfahren darstellen. Es werden drei verschiedene Unternehmensebenen betrachtet: ein kleines Unternehmen, ein mittleres Unternehmen und ein mittelgroßes Unternehmen. Unser Ziel ist es zu zeigen, wie durch den Einsatz von OrbitalChamp in der Schweißtechnik Kosten gesenkt und die Produktivität verbessert werden kann,"

 ,   Blog_ROI_Calculation_Paragraph2: "Wir gehen davon aus, dass in den nächsten 24 Monaten kontinuierliche Schweißarbeiten anfallen, die von 1-4 fest angestellten Schweißern ausgeführt werden. Unser Ziel ist es, die Produktion zu beschleunigen, die Qualität zu verbessern und die Kosten zu senken",

    Blog_ROI_Calculation_Paragraph3: "Darüber hinaus berücksichtigen wir nicht die zusätzliche Arbeit und den zusätzlichen Gewinn, der durch eine erhöhte Produktivität erzielt werden könnte. Wir betrachten nur, wie sich die Kosten ohne OrbitalChamp und mit OrbitalChamp entwickeln",

    Blog_ROI_Case_Title: "Wir betrachten drei verschiedene Fälle",
    Blog_ROI_Case1_Title: "Erster Fall: ",
    Blog_ROI_Case1_Text: "Wir schweißen Rohre, setzen aber keinen separaten Schweißingenieur ein",
    Blog_ROI_Case2_Title: "Zweiter Fall: ",
    Blog_ROI_Case2_Text: "Wir würden gerne die Anzahl der Schweißungen erhöhen, aber die finanzielle Situation des Unternehmens erlaubt es uns nicht, mehr Ingenieure einzustellen (OrbitalChamp kann helfen!)",
    Blog_ROI_Case3_Title: "Dritter Fall: ",
    Blog_ROI_Case3_Text: "Wir wollen deutlich expandieren, aber auf möglichst kostengünstige Weise",

    Blog_ROI_Calculation_Paragraph4: "In unserer Studie betrachten wir, wie sich der Gesamtkostensatz im Laufe der Zeit verändert.",

    Blog_ROI_Calculation_Title2 : "Kostenberechnungen:",
    Blog_ROI_Calculation_Paragraph5 : "Die folgende Tabelle zeigt die Kosten im Vergleich zum konventionellen Schweißen mit OrbitalChamp:",
   
    Blog_ROI_NumberOfWeldders : "Anzahl der Schweißer",
    Blog_ROI_Cost_WO_OC : "Kosten ohne OrbitalChamp über 24 Monate",
    Blog_ROI_Cost_W_OC : "Kosten mit OrbitalChamp über 24 Monate",
    Blog_ROI_Diff_In_24_Months : "Abweichung nach 24 Monaten",

    Blog_ROI_Scenario1_Title: "1 Schweißer vs. 1 angelernter Arbeiter + 1 OrbitalChamp-Gerät",
Blog_ROI_Scenario2_Title: "2 Schweißer vs. 1 Schweißer + 1 angelernter Arbeiter + 2 OrbitalChamp-Geräte",
Blog_ROI_Scenario3_Title: "4 Schweißer vs. 1 Schweißer + 1 angelernter Arbeiter + 3 OrbitalChamp-Geräte",

Blog_ROI_Cost_In_Ft_Scen1_WO_OC : "~ 25 Millionen Ft",
Blog_ROI_Cost_In_Ft_Scen1_W_OC : "~ 15 Millionen Ft",
Blog_ROI_Cost_In_Ft_Scen1_Diff : "~ 10 Millionen Ft Einsparung",

Blog_ROI_Cost_In_Ft_Scen2_WO_OC : "~ 50 Millionen Ft",
Blog_ROI_Cost_In_Ft_Scen2_W_OC : "~ 30 Millionen Ft",
Blog_ROI_Cost_In_Ft_Scen2_Diff : "~ 20 Millionen Ft Einsparung",

Blog_ROI_Cost_In_Ft_Scen3_WO_OC : "~ 100 Millionen Ft",
Blog_ROI_Cost_In_Ft_Scen3_W_OC : "~ 70 Millionen Ft",
Blog_ROI_Cost_In_Ft_Scen3_Diff : "~ 30 Millionen Ft Einsparung",

Blog_ROI_Summary : "Die Höhe der Einsparungen ist beeindruckend, aber noch attraktiver ist, dass sich die Investition laut Berechnungen bereits im 5. bis 6. Monat amortisiert. Ab diesem Zeitpunkt sinken die Kosten kontinuierlich durch den Einsatz des OrbitalChamp, wodurch langfristige Einsparungen entstehen.",

Blog_ROI_Outro_Title : "Warum lohnt sich die Investition?",
Blog_ROI_Outro1 : "Mit OrbitalChamp können nicht nur die Kosten gesenkt, sondern auch die Qualität und Geschwindigkeit der Schweißprozesse verbessert werden. Zudem ermöglicht es eine größere Flexibilität bei den Schweißarbeiten und hilft, fehlerhafte Schweißnähte zu vermeiden. Der langfristige Einsatz der Geräte führt zu erheblichen Einsparungen bei zukünftigen Schweißprojekten.",  
Blog_ROI_Outro2 : "Wenn du detailliertere Berechnungen sehen möchtest, klicke auf die Schaltfläche links. Möchtest du wissen, wie der OrbitalChamp funktioniert, dann sieh dir die rechte Schaltfläche an!",  

Blog_ROI_Megterules_calc : "Amortisationsberechnung",
Blog_ROI_Bemutato : "Für eine Vorführung anmelden",

ROI_Title: "Return-on-Investment-Berechnung",  
ROI_Intro: "In dieser Studie untersuchen wir die wirtschaftlichen Auswirkungen des OrbitalChamp-Systems in drei verschiedenen Unternehmensgrößen, wobei wir Investitions- und Betriebskosten sowie die Lohnstrukturen der Mitarbeiter berücksichtigen. Ziel der Analyse ist es, die Kostensenkung und die Amortisationsdauer zu quantifizieren.",  
ROI_Title_Base_Data: "Grunddaten und Annahmen",  
ROI_Base_Data_Par1: "Alle mathematischen Berechnungen basieren auf bestimmten Annahmen und Rahmenbedingungen. In diesem Fall besteht die Grundannahme darin, dass in den nächsten 24 Monaten kontinuierliche Schweißarbeiten erforderlich sind und das Unternehmen 1-4 Fachkräfte mit einem unbefristeten Vertrag beschäftigt. Die zusätzliche Arbeitslast, die durch den Einsatz der Maschine übernommen werden kann, wird in dieser Berechnung nicht berücksichtigt.",  
ROI_Base_Data_Par2: "Für die Berechnungen benötigte Daten: Die durchschnittlichen Löhne eines Schweißers und eines ungelernten Arbeiters sowie deren Bruttokosten. Diese sind:",  
ROI_Variable_Names: "Bezeichnungen weiterer wichtiger Variablen:",  
ROI_WelderWageText: "Bruttolohn der Schweißer",  
ROI_WelderCntText: "Anzahl der Schweißer",  
ROI_WelderCntTextVesszo: "Geänderte Anzahl der Schweißer",  
ROI_WorkerWageText: "Bruttolohn der ungelernten Arbeiter",  
ROI_WorkerCntText: "Anzahl der ungelernten Arbeiter",  
ROI_OrbitalCntText: "Anzahl der OrbitalChamp-Maschinen",  
ROI_CostVariableWeld: "Kosten bei Beschäftigung von Schweißern",  
ROI_CostVariableOrbi: "Kosten bei Beschäftigung von Schweißern und OrbitalChamp",  
ROI_DiffVarName: "Kostendifferenz",  
ROI_MonthsCnt: "Anzahl der Monate",  
ROI_NullLine: "Break-even-Linie",  
ROI_Machine_Price: "Preis der Maschinen",  
ROI_Intro2: "In unserer Analyse betrachten wir drei verschiedene Unternehmensstrukturen:",  
ROI_CostTitle: "Das Kostenanalysemodell",  
ROI_Cost_Par1: "Die für unsere Berechnungen verwendeten Formeln sind immer dieselben und lauten wie folgt:",  
ROI_Cost_Formula1: "Gesamtkosten für Arbeitskräfte ohne Automatisierung:",  
ROI_Cost_Formula2: "Betriebskosten mit dem OrbitalChamp-System:",  
ROI_Cost_Formula3: "Differenz zwischen den beiden Kosten:",  
ROI_Searched_Point: "Gesuchter Punkt:",  
ROI_Cost_Par2: "Je näher dieser Wert an null liegt, desto näher liegt der Break-even-Punkt. Dies gibt den Zeitpunkt an, zu dem die Gesamtkosten in beiden Varianten gleich sind. An diesem Punkt ist es also unerheblich, ob wir eine OrbitalChamp-Schweißmaschine verwenden oder Schweißer beschäftigen. Über diesen Punkt hinaus wird der Unterschied jedoch erheblich.",  
ROI_Cost_Par3: "Wir teilen diese Formeln mit den geschätzten Lesern, damit sie die Berechnungen mit beliebigen Eingabewerten oder mit den hier angegebenen Daten überprüfen können. Es wird jedoch auch einen interaktiven Diagrammgenerator auf der Website geben, in dem jeder beliebige Werte eingeben und testen kann, welche Lösung für ihn am effektivsten ist.",  
ROI_Results: "Ergebnisse",  
ROI_Result1_Title: "1. Im Fall eines kleinen Unternehmens",  
ROI_Result1_Text: "Am Ende des 24-monatigen Zeitraums erreichen die Kosten für die Beschäftigung von ausschließlich Schweißingenieuren 25 Millionen Forint, während die Betriebskosten mit dem OrbitalChamp-System 15 Millionen Forint betragen. Die Amortisationszeit beträgt 5-6 Monate, während sich die gesamte Investition nach 11 Monaten auszahlt.",  
ROI_Result2_Title: "2. Im Fall eines mittelständischen Unternehmens",  
ROI_Result2_Text: "Ein ähnlicher Trend ist zu beobachten: Die Amortisation erfolgt nach 5-6 Monaten, während die Kostendifferenz bis zum Ende des 24. Monats etwa 20 Millionen Forint erreicht. Die Investition amortisiert sich innerhalb von 10-11 Monaten.",  
ROI_Result3_Title: "3. Im Fall eines mittelgroßen Unternehmens",  
ROI_Result3_Text: "Obwohl die Anfangsinvestition höher ist, steigt die Kostendifferenz bis zum Ende des 24. Monats auf 30 Millionen Forint, da die Produktionskapazität größer ist. Der Break-even-Punkt liegt ebenfalls bei 5-6 Monaten, während sich die gesamte Investition innerhalb von 11 Monaten amortisiert.",  
ROI_Conclusion: "Fazit",  
ROI_Outro1: "Die Analyse zeigt deutlich, dass der Einsatz des automatisierten OrbitalChamp-Schweißsystems erhebliche Kosteneinsparungen im Vergleich zum traditionellen manuellen Schweißen mit sich bringt. Die Amortisationszeit ist kurz und liegt typischerweise zwischen 5-6 Monaten, während sich die gesamte Investition innerhalb von 10-11 Monaten auszahlt. Dies wurde in drei ähnlichen, aber unterschiedlichen Fällen nachgewiesen.",  
ROI_Outro2: "Mit der Einführung der automatisierten Schweißtechnologie können nicht nur Kosten gesenkt, sondern auch Produktivität und Schweißqualität gesteigert werden, was Unternehmen langfristig einen Wettbewerbsvorteil verschafft. Basierend auf den Ergebnissen kann gesagt werden, dass der Einsatz des Systems nicht nur aus wirtschaftlicher Sicht empfohlen wird, sondern auch erhebliche Vorteile in Bezug auf Effizienz und Qualität mit sich bringt. Darüber werden wir in einem späteren Artikel schreiben.",  
ROI_Outro3: "Wenn Sie sich für das Produkt interessieren, kontaktieren Sie uns bitte auf der folgenden Seite:",  
ROI_SporoltOszzeg: "Eingesparte Summe am Ende des 24. Monats:",  
ROI_Osszkoltseg_Hegeszto: "Gesamtkosten ohne OrbitalChamp am Ende des 24. Monats:",  
ROI_Osszkoltseg_Orbitalchamp: "Gesamtkosten mit OrbitalChamp am Ende des 24. Monats:",  
ROI_Money: "Million HUF",  

CostCalculator: "Berechnung der Kosten" 

},

};

export default translations


