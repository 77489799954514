import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import '../Designe/WelcomePage.css'
import logo2 from '../img/logo_transparent_edited.png'

const WelcomePageDE = () => {
  const location = useLocation(); // Az URL-ből érkező paraméterek eléréséhez
  const history = useHistory(); // Navigációhoz (React Router v5)
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Kinyerjük az URL query paramétereit
  const queryParams = new URLSearchParams(location.search);
  const uid = queryParams.get("uid");
  const redirectDirection = queryParams.get("redirectDirection");

  useEffect(() => {
    if (!uid || !redirectDirection) {
      setError("Hiányzó query paraméter (uid vagy redirectDirection).");
      setIsLoading(false);
      return;
    }

    const timestamp = new Date().toISOString();

    // Email küldése axios segítségével a backend végponton
    axios
      .post("https://app-ppd7fma2sq-uc.a.run.app/sendmail", {
        to: "info@orbitalchamp.com", // Cél email cím
        subject: "Weboldal látogatás - OrbitalChamp",
        message: `UID: ${uid}\nRedirectDirection: ${redirectDirection}\nTimestamp: ${timestamp}`,
      })
      .then((response) => {
        console.log("Email sikeresen elküldve:", response.data.message);
        // 2 másodperc után átirányítjuk a felhasználót
        setTimeout(() => {
          history.push(`/${redirectDirection}`);
        }, 1000);
      })
      .catch((err) => {
        console.error("Hiba az email küldésekor:", err);
        setError("Hiba történt az email küldése közben.");
        setIsLoading(false);
      });
  }, [uid, redirectDirection, history]);

  if (isLoading) {
    return (
      <div className="welcome-page-container">
        <div className="inner-welcome-page-container" style={{ textAlign: "center", padding: "50px" }}>
          <h1 className="h1_welcome_page">Willkommen bei OrbitalChamp!</h1>
          
        </div>
        <div className="inner-welcome-page-container" style={{ textAlign: "center", padding: "50px" }}>
          <img className="wp_img" src={logo2} alt="Company Logo"/>
        
        </div>
        <div className="inner-welcome-page-container" style={{ textAlign: "center", padding: "50px" }}>
          <h1 className="h1_welcome_page">Die Seite wird geladen...</h1>
          
        </div>
      </div>
    );
  }


  return null; // Ha minden rendben, a felhasználó át lesz irányítva





};

export default WelcomePageDE;
