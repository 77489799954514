import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Switch from "react-switch";
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useSelector } from 'react-redux';
import '../Designe/SeparateContactPage.css';
import translations from '../strings/Strings';
import location from '../img/footer/location.png'
import phone from '../img/footer/telephone (1).png'
import facebook from '../img/footer/facebook (1).png'
import instagramm from '../img/footer/instagram.png'
import youtube from '../img/footer/youtube.png'
import gmail from '../img/footer/gmail.png'
import shark from '../img/footer/shark.png'
import maps from '../img/footer/maps.png'
import logo from '../img/logo_only.png'
import logo2 from '../img/logo_transparent_edited.png'
import '../Designe/SeparateContactPage.css'


function ContactUsPage() {
  const title_size = 50;
  const input_size = 50;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactLocation, setContactLocation] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [isAtYourLocation, setIsAtYourLocation] = useState(false);
  const language = useSelector((state) => state.language);
  const [strings, setStrings] = useState(translations.hun);
  const [loading, setLoading] = useState(false); // Loading state
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);
  const history = useHistory(); // Initialize useHistory
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); 
    
    axios
      .post('https://app-ppd7fma2sq-uc.a.run.app/sendmail', {
        to: 'info@orbitalchamp.com',
        subject: 'Üzenet az Orbitalchamp.com-ról!',
        message: 
        !isAtYourLocation ? 
        `
          Név: ${name}
          Email: ${email}
          Cégnév: ${company}
          Telefonszám: ${phoneNumber}
          Demo dátuma: ${selectedDate ? selectedDate.toLocaleDateString() : 'Nincs megadva'}
          Időpont: ${selectedTime || 'Nincs megadva'}
          Locale: ${language}
        `
        :
        `
          Név: ${name}
          Email: ${email}
          Cégnév: ${company}
          Telefonszám: ${phoneNumber}
          Demo dátuma: ${selectedDate ? selectedDate.toLocaleDateString() : 'Nincs megadva'}
          Ügyfél helyszin: ${contactLocation || 'Nincs megadva'}
          Locale: ${language}
        `
      })
      .then(() => {
        setLoading(false); // Stop loading
        alert('Az üzenet sikeresen elküldve!');
         history.push('/'); // Navigate to home page
      })
      .catch(() => {
        setLoading(false); // Stop loading
        alert('Hiba történt az üzenet küldése közben!');
      });
  };

  const handleImageClick = (url) => {
    window.open(url, '_blank');
  };

  const handleEmailClick = () => {
    const emailAddress = 'info@orbitalchamp.com';
    const emailSubject = 'Érdeklődni szeretnék!';
    const emailBody = 'Kedves OrbitalChamp kft!';
    const emailLink = `mailto:${emailAddress}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    
    window.open(emailLink, '_blank');
  };

  const handleMobileClick = () => {
    const phoneNumber = '+36305622299';
    const phoneLink = `tel:${phoneNumber}`;
  
    window.open(phoneLink, '_self');
  };

  useEffect(() => {
    const handleResize = () => {
      // You can handle layout changes dynamically here if needed
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    switch (language) {
      case 'en':       
        setStrings(translations.en)
        break;  
      case 'hun':        
        setStrings(translations.hun)
        break;       
      case 'de':        
          setStrings(translations.de)
          break;         
      default:
        break;
    }
  }, [language]);

  const renderLoadingIndicator = () => (
    <div className="sep_loading-container">
      <p>{strings.ContactLoading}</p>
      <img src={logo} alt="Loading" className="sep_loading-image" />
    </div>
  );

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        id="name"
        name="name"
        placeholder={strings.Contact_name}
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{ fontSize: window.innerHeight / title_size }}
        required
      />
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ fontSize: window.innerHeight / title_size }}
        required
      />
      <input
        type="text"
        id="company"
        name="company"
        placeholder={strings.Contact_Company_name}
        value={company}
        onChange={(e) => setCompany(e.target.value)}
        style={{ fontSize: window.innerHeight / title_size }}
      />
      <input
        type="tel"
        id="phone"
        name="phone"
        placeholder={strings.Contact_Phone}
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        style={{ fontSize: window.innerHeight / title_size }}
        required
      />

      {/* Toggle switch */}
      <div className="sep_switch-container">
        <Switch
          onChange={() => setIsAtYourLocation(!isAtYourLocation)}
          checked={isAtYourLocation}
          onColor="#960000"
          offColor="#800000"
          handleDiameter={isMobile ? 20 : 50}
          height={isMobile ? 30 : 60}
          width={isMobile ? 100 : 260}
          checkedIcon={
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              paddingLeft: isMobile ? 21 : 100,
            }}>
              <p style={{
                margin: 0,
                fontSize: isMobile ? 12 : 18,
                color: "white",
                whiteSpace: "nowrap",
                fontFamily: "'Russo One', sans-serif",
                textAlign: "center",
              }}>
                {strings.Contact_AtYou}
              </p>
            </div>
          }
          uncheckedIcon={
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              whiteSpace: "nowrap",
              paddingRight: isMobile ? 30 : 100,
            }}>
              <p style={{
                margin: 0,
                fontSize: isMobile ? 12 : 20,
                color: "white",
                fontFamily: "'Russo One', sans-serif",
                textAlign: "center",
              }}>
                {strings.Contact_AtUs}
              </p>
            </div>
          }
        />
      </div>

      {/* If at your location then show location input, otherwise date & time pickers */}
      {isAtYourLocation ? (
        <>
          <input
            type="text"
            id="contactLocation"
            name="contactLocation"
            placeholder={strings.Contact_AtYouPlace}
            value={contactLocation}
            onChange={(e) => setContactLocation(e.target.value)}
            style={{ fontSize: window.innerHeight / title_size }}
          />
          <p className="sep_info_paragraph">
            {strings.Contact_Info}
          </p>
        </>
      ) : (
        <>
          <div className="sep_datepicker-container">
            <p className="sep_info_paragraph">
              {strings.Contact_Info2}
            </p>
            <DatePicker
              className="sep_datepicker"
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText={strings.Contact_ChooseDate}
              isClearable
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={20}
            />
          </div>
          <input
            className="sep_timePicker"
            type="time"
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
          />
        </>
      )}

      <button className="sep-button-send" type="submit">
        {strings.Conatct_Send}
      </button>

      {isMobile ? (
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "250px",
          height: "250px",
          marginTop: "24px"
        }}>
          <img
            src={logo2}
            alt="Handle"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain"
            }}
          />
        </div>
      ) : (
        <div></div>
      )}
    </form>
  );

  const renderFooter = () => {
    if (window.innerWidth > 762) {
      return (
        <div className="sep_footer">
          <div className="footer">
            <div className="footer-top">
              <div className="footer-item large">
                <img src={gmail} alt="Email" onClick={handleEmailClick} />
                <p>info@orbitalchamp.com</p>
              </div>
              <div className="footer-item large">
                <img src={phone} alt="Kapcsolat OrbitalChamp telefonszámon" />
                <p>+36 30 562 2299</p>
              </div>
              <div className="footer-item">
                <img src={facebook} alt="Facebook" onClick={() => handleImageClick('https://www.facebook.com/profile.php?id=100063915306124')} />
                <p> Facebook </p>
              </div>
              <div className="footer-item">
                <img src={instagramm} alt="Instagram" onClick={() => handleImageClick('https://www.instagram.com/orbitalchamp2023')} />
                <p> Instagram </p>
              </div>
              <div className="footer-item">
                <img src={youtube} alt="YouTube" onClick={() => handleImageClick('https://www.youtube.com/channel/UC1kGWbqiOiRHCLruU4hNpbA')} />
                <p> YouTube </p>
              </div>
              <div className="footer-item">
                <img src={maps} alt="OrbitalChamp helyszín Budapest" onClick={() => handleImageClick('https://www.google.com/maps/place/OrbitalChamp+kft./@47.4119787,18.9968637,17z/data=!3m1!4b1!4m6!3m5!1s0x4741e7a691823f57:0x995dc5cf08485731!8m2!3d47.4119787!4d18.9994386!16s%2Fg%2F11vcmp3gjj?entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D')} />
                <p>{strings.Footer_Location}</p>
              </div>
            </div>
            <div className="footer-bottom"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="footer">
          <div className="footer-top">
            <div className="footer-item large">
              <div className="footer-item">
                <img src={gmail} alt="Email" onClick={handleEmailClick} />
              </div>
              <p>info@orbitalchamp.com</p>
            </div>
            <div className="footer-item large">
              <img src={phone} alt="Kapcsolat OrbitalChamp telefonszámon" onClick={handleMobileClick} />
              <p>+36 30 562 2299</p>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-item">
              <img src={facebook} alt="Facebook" onClick={() => handleImageClick('https://www.facebook.com/profile.php?id=100063915306124')} />
              <p> Facebook </p>
            </div>
            <div className="footer-item">
              <img src={instagramm} alt="Instagram" onClick={() => handleImageClick('https://www.instagram.com/orbitalchamp2023')} />
              <p> Instagram </p>
            </div>
            <div className="footer-item">
              <img src={youtube} alt="YouTube" onClick={() => handleImageClick('https://www.youtube.com/channel/UC1kGWbqiOiRHCLruU4hNpbA')} />
              <p> YouTube </p>
            </div>
            <div className="footer-item">
              <img src={maps} alt="OrbitalChamp helyszín Budapest" onClick={() => handleImageClick('https://www.google.com/maps/place/OrbitalChamp+kft./@47.4119787,18.9968637,17z/data=!3m1!4b1!4m6!3m5!1s0x4741e7a691823f57:0x995dc5cf08485731!8m2!3d47.4119787!4d18.9994386!16s%2Fg%2F11vcmp3gjj?entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D')} />
              <p>{strings.Footer_Location}</p>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="sep_footer_container" id="contact">
      <div className="sep_container">
        <div className="sep_right-column">
          <div className="sep-header">
            <div style={isMobile? {marginTop:"100px"}:{marginTop:"0px"} }></div>
            <h2 className="sep-title">{strings.ContactApplyTitle}</h2>
          </div>
          {loading ? renderLoadingIndicator() : renderForm()}
        </div>
      </div>
      {renderFooter()}
    </div>
  );
}

export default ContactUsPage;
