import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '../Designe/Blogs/calculator.css';


const CostCalculator = ({ strings }) => {
  const [inputs, setInputs] = useState({
    gamma_w:1020000, // Hegesztő netto bére (γh)
    gamma_m: 530000, // Betanított munkás netto bére (γm)
    n_m: 1, // Betanított munkások száma
    n_w: 1, // Hegesztők száma (nh)
    n_w2: 0, // Hegesztők száma (nh)
    n_omega: 1, // Orbitalchamp gépek száma (nΩ)
  });
  const [alphaH, setAlphaH] = useState(0); // Kölség hegesztők alkalmazásával (αh)
  const [alphaOmega, setAlphaOmega] = useState(0); // Kölség hegesztők és Orbitalchamp alkalmazásával (αΩ)
  const [deltaAlpha, setDeltaAlpha] = useState(10.61);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);

    const calculateCosts = () => {
    const { n_m, gamma_m, n_w, n_w2, gamma_w, n_omega } = inputs;

    const calculatedGammaW = gamma_w / (0.88 );
    const calculatedGammaM = gamma_m / (0.88 );

    const calculatedAlphaH = calculatedGammaW * n_w;
    const calculatedAlphaOmega = (n_m * calculatedGammaM + n_w2 * calculatedGammaW);


    // 24 hónapos adatok létrehozása
    const chartData = Array.from({ length: 24 }, (_, i) => ({
      month: i + 1,
      alphaH: calculatedAlphaH/1000000 * (i + 1),
      alphaOmega: (calculatedAlphaOmega * (i + 1) + n_omega * 2750000)/1000000 ,
    }));
    const chartData2 = Array.from({ length: 24 }, (_, i) => ({
      month: i + 1,
      deltaAlpha: ((calculatedAlphaH/1000000 * (i + 1)) - (calculatedAlphaOmega/1000000 * (i + 1) + n_omega * 2.75)) ,
      nulls: 0,
      machinePrice: 2.75*n_omega
    }));


    setAlphaH(calculatedAlphaH/1000000 * (24),);
    setAlphaOmega((calculatedAlphaOmega * (24) + n_omega * 2750000)/1000000);
    setDeltaAlpha((calculatedAlphaH / 1000000 * 24 - (calculatedAlphaOmega / 1000000 * 24 + n_omega * 2.75)));

    setData(chartData);
    setData2(chartData2);
  };

  useEffect(() => {
    calculateCosts();
  }, [inputs]);


    class CustomizedDot extends React.Component {

        render() {
            const { cx, cy } = this.props;

            return (
                <circle cx={cx} cy={cy} r={isMobile? 2 : 4} stroke="black" strokeWidth={1} fill="white" />
            );
        }
    };


  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h2>{strings.CostCalculator}</h2>
      <div >
      <table style={{ margin: "20 auto", textAlign: "left", gap: "20px !important" }}>
      <tbody>
        <tr>
          <td>{strings.ROI_WelderWageText} </td>
         
          <td>
            <div style={{ display: 'flex', alignItems: 'center'  }}>
              <button onClick={() => setInputs({ ...inputs, gamma_w: inputs.gamma_w - 10000 })}>-</button>
              <input
                type="number"
                value={inputs.gamma_w}
                onChange={(e) => setInputs({ ...inputs, gamma_w: parseFloat(e.target.value) })}
                style={{ width: '100px', textAlign: 'center' }}
              />
              <button onClick={() => setInputs({ ...inputs, gamma_w: inputs.gamma_w + 10000 })}>+</button>
            </div>
          </td>
        </tr>
        <tr>
          <td>{strings.ROI_WorkerWageText}</td>
          <td>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button onClick={() => setInputs({ ...inputs, gamma_m: inputs.gamma_m - 10000 })}>-</button>
              <input
                type="number"
                value={inputs.gamma_m}
                onChange={(e) => setInputs({ ...inputs, gamma_m: parseFloat(e.target.value) })}
                style={{ width: '100px', textAlign: 'center' }}
              />
              <button onClick={() => setInputs({ ...inputs, gamma_m: inputs.gamma_m + 10000 })}>+</button>
            </div>
          </td>
        </tr>
        <tr>
          <td>{strings.ROI_OrbitalCntText}</td>
          <td>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button onClick={() => setInputs({ ...inputs, n_omega: inputs.n_omega - 1 })}>-</button>
              <input
                type="number"
                value={inputs.n_omega}
                onChange={(e) => setInputs({ ...inputs, n_omega: parseFloat(e.target.value) })}
                style={{ width: '100px', textAlign: 'center' }}
              />
              <button onClick={() => setInputs({ ...inputs, n_omega: inputs.n_omega + 1 })}>+</button>
            </div>
          </td>
        </tr>
        <tr>
          <td>{strings.ROI_WelderCntText}</td>
          <td>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button onClick={() => setInputs({ ...inputs, n_w: inputs.n_w - 1 })}>-</button>
              <input
                type="number"
                value={inputs.n_w}
                onChange={(e) => setInputs({ ...inputs, n_w: parseFloat(e.target.value) })}
                style={{ width: '100px', textAlign: 'center' }}
              />
              <button onClick={() => setInputs({ ...inputs, n_w: inputs.n_w + 1 })}>+</button>
            </div>
          </td>
        </tr>
        <tr>
          <td>{strings.ROI_WelderCntTextVesszo}</td>
          <td>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button onClick={() => setInputs({ ...inputs, n_w2: inputs.n_w2 - 1 })}>-</button>
              <input
                type="number"
                value={inputs.n_w2}
                onChange={(e) => setInputs({ ...inputs, n_w2: parseFloat(e.target.value) })}
                style={{ width: '100px', textAlign: 'center' }}
              />
              <button onClick={() => setInputs({ ...inputs, n_w2: inputs.n_w2 + 1 })}>+</button>
            </div>
          </td>
        </tr>
        <tr>
          <td>{strings.ROI_WorkerCntText}</td>
          <td>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button onClick={() => setInputs({ ...inputs, n_m: inputs.n_m - 1 })}>-</button>
              <input
                type="number"
                value={inputs.n_m}
                onChange={(e) => setInputs({ ...inputs, n_m: parseFloat(e.target.value) })}
                style={{ width: '100px', textAlign: 'center' }}
              />
              <button onClick={() => setInputs({ ...inputs, n_m: inputs.n_m + 1 })}>+</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
      </div>
      <div style={{marginTop:'20px'}}></div>
<table style={{
  border: '2px solid #9e1000',
  borderCollapse: 'collapse',
  width: '100%'
}}>
  <thead>
    <tr>

    </tr>
  </thead>
  <tbody>
    <tr>
      <td style={{
        border: '1px solid #9e1000',
        padding: '10px',
        textAlign: 'left'
      }}>{strings.ROI_Osszkoltseg_Orbitalchamp}</td>
      <td style={{
        border: '1px solid #9e1000',
        padding: '10px',
        textAlign: 'right'
      }}>{alphaH.toFixed(2)} {strings.ROI_Money}</td>
    </tr>
    <tr>
      <td style={{
        border: '1px solid #9e1000',
        padding: '10px',
        textAlign: 'left'
      }}>{strings.ROI_Osszkoltseg_Hegeszto}</td>
      <td style={{
        border: '1px solid #9e1000',
        padding: '10px',
        textAlign: 'right'
      }}>{alphaOmega.toFixed(2)}  {strings.ROI_Money}</td>
    </tr>
    <tr>
      <td style={{
        border: '1px solid #9e1000',
        padding: '10px',
        textAlign: 'left',
        fontWeight: 'bold'
      }}>{strings.ROI_SporoltOszzeg}</td>
      <td style={{
        border: '1px solid #9e1000',
        padding: '10px',
        textAlign: 'right',
        fontWeight: 'bold'
      }}>{deltaAlpha.toFixed(2)}  {strings.ROI_Money}</td>
    </tr>
  </tbody>
</table>

<div style={{marginTop:'20px'}}></div>
      <div 
        className='chart-container'>
        <ResponsiveContainer >
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" label={{ value: strings.ROI_MonthsCnt, position: "insideBottomRight", offset: -5 }} />
            <YAxis 
              className='small-font'
                  tickFormatter={(value) => `${value } Mio Ft`} 
                  width={100}    
            />
            <Tooltip />
            <Legend 
              layout="vertical" // Ha a vertikális elrendezést választod
              align="center" // Középre igazítja a legendet
              verticalAlign="bottom" // Az aljára helyezi
              wrapperStyle={{
                  
              }} 
              />
            <Line type="monotone" dataKey="alphaH" stroke="#ff0000" name={strings.ROI_CostVariableWeld} dot={<CustomizedDot/>}strokeWidth={3}/>
            <Line type="monotone" dataKey="alphaOmega" stroke="#00ff00" name={strings.ROI_CostVariableOrbi}dot={<CustomizedDot/>} strokeWidth={3} />
          </LineChart>
        </ResponsiveContainer>
        <div style={{marginTop:'20px'}}></div>
          <ResponsiveContainer >
          <LineChart data={data2}>
              <CartesianGrid strokeDasharray="5 3" />
              <XAxis dataKey="month" label={{ value: strings.ROI_MonthsCnt, position: "insideBottomRight", offset: -5 }} />
              <YAxis 
              className='small-font'

                  tickFormatter={(value) => `${value } Mio Ft`} 
                  width={100}    
              />
              <Tooltip />
              <Legend 
              layout="vertical" // Ha a vertikális elrendezést választod
              align="center" // Középre igazítja a legendet
              verticalAlign="bottom" // Az aljára helyezi
              className="legend" 
              />
              <Line type="monotone" dataKey="deltaAlpha" stroke="#5e17eb" name={strings.ROI_DiffVarName}  dot={<CustomizedDot/>} strokeWidth={3}/>
              <Line type="monotone" dataKey="nulls" stroke="#ff5757" name={strings.ROI_NullLine} dot={false} strokeWidth={3}/>
              <Line type="monotone" dataKey="machinePrice" stroke="#2bbd00" name={strings.ROI_Machine_Price} dot={false} strokeWidth={3}/>
          </LineChart>
        </ResponsiveContainer>
      </div>

    </div>
  );
};

export default CostCalculator;

