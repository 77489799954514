import React, { useEffect, useState } from 'react';
import '../../Designe/Blogs/BlogPostWhyNot.css';
import hegesztobacsi from '../../img/OrbitalWelding/O1.jpg';
import iv from '../../img/en.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet} from 'react-helmet'
import translations from '../../strings/Strings'
import { setLanguage } from '../../redux/actions';

function BlogPostHunWeld() {
  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 762);
    };

    
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    switch (language) {
      case 'en':       
        setStrings(translations.en)
        break;  
      case 'hun':        
        setStrings(translations.hun)
        break;       
      case 'de':        
          setStrings(translations.de)
          break;         
      default:
        break;
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [language]);

// Save language to local storage when it changes
const dispatch = useDispatch();
useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'hun'; // Default to Hungarian
    dispatch(setLanguage(savedLanguage)); // Set language in Redux
    setStrings(translations[savedLanguage])
}, [dispatch]);



  var url = 'https://orbitalchamp.com/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes';
  var title = 'Ezért leszel egyre értékesebb, mint hegesztő!';
  var description = 'Az utóbbi években a hegesztők iránti kereslet jelentősen megnövekedett, és ez a tendencia várhatóan folytatódni fog a jövőben is. De miért is leszel egyre értékesebb mint hegesztő, és hogyan használhatod ki a szakmában rejlő lehetőségeket? Ebben a bejegyzésben megvizsgáljuk azokat a tényezőket, amelyek hozzájárulnak ahhoz, hogy hegesztőként egyre nagyobb megbecsülésben részesülj.';
  var imageUrl = hegesztobacsi;
  
  useEffect(() => {
    // Set the document title
    document.title = title;

    // Update meta tags dynamically
    const metaTags = [
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: imageUrl },
      { property: 'og:url', content: url },
      { property: 'og:type', content: 'article' },
      { property: 'og:site_name', content: 'OrbitalChamp.com' }
    ];

    metaTags.forEach(tag => {
      let element = document.querySelector(`meta[property='${tag.property}']`);
      
      if (!element) {
        // If the meta tag doesn't exist, create it
        element = document.createElement('meta');
        element.setAttribute('property', tag.property);
        document.head.appendChild(element);
      }

      element.setAttribute('content', tag.content);
    });
  }, [title, description, imageUrl, url]); 





  if(isMobile==false){
  return (
    <div className="blog-container">

      <div className="menu">
        <ul>
        <li><a href="/shop" replace>{strings.Webshop}</a></li>
          <li><a href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot"                                >{strings.Blog_Top3_Title}       </a></li>
          <li><a href="/OrbitalisHegesztesTortelnelme"                                             >{strings.Blog_History_Title}    </a></li>
          <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto"                                    >{strings.Blog_Valuable_Title}   </a></li>
          <li><a href="/AMagyarOrbitalisHegesztoAzOrbitalChamp"                                    >{strings.Blog_Hungarian_Title}  </a></li>
          <li><a className="a_active" href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes">{strings.Blog_NoJobsTaken_Title}</a></li>
          <li><a href="/OrbitalChampROI"          >{strings.Blog_ROI_Title}</a></li>
        
        </ul>
      </div>

      <div className="blog-content">
      <h1 className="bela">{strings.Blog_NoJobsTaken_Title}</h1>
      <img
        className="welding-image_why"
        src={hegesztobacsi}
        alt="Orbitális hegesztő munka közben"
      />
      <p>{strings.Blog_NoJobsTaken_Paragraph1}</p>

      <h2>{strings.Blog_NoJobsTaken_Section1_Title}</h2>
      <p>{strings.Blog_NoJobsTaken_Section1_Text1}</p>
      <p>{strings.Blog_NoJobsTaken_Section1_Text2}</p>
      <ul>
        <li>
          <strong>{strings.Blog_NoJobsTaken_Supervision}: </strong>
          {strings.Blog_NoJobsTaken_Supervision_Text}
        </li>
        <li>
          <strong>{strings.Blog_NoJobsTaken_Programming}: </strong>
          {strings.Blog_NoJobsTaken_Programming_Text}
        </li>
        <li>
          <strong>{strings.Blog_NoJobsTaken_Maintenance}: </strong>
          {strings.Blog_NoJobsTaken_Maintenance_Text}
        </li>
      </ul>

      <h2>{strings.Blog_NoJobsTaken_Section2_Title}</h2>
      <p>{strings.Blog_NoJobsTaken_Section2_Text}</p>
      <ul>
        <li>
          <strong>{strings.Blog_NoJobsTaken_TechnologySkills}: </strong>
          {strings.Blog_NoJobsTaken_TechnologySkills_Text}
        </li>
        <li>
          <strong>{strings.Blog_NoJobsTaken_CareerAdvancement}: </strong>
          {strings.Blog_NoJobsTaken_CareerAdvancement_Text}
        </li>
        <li>
          <strong>{strings.Blog_NoJobsTaken_Education}: </strong>
          {strings.Blog_NoJobsTaken_Education_Text}
        </li>
      </ul>

      <h2>{strings.Blog_NoJobsTaken_Section3_Title}</h2>
      <p>{strings.Blog_NoJobsTaken_Section3_Text}</p>
      <ul>
        <li>
          <strong>{strings.Blog_NoJobsTaken_UniqueJobs}: </strong>
          {strings.Blog_NoJobsTaken_UniqueJobs_Text}
        </li>
        <li>
          <strong>{strings.Blog_NoJobsTaken_ProblemSolving}: </strong>
          {strings.Blog_NoJobsTaken_ProblemSolving_Text}
        </li>
        <li>
          <strong>{strings.Blog_NoJobsTaken_QualityAssurance}: </strong>
          {strings.Blog_NoJobsTaken_QualityAssurance_Text}
        </li>
      </ul>

      <h2>{strings.Blog_NoJobsTaken_Section4_Title}</h2>
      <p>{strings.Blog_NoJobsTaken_Section4_Text}</p>

      <h2>{strings.Blog_NoJobsTaken_Section5_Title}</h2>
      <p>{strings.Blog_NoJobsTaken_Section5_Text}</p>

      <h2>{strings.Blog_NoJobsTaken_Summary_Title}</h2>
      <p>{strings.Blog_NoJobsTaken_Summary_Text}</p>
      {strings.Blog_Author}
      <div className="author_div">
        <img className="author-image" src={iv} alt="CEO OrbitalChamp" />
        <div className="author-text">
          <p>
            <strong>{strings.Blog_NoJobsTaken_Author_Name}</strong> <br />
            <hr className="rounded"></hr>
            <span className="author-title">{strings.Blog_NoJobsTaken_Author_Title}</span>
          </p>
        </div>
      </div>
    </div>

      <div className='empty-space'></div>
    </div>
  );
 }else{
  return (
    <div className="blog-container">
      

      <div className="blog-content">
      <h1 className="bela-mobile">{strings.Blog_NoJobsTaken_Title}</h1>
      <img
        className="welding-image_why-mobile"
        src={hegesztobacsi}
        alt="Orbitális hegesztő munka közben"
      />
      <p>{strings.Blog_NoJobsTaken_Paragraph1}</p>

      <h2>{strings.Blog_NoJobsTaken_Section1_Title}</h2>
      <p>{strings.Blog_NoJobsTaken_Section1_Text1}</p>
      <p>{strings.Blog_NoJobsTaken_Section1_Text2}</p>
      <ul>
        <li>
          <strong>{strings.Blog_NoJobsTaken_Supervision}: </strong>
          {strings.Blog_NoJobsTaken_Supervision_Text}
        </li>
        <li>
          <strong>{strings.Blog_NoJobsTaken_Programming}: </strong>
          {strings.Blog_NoJobsTaken_Programming_Text}
        </li>
        <li>
          <strong>{strings.Blog_NoJobsTaken_Maintenance}: </strong>
          {strings.Blog_NoJobsTaken_Maintenance_Text}
        </li>
      </ul>

      <h2>{strings.Blog_NoJobsTaken_Section2_Title}</h2>
      <p>{strings.Blog_NoJobsTaken_Section2_Text}</p>
      <ul>
        <li>
          <strong>{strings.Blog_NoJobsTaken_TechnologySkills}: </strong>
          {strings.Blog_NoJobsTaken_TechnologySkills_Text}
        </li>
        <li>
          <strong>{strings.Blog_NoJobsTaken_CareerAdvancement}: </strong>
          {strings.Blog_NoJobsTaken_CareerAdvancement_Text}
        </li>
        <li>
          <strong>{strings.Blog_NoJobsTaken_Education}: </strong>
          {strings.Blog_NoJobsTaken_Education_Text}
        </li>
      </ul>

      <h2>{strings.Blog_NoJobsTaken_Section3_Title}</h2>
      <p>{strings.Blog_NoJobsTaken_Section3_Text}</p>
      <ul>
        <li>
          <strong>{strings.Blog_NoJobsTaken_UniqueJobs}: </strong>
          {strings.Blog_NoJobsTaken_UniqueJobs_Text}
        </li>
        <li>
          <strong>{strings.Blog_NoJobsTaken_ProblemSolving}: </strong>
          {strings.Blog_NoJobsTaken_ProblemSolving_Text}
        </li>
        <li>
          <strong>{strings.Blog_NoJobsTaken_QualityAssurance}: </strong>
          {strings.Blog_NoJobsTaken_QualityAssurance_Text}
        </li>
      </ul>

      <h2>{strings.Blog_NoJobsTaken_Section4_Title}</h2>
      <p>{strings.Blog_NoJobsTaken_Section4_Text}</p>

      <h2>{strings.Blog_NoJobsTaken_Section5_Title}</h2>
      <p>{strings.Blog_NoJobsTaken_Section5_Text}</p>

      <h2>{strings.Blog_NoJobsTaken_Summary_Title}</h2>
      <p>{strings.Blog_NoJobsTaken_Summary_Text}</p>
      {strings.Blog_Author}
      <div className="author_div">
        <img className="author-image" src={iv} alt="CEO OrbitalChamp" />
        <div className="author-text">
          <p>
            <strong>{strings.Blog_NoJobsTaken_Author_Name}</strong> <br />
            <hr className="rounded"></hr>
            <span className="author-title">{strings.Blog_NoJobsTaken_Author_Title}</span>
          </p>
        </div>
      </div>
    </div>

    <div className="menu">
        <ul>
        <li><a href="/shop" replace>{strings.Webshop}</a></li>
          <li><a href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot"                                >{strings.Blog_Top3_Title}       </a></li>
          <li><a href="/OrbitalisHegesztesTortelnelme"                                             >{strings.Blog_History_Title}    </a></li>
          <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto"                                    >{strings.Blog_Valuable_Title}   </a></li>
          <li><a href="/AMagyarOrbitalisHegesztoAzOrbitalChamp"                                    >{strings.Blog_Hungarian_Title}  </a></li>
          <li><a className="a_active" href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes">{strings.Blog_NoJobsTaken_Title}</a></li>
          <li><a href="/OrbitalChampROI">{strings.Blog_ROI_Title}</a></li>
        
        </ul>
      </div>
    </div>
  );
 }
}

export default BlogPostHunWeld;
