import React, { useEffect, useState } from 'react';
import '../../Designe/Blogs/BlogPostTop3.css';
import hegesztobacsi from '../../img/form2.jpg'
import iv from '../../img/me_m.jpg'
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../redux/actions';

import translations from '../../strings/Strings'


function BlogPostTop3() {
  
  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);


  var url = 'https://orbitalchamp.com/Top3OkaHogyMiertHasznaljOrbitalisHegesztot';
  var title = 'Top 3 oka, hogy miért használj ORBITÁLIS HEGESZTŐT!';
  var description = 'Az orbitális hegesztés technológiája az utóbbi években egyre nagyobb teret hódított a hegesztési iparban. De miért válasszunk orbitális hegesztőt a hagyományos módszerek helyett? Íme három meggyőző ok, amelyek miatt érdemes a modern technológiát választanod:';
  var imageUrl = hegesztobacsi;
  
  useEffect(() => {
    // Set the document title
    document.title = title;

    // Update meta tags dynamically
    const metaTags = [
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: imageUrl },
      { property: 'og:url', content: url },
      { property: 'og:type', content: 'article' },
      { property: 'og:site_name', content: 'OrbitalChamp.com' }
    ];

    metaTags.forEach(tag => {
      let element = document.querySelector(`meta[property='${tag.property}']`);
      
      if (!element) {
        // If the meta tag doesn't exist, create it
        element = document.createElement('meta');
        element.setAttribute('property', tag.property);
        document.head.appendChild(element);
      }

      element.setAttribute('content', tag.content);
    });
  }, [title, description, imageUrl, url]); 
  
  
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 762);
    };

    
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    switch (language) {
      case 'en':       
        setStrings(translations.en)
        break;  
      case 'hun':        
        setStrings(translations.hun)
        break;       
      case 'de':        
          setStrings(translations.de)
          break;         
      default:
        break;
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [language]);

// Save language to local storage when it changes
  const dispatch = useDispatch();
  useEffect(() => {
      const savedLanguage = localStorage.getItem('language') || 'hun'; // Default to Hungarian
      dispatch(setLanguage(savedLanguage)); // Set language in Redux
      setStrings(translations[savedLanguage])
  }, [dispatch]);

  



  if(isMobile==false){
  return (
    <div className="blog-container">
   
      <div className="menu">
      <ul>
          <li><a href="/shop" >{strings.Webshop}</a></li>
          <li><a className="a_active" href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">{strings.Blog_Top3_Title}       </a></li>
          <li><a href="/OrbitalisHegesztesTortelnelme"                                  >{strings.Blog_History_Title}    </a></li>
          <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto"                         >{strings.Blog_Valuable_Title}   </a></li>
          <li><a href="/AMagyarOrbitalisHegesztoAzOrbitalChamp"                         >{strings.Blog_Hungarian_Title}  </a></li>
          <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes"          >{strings.Blog_NoJobsTaken_Title}</a></li>
          <li><a href="/OrbitalChampROI">{strings.Blog_ROI_Title}</a></li>

      </ul>

    </div>
      <div className="blog-content">
        <h1 className="bela">{strings.Blog_Top3_Title}</h1>
        <img
          className="welding-imagetop"
          src={hegesztobacsi}
          alt="Orbitális hegesztő munka közben"
        />
        <p>
            {strings.Blog_Top3_Paragraph1}
        </p>
        <ol>
          <li>
            <strong> {strings.Blog_Top3_T1_Strong} </strong> 
            {strings.Blog_Top3_T1_Text}
          </li>
          <li>
            <strong>{strings.Blog_Top3_T2_Strong} </strong> 
            {strings.Blog_Top3_T2_Text}        
          </li>
          <li>
          <strong> {strings.Blog_Top3_T3_Strong} </strong> {strings.Blog_Top3_T3_Text}
            
          </li>
        </ol>
        <p>
          {strings.Blog_Top3_P2}
        </p>
        {strings.Blog_Author} <br />
        <div className='author_div'>
            <img
              className="author-image"
              src={iv}
              alt="Okleveles Mechatronikai Mérnök"> 
            </img>
            <div className='author-text'>
                <p>               
                <strong>Hopp Márton Péter</strong> <br />
                <hr className="rounded"></hr>
                <span className="author-title"></span>{strings.Blog_TitulusMecha}
                </p>

            </div>

        </div>

      </div>
      <div className='empty-space'>

      </div>
    </div>
  );
 }else{
  return (
    <div className="blog-container">
      <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:url" content={url} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="OrbitalChamp.com" />
        </Helmet>

        <div className="blog-content">
          <h1 className="bela-mobile">{strings.Blog_Top3_Title}</h1>
          <img
            className="welding-imagetop"
            src={hegesztobacsi}
            alt="Orbitális hegesztő munka közben"
          />
          <p>
              {strings.Blog_Top3_Paragraph1}
          </p>
          <ol>
            <li>
              <strong> {strings.Blog_Top3_T1_Strong} </strong> 
              {strings.Blog_Top3_T1_Text}
            </li>
            <li>
              <strong>{strings.Blog_Top3_T2_Strong} </strong> 
              {strings.Blog_Top3_T2_Text}        
            </li>
            <li>
            <strong> {strings.Blog_Top3_T3_Strong} </strong> {strings.Blog_Top3_T3_Text}
              
            </li>
          </ol>
          <p>
            {strings.Blog_Top3_P2}
          </p>
          {strings.Blog_Author} <br />
          <div className='author_div'>
              <img
                className="author-image"
                src={iv}
                alt="Okleveles Mechatronikai Mérnök"> 
              </img>
              <div className='author-text'>
                  <p>               
                  <strong>Hopp Márton Péter</strong> <br />
                  <hr className="rounded"></hr>
                  <span className="author-title"></span>{strings.Blog_TitulusMecha}
                  </p>

              </div>
          </div>
      </div>
      <div className="menu">
        <ul>
            <li><a href="/shop" >{strings.Webshop}</a></li>
            <li><a className="a_active" href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">{strings.Blog_Top3_Title}       </a></li>
            <li><a href="/OrbitalisHegesztesTortelnelme"                                  >{strings.Blog_History_Title}    </a></li>
            <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto"                         >{strings.Blog_Valuable_Title}   </a></li>
            <li><a href="/AMagyarOrbitalisHegesztoAzOrbitalChamp"                         >{strings.Blog_Hungarian_Title}  </a></li>
            <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes"          >{strings.Blog_NoJobsTaken_Title}</a></li>
            <li><a href="/OrbitalChampROI">{strings.Blog_ROI_Title}</a></li>

        </ul>

      </div>
    </div>
  );
 }
}

export default BlogPostTop3;
